import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useProjectState } from "../../ducks/project/selectors";
import { LoadingDialog } from "../../components/molecules/loadingDialog";

export const Auth: React.FC<{}> = (props) => {
    const state = useProjectState().project;
    const authState = useProjectState().firebase.auth;
    const dispatch = useDispatch();

    if (authState.isLoaded) {
        if (authState.isEmpty) {
            return <Redirect to="/login" />;
        } else {
            return <Route children={props.children} />;
        }
    } else {
        return <LoadingDialog isLoading={authState.isEmpty} />;
    }
};
