import {
    Store,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import record, { initialState as recordState } from "./record/slice";
import home, { initialState as homeState } from "./home/slice";
import login, { initialState as loginState } from "./login/slice";
import register, { initialState as registerState } from "./register/slice";
import forgotPassword, {
    initialState as forgorPasswordState,
} from "./forgotPassword/slice";
import project, { initialState as projectState } from "./project/slice";
import testSuites, {
    initialState as testSuitesState,
} from "./testSuites/slice";
import popup, { initialState as popupState } from "./popup/slice";
import builds, { initialState as buildsState } from "./builds/slice";
import checkout, { initialState as checkoutState } from "./checkout/slice";
import logger from "redux-logger";
import { getFirebase, firebaseReducer } from "react-redux-firebase";
import { getFirestore, firestoreReducer } from "redux-firestore";
import thunk from "redux-thunk";
import integrations, {
    initialState as integrationsState,
} from "./integrations/slice";
import authCallback, {
    initialState as authCallbackState,
} from "./authCallback/slice";
import constantRun, {
    initialState as constantRunState,
} from "./constantRun/slice";
import variables, { initialState as variablesState } from "./variables/slice";

const rootReducer = combineReducers({
    login: login.reducer,
    register: register.reducer,
    forgotPassword: forgotPassword.reducer,
    home: home.reducer,
    record: record.reducer,
    project: project.reducer,
    testSuites: testSuites.reducer,
    popup: popup.reducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    integrations: integrations.reducer,
    builds: builds.reducer,
    authCallback: authCallback.reducer,
    checkout: checkout.reducer,
    constantRun: constantRun.reducer,
    variables: variables.reducer,
});

const preloadedState = () => {
    return {
        login: loginState,
        register: registerState,
        forgotPassword: forgorPasswordState,
        record: recordState,
        home: homeState,
        project: projectState,
        testSuites: testSuitesState,
        popup: popupState,
        firebase: { auth: { isEmpty: true, isLoaded: false } },
        integrations: integrationsState,
        builds: buildsState,
        authCallback: authCallbackState,
        checkout: checkoutState,
        constantRun: constantRunState,
        variables: variablesState,
    };
};

export type StoreState = ReturnType<typeof preloadedState>;

export type ReduxStore = Store<StoreState>;

const createStore = () => {
    const middlewareList = [
        ...getDefaultMiddleware({
            serializableCheck: false,
            // {
            // ignoredActions: [
            //     // just ignore every redux-firebase and react-redux-firebase action type
            //     ...Object.keys(rfConstants.actionTypes).map(
            //         (type) => `${rfConstants.actionsPrefix}/${type}`
            //     ),
            //     ...Object.keys(rrfActionTypes).map(
            //         (type) => `@@reactReduxFirebase/${type}`
            //     ),
            // ],
            // ignoredPaths: ["firebase", "firestore"],
            // },
        }),
        thunk.withExtraArgument({ getFirebase, getFirestore }),
        // logger,
    ];

    return configureStore({
        reducer: rootReducer,
        middleware: middlewareList,
        devTools: process.env.NODE_ENV !== "production",
        preloadedState: preloadedState(),
    });
};

export default createStore;
