import { green, red } from "@material-ui/core/colors";

export const getStatusColor = (
    status: "success" | "failed" | "running" | "pending"
) => {
    switch (status) {
        case "success":
            return green["500"];
        case "failed":
            return red["500"];
        case "running":
            return "inherit";
        case "pending":
            return "inherit";
    }
};

export const getStatusMessage = (
    status: "success" | "failed" | "running" | "pending"
) => {
    switch (status) {
        case "success":
            return "Success!";
        case "failed":
            return "Failed.";
        case "running":
            return "Running...";
        case "pending":
            return "Pending...";
    }
};
