import { createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase";
import { getFirebase } from "react-redux-firebase";
import { IAPIKey } from "../../../../entities/src/apiKey";
import { IProject } from "../../../../entities/src/project";
import { ISubscription } from "../../../../entities/src/subscription";
import { APIKey } from "../../models/apiKey";
import { AppInfo } from "../../models/appInfo";
import { Project } from "../../models/project";
import { Subscription } from "../../models/subscription";
import { generateHash } from "../../utilities/hash";
import { parseDocument, parseDocuments } from "../../utilities/parseDocument";
import { timestampToDateRecursively } from "../../utilities/timestamp";
import { functions } from "../../firebaseConfig";
import { Variable } from "../../models/variable";
import { IVariable } from "../../../../entities/src/variable";

export const createProject = createAsyncThunk<
    Project,
    { name: string; uid: string; build: File }
>("project/createProject", async (args, thunk) => {
    const { name, uid, build } = args;
    const firebase = getFirebase();

    const project = new Project({
        id: "",
        name: name,
        plan: "trial",
        adminUserIDs: [uid],
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
        authenticatedApp: null,
    });

    const projectDoc = await firebase
        .firestore()
        .collection("project")
        .add(project.data);

    project.data.id = projectDoc.id;

    const currentUser = firebase.auth().currentUser!;

    const waitUntilBecomeOwner = () =>
        new Promise<void>((resolve) => {
            const checkCustomClaimInterval = setInterval(async () => {
                const projectID = (await currentUser.getIdTokenResult(true))
                    .claims.projectID;
                if (projectID === project.data.id) {
                    resolve();
                    clearInterval(checkCustomClaimInterval);
                }
            }, 1000);
        });

    await waitUntilBecomeOwner();

    const filePath = `${project.data.id}/builds/${generateHash()}.app.zip`;
    const snapshot = await firebase.storage().ref(filePath).put(build);
    const appURL = `https://firebasestorage.googleapis.com/v0/b/${snapshot.metadata.bucket}/o/${filePath}`;

    const appInfo = new AppInfo({
        id: "",
        appURL: appURL,
        minPlatformVersion: "",
        bundleIdentifier: "",
        displayName: null,
        bundleName: "",
        releaseVersion: "",
        bundleVersion: "",
        appIconName: null,
        appIconURL: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
    });
    projectDoc.collection("appInfo").add(appInfo.data);

    return project;
});

export const fetchMyProjects = createAsyncThunk<Project[], string>(
    "project/fetchMyProject",
    async (uid) => {
        const projectDocs = await firebase
            .firestore()
            .collection("project")
            .where("adminUserIDs", "array-contains", uid)
            .get();
        const projects = parseDocuments<IProject>(projectDocs).map(
            (projectData) => new Project(projectData)
        );

        return projects;
    }
);

export const fetchAPIKey = createAsyncThunk<APIKey | null, string>(
    "project/fetchAPIKey",
    async (projectID) => {
        const apiKeyDoc = await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("apiKey")
            .where("isValid", "==", true)
            .get();

        if (apiKeyDoc.docs.length === 0) {
            return null;
        }

        const apiKey = new APIKey(parseDocument<IAPIKey>(apiKeyDoc.docs[0]));
        return apiKey;
    }
);

export const fetchActiveSubscriptions = createAsyncThunk<
    { subscription: Subscription; productName: string } | null,
    string
>("project/fetchActiveSubscriptions", async (projectID) => {
    const subscriptions = await firebase
        .firestore()
        .collection("customer")
        .doc(projectID)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .get();

    if (subscriptions.docs.length === 0) {
        return null;
    }

    const data = timestampToDateRecursively(subscriptions.docs[0].data());
    const subscription = new Subscription({
        status: data.status,
        product: data.product.path,
        current_period_end: data.current_period_end,
        current_period_start: data.current_period_start,
        cancel_at: data.cancel_at,
    } as ISubscription);

    const product = await firebase
        .firestore()
        .doc(subscription.data.product)
        .get();

    if (!product.exists) {
        return null;
    }

    const productName = product.data()!.name;

    return { subscription, productName: productName };
});

export const createSlackIntegration = createAsyncThunk<
    void,
    {
        projectID: string;
        channelName: string;
        webhookURL: string;
        userName: string;
    }
>("project/createSlackIntegration", async (params) => {
    const { projectID, channelName, webhookURL, userName } = params;

    const firestore = getFirebase().firestore();

    await firestore
        .collection("project")
        .doc(projectID)
        .update({
            authenticatedApp: {
                slack: {
                    channelName,
                    webhookURL,
                    userName,
                },
            },
        });
});

export const deleteSlackIntegration = createAsyncThunk<
    void,
    {
        projectID: string;
    }
>("project/deleteSlackIntegration", async (params) => {
    const { projectID } = params;

    const firestore = getFirebase().firestore();

    await firestore
        .collection("project")
        .doc(projectID)
        .update({
            authenticatedApp: {
                slack: null,
            },
        });
});

export const fetchProjectMembers = createAsyncThunk<
    { email: string }[],
    string
>("project/fetchProjectMembers", async (projectID) => {
    const fetchProjectMembers = functions.httpsCallable("projectMembers");
    const result = await fetchProjectMembers({ projectID });

    const members = result.data;

    return members;
});

export const fetchVariables = createAsyncThunk<Variable[], string>(
    "project/fetchVariables",
    async (projectID) => {
        const variablesDocs = await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("variable")
            .where("deletedAt", "==", null)
            .orderBy("createdAt", "asc")
            .get();

        const variables = parseDocuments<IVariable>(variablesDocs).map(
            (data) => new Variable(data)
        );

        return variables;
    }
);

export const createVariable = createAsyncThunk<
    Variable,
    { projectID: string; name: string; value: string }
>("project/createVariable", async (args) => {
    const { projectID, name, value } = args;

    const variableData = {
        name,
        value,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
    } as IVariable;

    const variableRef = await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("variable")
        .add(variableData);

    const variable = new Variable({ ...variableData, id: variableRef.id });

    return variable;
});
