import { createSlice } from "@reduxjs/toolkit";
import { RunSchedule } from "../../models/runSchedule";
import { fetchRunSchedule, updateRunSchedule } from "./effects";

export interface ConstantRunState {
    runSchedule: RunSchedule | null;
}

export const initialState: ConstantRunState = {
    runSchedule: null,
};

const constantRun = createSlice({
    name: "constantRun",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRunSchedule.fulfilled, (state, action) => {
            state.runSchedule = action.payload;
        });
        builder.addCase(updateRunSchedule.fulfilled, (state, action) => {
            state.runSchedule = action.payload;
        });
    },
});

export const {} = constantRun.actions;

export default constantRun;
