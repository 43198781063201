export const convertUnixTimeToMinSec = (executionTime: number): string => {
    if (executionTime < 60) {
        return `${executionTime}秒`;
    } else {
        const hour = Math.floor(executionTime / 60);
        const min = executionTime % 60;
        return `${hour}分${min}秒`;
    }
};

export const convertUnixTimeToHourMin = (executionTime: number) => {
    if (executionTime < 60) {
        return `${executionTime}分`;
    } else {
        const hour = Math.floor(executionTime / 60);
        const min = executionTime % 60;
        return `${hour}時間${min}分`;
    }
};
