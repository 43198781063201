import { List } from "@material-ui/core";
import React from "react";
import { ActionView } from "../../../components/molecules/actionView";
import { ActionEvent } from "../../../models/actionEvent";
import { Device } from "../../../models/device";

interface RecordActionListProps {
    actionEvents: ActionEvent[];
    device?: Device;
    screenshotWidth: number;
    lastActionViewRef?: React.RefObject<HTMLDivElement>;
    onDelete: (actionEventID: string) => void;
}

export const RecordActionList = (props: RecordActionListProps) => {
    const {
        actionEvents,
        device,
        screenshotWidth,
        lastActionViewRef,
        onDelete,
    } = props;
    return (
        <List
            style={{
                overflowY: "hidden",
                overflowX: "scroll",
                display: "flex",
                alignItems: "flex-end",
            }}
        >
            {actionEvents.length > 0 &&
                device &&
                actionEvents.map((actionEvent) => {
                    return (
                        <ActionView
                            key={actionEvent.data.id}
                            actionEvent={actionEvent}
                            device={device}
                            screenshotWidth={screenshotWidth}
                            canDelete={true}
                            onDelete={() => {
                                onDelete(actionEvent.data.id);
                            }}
                        />
                    );
                })}
            <div ref={lastActionViewRef}></div>
        </List>
    );
};
