import { IAssertion } from "../../../entities/src/assertion";
import { Modelable } from "../../../entities/src/modelable";

export class Assertion implements Modelable<IAssertion> {
    constructor(public data: IAssertion) {}

    static getTitles = (): { [criteria: string]: string } => {
        return {
            SIMILARITY_ABOVE: SIMILARITY_ABOVE_TITLE(),
            ELEMENT_EXISTS: ELEMENT_EXISTS_TITLE,
            ELEMENT_SIZE: ELEMENT_SIZE_TITLE,
            ELEMENT_POSITION: ELEMENT_POSITION_TITLE,
            TEXT_EQUALS: TEXT_EQUALS_TITLE,
            TEXT_CONTAINS: TEXT_CONTAINS_TITLE,
        };
    };

    static getNodeAssertionTitles = (): { [criteria: string]: string } => {
        const titles = Assertion.getTitles();
        delete titles.SIMILARITY_ABOVE;
        return titles;
    };

    getTitle = () => {
        switch (this.data.criteria) {
            case "SIMILARITY_ABOVE":
                return SIMILARITY_ABOVE_TITLE(this.data.value);
            case "ELEMENT_EXISTS":
                return ELEMENT_EXISTS_TITLE;
            case "ELEMENT_SIZE":
                return ELEMENT_SIZE_TITLE;
            case "ELEMENT_POSITION":
                return ELEMENT_POSITION_TITLE;
            case "TEXT_EQUALS":
                return TEXT_EQUALS_TITLE;
            case "TEXT_CONTAINS":
                return TEXT_CONTAINS_TITLE;
        }
    };
}

export const SIMILARITY_ABOVE_TITLE = (value?: number) => {
    let percent: string;
    if (value === undefined) {
        percent = "xx";
    } else {
        percent = String(value * 100);
    }
    return `スクショ画像が${percent}%一致していること`;
};
export const ELEMENT_EXISTS_TITLE = "要素が存在すること";
export const ELEMENT_SIZE_TITLE = "要素のサイズが同じであること";
export const ELEMENT_POSITION_TITLE = "要素の位置が同じであること";
export const TEXT_EQUALS_TITLE = "要素が指定したテキストと同じであること";
export const TEXT_CONTAINS_TITLE = "要素が指定したテキストを含むこと";
