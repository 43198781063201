import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { useLoginState } from "../../ducks/login/selectors";
import { updateEmail, updatePassword } from "../../ducks/login/slice";
import { loginWithEmail } from "../../ducks/login/effects";
import { useFirebase } from "react-redux-firebase";
import { openPopup } from "../../ducks/popup/slice";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <a color="inherit" href="https://levetty.co.jp/">
                Levetty, inc.
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const Login = () => {
    const classes = useStyles();
    const loginState = useLoginState().login;
    const authState = useLoginState().firebase.auth;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!authState.isEmpty && authState.isLoaded) {
            history.push("/");
            dispatch(
                openPopup({
                    type: "success",
                    message: "ログインに成功しました",
                })
            );
        }
    }, [authState.isEmpty]);

    const firebase = useFirebase();

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    {/* <LockOutlinedIcon /> */}
                </Avatar>
                <Typography component="h1" variant="h5">
                    ログイン
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                        dispatch(loginWithEmail({ ...loginState }));
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={loginState.email}
                        onChange={(event) => {
                            dispatch(updateEmail(event.target.value));
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={loginState.password}
                        onChange={(event) => {
                            dispatch(updatePassword(event.target.value));
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        ログインする
                    </Button>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container>
                            <Grid item xs>
                                <Link to="/forgot-password">
                                    <Typography variant="body2">
                                        パスワードを忘れた場合はこちら
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/register">
                                    <Typography variant="body2">
                                        アカウントを作成する
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item container justify="flex-end" spacing={1}>
                                <Grid item>
                                    <a
                                        href="https://smart-qa.io/terms-of-use"
                                        target="_blank"
                                    >
                                        <Typography variant="caption">
                                            利用規約
                                        </Typography>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a
                                        href="https://smart-qa.io/privacy-policy"
                                        target="_blank"
                                    >
                                        <Typography variant="caption">
                                            プライバシーポリシー
                                        </Typography>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};
