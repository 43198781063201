import { IIOSNode } from "../../../entities/src/iOSNode";
import { Modelable } from "../../../entities/src/modelable";

export class IOSNode implements Modelable<IIOSNode> {
    constructor(public data: IIOSNode) {}

    equals = (iOSNode: IOSNode): boolean => {
        return (
            this.data.x === iOSNode.data.x &&
            this.data.y === iOSNode.data.y &&
            this.data.width === iOSNode.data.width &&
            this.data.height === iOSNode.data.height &&
            this.data.type === iOSNode.data.type &&
            this.data.enabled === iOSNode.data.enabled
        );
    };

    getDescription = (): string => {
        if (this.data.name) {
            return this.data.name;
        }
        return this.data.type;
    };
}
