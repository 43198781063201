import {
    Button,
    Dialog,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { openPopup } from "../../../ducks/popup/slice";
import { useProjectState } from "../../../ducks/project/selectors";

interface CreateVariableModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (name: string, value: string) => void;
}

export const CreateVariableModal = (props: CreateVariableModalProps) => {
    const { isOpen, onClose, onCreate } = props;

    const projectState = useProjectState().project;

    const [name, setName] = React.useState<string>("");
    const [value, setValue] = React.useState<string>("");
    const [randomText, setRandomText] = React.useState<string>("");

    const clearField = () => {
        setName("");
        setValue("");
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        setRandomText(generateRandomText(8));
    }, [isOpen]);

    const appendUniqueText = () => {
        const newValue = value + "${random}";
        setValue(newValue);
    };

    const checkContainsRandomText = (text: string) => {
        const isContained = text.includes("${random}");
        return isContained;
    };

    const getReplacedText = (text: string) => {
        let result = text;
        while (checkContainsRandomText(result)) {
            result = result.replace("${random}", randomText);
        }

        return result;
    };

    const generateRandomText = (length: number) => {
        var s = "";
        const _length = length || 32;
        for (let i = 0; i < _length; i++) {
            const random = (Math.random() * 16) | 0;
            s += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(
                16
            );
        }

        return s;
    };

    const checkAlreadyExistsVariable = () => {
        const isExists = projectState.variables.some(
            (variable) => variable.data.name === name
        );
        return isExists;
    };

    const canCreateVariable = Boolean(
        name && value && !checkAlreadyExistsVariable()
    );

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            onClose={onClose}
        >
            <Grid container direction="column" spacing={6}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h6">変数を追加</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <Close htmlColor={grey[500]} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container wrap="nowrap" spacing={4}>
                    <Grid item container direction="column" spacing={4}>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item>
                                <TextField
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.currentTarget.value);
                                    }}
                                    variant="outlined"
                                    label="変数名"
                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: "small" },
                                    }}
                                    error={checkAlreadyExistsVariable()}
                                    helperText={
                                        checkAlreadyExistsVariable() &&
                                        "既に存在する変数名です。"
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={value}
                                    onChange={(event) => {
                                        setValue(event.currentTarget.value);
                                    }}
                                    variant="outlined"
                                    label="値"
                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: "small" },
                                    }}
                                />
                            </Grid>
                            {checkContainsRandomText(value) && (
                                <Grid>
                                    <Typography variant="caption">
                                        {getReplacedText(value)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item container justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={appendUniqueText}
                                >
                                    ランダム文字を追加する
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="body2" color="textPrimary">
                                {
                                    "${random}を使うことで、ランダムに生成される文字を使用することができます。テストケースごとに一意に生成されます。"
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justify="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canCreateVariable}
                            onClick={() => {
                                if (!canCreateVariable) {
                                    return;
                                }

                                clearField();
                                onCreate(name, value);
                            }}
                        >
                            作成する
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
