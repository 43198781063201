import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import {
    updateEmail,
    clearForgotPasswordState,
} from "../../ducks/forgotPassword/slice";
import { useForgotPasswordState } from "../../ducks/forgotPassword/selectors";
import { useLoginState } from "../../ducks/login/selectors";
import { resetPasswordWithEmail } from "../../ducks/forgotPassword/effects";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <a color="inherit" href="https://levetty.co.jp/">
                Levetty, inc.
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ForgotPassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useForgotPasswordState().forgotPassword;
    const authState = useLoginState().firebase.auth;
    const history = useHistory();

    useEffect(() => {
        if (authState.isEmpty && authState.isLoaded && state.hasSentLink) {
            dispatch(clearForgotPasswordState());
            history.push("/login");
        }
    }, [authState.isEmpty, state.hasSentLink]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}></Avatar>
                <Typography component="h1" variant="h5">
                    パスワードの再設定
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                        dispatch(resetPasswordWithEmail({ ...state }));
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                error={state.emailError != undefined}
                                helperText={
                                    state.emailError != undefined
                                        ? state.emailError.message
                                        : undefined
                                }
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                placeholder="info@smart-qa.io"
                                name="email"
                                autoComplete="email"
                                value={state.email}
                                onChange={(event) => {
                                    dispatch(updateEmail(event.target.value));
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!state.isValid}
                        color="primary"
                        className={classes.submit}
                    >
                        リセットリンクを送信
                    </Button>
                    <Grid container justify="center">
                        <Grid item>
                            <Link to="/login">
                                <Typography variant="body2">
                                    ログインする
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};
