import {
    QueryDocumentSnapshot,
    QuerySnapshot,
    DocumentData,
    DocumentSnapshot,
} from "@firebase/firestore-types";
import { timestampToDateRecursively } from "./timestamp";

export const parseDocument = <T>(
    snapshot:
        | QueryDocumentSnapshot<DocumentData>
        | DocumentSnapshot<DocumentData>
): T => {
    return {
        ...timestampToDateRecursively(snapshot.data()),
        id: snapshot.id,
    } as T;
};

export const parseDocuments = <T>(
    snapshot: QuerySnapshot<DocumentData>
): T[] => {
    return snapshot.docs.map((doc) => parseDocument<T>(doc));
};
