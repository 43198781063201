import {
    Button,
    Dialog,
    Grid,
    DialogActions,
    TextField,
    Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";

interface ConfirmDeleteModalProps {
    isOpen: boolean;
    onClick: (url: string) => void;
    onClose: () => void;
}

export const EditDeepLinkModal = (props: ConfirmDeleteModalProps) => {
    const { isOpen, onClick, onClose } = props;

    const [url, setURL] = React.useState<string>("");

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            BackdropProps={{ timeout: 500 }}
        >
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="space-around"
                direction="column"
                spacing={4}
            >
                <Grid item container>
                    <Typography>ディープリンクを開く</Typography>
                </Grid>
                <Grid item container justify="center">
                    <TextField
                        fullWidth
                        placeholder="https://"
                        value={url}
                        onChange={(event) => {
                            setURL(event.currentTarget.value as string);
                        }}
                    />
                </Grid>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        color="default"
                    >
                        キャンセル
                    </Button>
                    <Button
                        onClick={() => {
                            onClick(url);
                        }}
                        color="primary"
                    >
                        送信する
                    </Button>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};
