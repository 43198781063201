import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../../firebaseConfig";

export const showSubscriptionDetail = createAsyncThunk<void, string>(
    "organization/showSubscriptionDetail",
    async (projectID) => {
        const functionRef = functions.httpsCallable("createPortalLink");
        const { data } = await functionRef({
            returnURL: window.location.origin,
            projectID: projectID,
        });
        window.location.assign(data.url);
    }
);
