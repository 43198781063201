import { IIOSNode } from "../../../entities/src/iOSNode";
import { IOSNode } from "../models/iOSNode";

export const getAttribute = (node: ChildNode | Document): IOSNode | null => {
    const _node = node as any;
    if (!_node.attributes) {
        return null;
    }

    const attributes = Object.values(_node.attributes as object);

    const result = {} as IIOSNode;
    attributes.forEach((attribute) => {
        const key = attribute.nodeName as string;
        const value = attribute.nodeValue;
        switch (key) {
            case "x":
            case "y":
            case "width":
            case "height":
                result[key] = parseInt(value);
                break;
            case "enabled":
                result[key] = value === "true";
                break;
            case "type":
            case "value":
            case "name":
            case "label":
                result[key] = value;
                break;
        }
    });
    const iOSNode = new IOSNode(result);
    return iOSNode;
};
