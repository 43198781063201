import { createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase";
import { IActionEvent } from "../../../../entities/src/actionEvent";
import { ITestCase } from "../../../../entities/src/testCase";
import { ActionEvent } from "../../models/actionEvent";
import { TestCase } from "../../models/testCase";
import { parseDocument, parseDocuments } from "../../utilities/parseDocument";

export const fetchActionEventsWithVariable = createAsyncThunk<
    { [testCaseID: string]: ActionEvent[] },
    { projectID: string; variableID: string }
>("variables/fetchActionEventsWithVariable", async (args) => {
    const { projectID, variableID } = args;

    const actionEventsDocs = await firebase
        .firestore()
        .collectionGroup("actionEvent")
        .where("projectID", "==", projectID)
        .where("variableID", "==", variableID)
        .get();

    const actionEventInfo: { [testCaseID: string]: ActionEvent[] } = {};

    actionEventsDocs.docs.forEach((doc) => {
        const testCaseID = doc.ref.parent.parent?.id;
        if (!testCaseID) {
            return;
        }
        const actionEvent = new ActionEvent(parseDocument<IActionEvent>(doc));

        if (!actionEventInfo[testCaseID]) {
            actionEventInfo[testCaseID] = [];
        }
        actionEventInfo[testCaseID].push(actionEvent);
    });

    return actionEventInfo;
});

export const fetchMyTestCases = createAsyncThunk<
    TestCase[],
    { projectID: string }
>("variables/fetchMyTestCases", async (args, thunk) => {
    const { projectID } = args;
    const testCaseDocs = await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .where("isSaved", "==", true)
        .where("deletedAt", "==", null)
        .orderBy("createdAt", "desc")
        .get();

    const testCases = parseDocuments<ITestCase>(testCaseDocs).map(
        (testCaseData) => new TestCase(testCaseData)
    );

    return testCases;
});

export const deleteVariable = createAsyncThunk<
    void,
    { projectID: string; variableID: string }
>("variables/deleteVariable", async (args, thunk) => {
    const { projectID, variableID } = args;
    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("variable")
        .doc(variableID)
        .delete();
});
