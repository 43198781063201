import React, { useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import { Redirect, useHistory } from "react-router-dom";

export const Logout = () => {
    const firebase = useFirebase();
    const history = useHistory();
    useEffect(() => {
        firebase.auth().signOut();
    }, []);

    return <Redirect to="/login" />;
};
