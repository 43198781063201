import { useSelector } from "react-redux";
import { FirebaseState } from "../../utilities/firebaseState";
import { ProjectState } from "../project/slice";
import { HomeState } from "./slice";

export const useHomeState = () => {
    return useSelector(
        (state: {
            home: HomeState;
            firebase: FirebaseState;
            project: ProjectState;
        }) => state
    );
};
