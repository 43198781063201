import { Dialog, Paper } from "@material-ui/core";
import styled from "styled-components";
import React from "react";
import * as circularLoadingData from "../../resources/loading.json";
import * as iPhoneLoadingData from "../../resources/iphone.json";
import { LoadingView } from "./loadingView";

const TransparentPaper = styled(Paper)`
    background-color: transparent;
    box-shadow: none;
`;

type LoadingIcon = "CIRCULAR_PROGRESS" | "IPHONE";

interface ILoading {
    isLoading: boolean;
    loadingIcon?: LoadingIcon;
    message?: string;
}

export const LoadingDialog = (props: ILoading) => {
    const { isLoading, loadingIcon, message } = props;

    return (
        <Dialog
            open={isLoading}
            style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                textAlign: "center",
            }}
            PaperComponent={TransparentPaper}
            transitionDuration={200}
        >
            <LoadingView
                loadingIcon={loadingIcon}
                message={message}
                width={384}
                height={384}
            />
        </Dialog>
    );
};
