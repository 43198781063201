import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import recorder, {
    clearRecordState,
    Point,
    refreshStreamingURL,
    updateSwiping,
    updateTestCaseName,
} from "../../ducks/record/slice";
import { useRecordState } from "../../ducks/record/selectors";
import {
    createTapActionEvent,
    createSendKeyActionEvent,
    fetchTestCase,
    launchApp,
    createSwipeActionEvent,
    endTestCase,
    deleteSession,
    executeTestCase,
    createOpenURLActionEvent,
    deleteActionEvent,
    createShakeActionEvent,
    updateUsingSession,
} from "../../ducks/record/effects";
import {
    Paper,
    Container,
    Grid,
    makeStyles,
    Box,
    IconButton,
    Typography,
    Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { LoadingDialog } from "../../components/molecules/loadingDialog";
import { useSwipeable } from "react-swipeable";
import { SwipeBar } from "../organisms/record/swipeBar";
import { RecordActionList } from "../organisms/record/recordActionList";
import { ConfirmTestCase } from "../organisms/record/confirmTestCase";
import { SendTextForm } from "../organisms/record/sendTextForm";
import { LoadingView } from "../../components/molecules/loadingView";
import { Device } from "../../models/device";
import RefreshOutlined from "@material-ui/icons/RefreshOutlined";
import Link from "@material-ui/icons/Link";
import { EditDeepLinkModal } from "../organisms/record/editDeepLinkModal";
import ShakePhoneImage from "../../assets/shake-phone.png";
import { openPopup } from "../../ducks/popup/slice";
import { useInterval } from "../../hooks/record/useInterval";
import { AlertDialog } from "../../components/molecules/alertDialog";
import { CreateVariableModal } from "../organisms/variables/createVariableModal";
import { createVariable } from "../../ducks/project/effects";

// web上で表示するスクリーンショットのサイズ
const SCREENSHOT_WIDTH = 256;

// スワイプした時に表示する補助線の幅
const SWIPE_LINE_WIDTH = 8;

/**
 * ドラッグした距離から、X軸・Y軸どちらか短い方のみを返す
 * @param delta ドラッグした距離
 */
const extractShortSide = (delta: { x: number; y: number }) => {
    const isXShort = Math.abs(delta.x) < Math.abs(delta.y);
    if (isXShort) {
        return { x: delta.x, y: 0 };
    } else {
        return { x: 0, y: delta.y };
    }
};

/**
 * ドラッグした距離から、X軸・Y軸どちらか長い方のみを返す
 * @param delta ドラッグした距離
 */
const extractLongSide = (delta: { x: number; y: number }) => {
    const isXLong = Math.abs(delta.x) > Math.abs(delta.y);
    if (isXLong) {
        return { x: delta.x, y: 0 };
    } else {
        return { x: 0, y: delta.y };
    }
};

const useStyles = makeStyles((theme) => ({
    simulator: {
        cursor:
            "-webkit-image-set(url(https://firebasestorage.googleapis.com/v0/b/e2e-test-dev.appspot.com/o/assets%2FCircle.png?alt=media&token=488e32b3-3111-4a18-a463-1f6604b76a74) 1x, url(https://firebasestorage.googleapis.com/v0/b/e2e-test-dev.appspot.com/o/assets%2FCircle%402x.png?alt=media&token=649d5995-028a-462f-9515-97aa385c8933) 2x) 23 23, auto;",
    },
}));

export const Recorder = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useRecordState().record;
    const authState = useRecordState().firebase.auth;
    const projectState = useRecordState().project;
    const classes = useStyles();
    const simulatorRef = React.useRef<HTMLElement>();
    const lastActionViewRef = React.createRef<HTMLDivElement>();
    const [device, setDevice] = React.useState<Device | null>(null);
    const [isEditingDeepLink, setEditingDeepLink] = React.useState<boolean>(
        false
    );
    const [isTimeout, setTimeout] = React.useState<boolean>(false);
    const [isVariableModalOpen, setVariableModalOpen] = React.useState<boolean>(
        false
    );

    const sessionIDRef = React.useRef(state.sessionID);
    useEffect(() => {
        sessionIDRef.current = state.sessionID;
    }, [state.sessionID]);

    const projectID = projectState.selectedProject?.data.id;

    useInterval(() => {
        if (!state.sessionID || !projectID) {
            return;
        }

        if (
            new Date().getTime() - state.lastActionDate.getTime() >
            5 * 60 * 1000
        ) {
            setTimeout(true);
            dispatch(deleteSession({ projectID, sessionID: state.sessionID }));
            return;
        }
        dispatch(updateUsingSession({ projectID, sessionID: state.sessionID }));
    }, 5000);

    // web上で表示するシミュレーターのサイズ
    const SIMULATOR_WIDTH = state.testCase?.data.device.name.startsWith("iPad")
        ? 480
        : 320;

    /**
     * 引数で受け取ったweb上のシミュレーターの座標を実際のデバイスの倍率に変換します。
     * 整数がほしいので Math.round で丸めています。
     * @param relativePoint web上のシミュレーターの座標
     * @param device
     */
    const createDevicePointFromRelativePoint = (
        relativePoint: Point,
        device: Device
    ) => {
        const rect = device.getRect();
        const ratio = rect.width / SIMULATOR_WIDTH;
        return {
            x: Math.round(relativePoint.x * ratio),
            y: Math.round(relativePoint.y * ratio),
        };
    };

    const tap = (point: Point) => {
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            // TODO: Error
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            createTapActionEvent({
                projectID: projectID,
                testCaseID: state.testCase.data.id,
                point: point,
                sessionID: state.sessionID,
            })
        );
    };

    const swipe = (from: Point, to: Point, direction: string) => {
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            // TODO: Error
            return;
        }
        const projectID = projectState.selectedProject.data.id;

        dispatch(
            createSwipeActionEvent({
                projectID: projectID,
                testCaseID: state.testCase.data.id,
                sessionID: state.sessionID,
                from: from,
                to: to,
                direction: direction as "up" | "down" | "left" | "right",
            })
        );
    };

    const sendKeys = (args: { text?: string; variableID?: string }) => {
        console.log(args.text, args.variableID);
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            // TODO: Error
            return;
        }

        const { text, variableID } = args;
        if (!text && !variableID) {
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            createSendKeyActionEvent({
                projectID,
                testCaseID: state.testCase.data.id,
                text,
                variableID,
                sessionID: state.sessionID,
            })
        );
    };

    const openDeepLink = (url: string) => {
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            createOpenURLActionEvent({
                projectID: projectID,
                testCaseID: state.testCase.data.id,
                url,
                sessionID: state.sessionID,
            })
        );
    };

    useEffect(() => {
        if (!authState.uid || !projectState.selectedProject) {
            return;
        }

        if (!state.isReady) {
            dispatch(openPopup({ type: "error", message: "無効なURLです。" }));
            return;
        }

        setTimeout(false);
        dispatch(clearRecordState());
        const testCaseID = history.location.pathname.split("/record/")[1];
        dispatch(
            fetchTestCase({
                testCaseID: testCaseID,
                projectID: projectState.selectedProject.data.id,
            })
        );

        return () => {
            if (!projectState.selectedProject || !sessionIDRef.current) {
                return;
            }
            const projectID = projectState.selectedProject.data.id;
            const sessionID = sessionIDRef.current;
            dispatch(deleteSession({ projectID, sessionID }));
        };
    }, []);

    useEffect(() => {
        if (!state.testCase || !projectState.selectedProject) {
            return;
        }

        setDevice(new Device(state.testCase.data.device));
        dispatch(
            launchApp({
                projectID: projectState.selectedProject.data.id,
                testCaseID: state.testCase.data.id,
            })
        );
    }, [state.testCase]);

    useEffect(() => {
        if (!state.shouldLeave || isTimeout) {
            return;
        }
        history.push("/");
        dispatch(clearRecordState());
    }, [state.shouldLeave]);

    useEffect(() => {
        if (
            !state.shouldExecTestCase ||
            !projectState.selectedProject ||
            !state.testCase ||
            !state.sessionID
        ) {
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            executeTestCase({
                projectID: projectID,
                testCaseID: state.testCase.data.id,
                sessionID: state.sessionID,
            })
        );
    }, [state.shouldExecTestCase]);

    const handleShake = () => {
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            // TODO: Error
            return;
        }

        const projectID = projectState.selectedProject.data.id;
        const testCaseID = state.testCase.data.id;
        const sessionID = state.sessionID;

        dispatch(createShakeActionEvent({ projectID, testCaseID, sessionID }));
    };

    const saveTestCase = () => {
        if (
            !state.sessionID ||
            !projectState.selectedProject ||
            !state.testCase
        ) {
            // TODO: Error
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            endTestCase({
                projectID: projectID,
                testCaseID: state.testCase.data.id,
                sessionID: state.sessionID,
                testCaseName: state.testCaseName,
                originalTestCaseID: state.testCase.data.originalTestCaseID,
            })
        );
    };

    useEffect(() => {
        lastActionViewRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }, [state.actions]);

    const swipeHandlers = useSwipeable({
        onSwiping: (e) => {
            if (!simulatorRef.current || !e.event.target) {
                return;
            }
            const hasSimulatorInParent = (
                targetElement: HTMLElement,
                simulatorElement: HTMLElement
            ) => {
                const hasSimulatorInParentRecursively = (
                    element: HTMLElement
                ): boolean => {
                    if (element === simulatorElement) {
                        return true;
                    }
                    if (element.parentElement) {
                        return hasSimulatorInParentRecursively(
                            element.parentElement
                        );
                    } else {
                        return false;
                    }
                };

                return hasSimulatorInParentRecursively(targetElement);
            };

            const isMouseInSimulatorView = hasSimulatorInParent(
                e.event.target as HTMLElement,
                simulatorRef.current
            );

            const event = e.event as MouseEvent;

            const fromPoint = {
                x: event.offsetX - e.deltaX,
                y: event.offsetY - e.deltaY,
            };

            const longSideDelta = extractLongSide({
                x: e.deltaX,
                y: e.deltaY,
            });

            const toPoint = {
                x: fromPoint.x + longSideDelta.x,
                y: fromPoint.y + longSideDelta.y,
            };

            dispatch(
                updateSwiping({
                    isSwiping: isMouseInSimulatorView,
                    fromPoint: fromPoint,
                    toPoint: toPoint,
                    direction: e.dir.toLowerCase() as
                        | "up"
                        | "down"
                        | "left"
                        | "right",
                })
            );
        },
        onSwiped: (e) => {
            if (!state.isSwiping || !device) {
                return;
            }
            const direction = e.dir.toLowerCase();
            const event = e.event as MouseEvent;

            // これでクリックイベントを設定しているdiv内の相対座標を取得できている。
            const endRelativeX = event.offsetX;
            const endRelativeY = event.offsetY;

            const shortSideDelta = extractShortSide({
                x: e.deltaX,
                y: e.deltaY,
            });

            const endPoint = createDevicePointFromRelativePoint(
                {
                    x: endRelativeX - shortSideDelta.x,
                    y: endRelativeY - shortSideDelta.y,
                },
                device
            );

            const startPoint = createDevicePointFromRelativePoint(
                {
                    x: endRelativeX - e.deltaX,
                    y: endRelativeY - e.deltaY,
                },
                device
            );

            swipe(startPoint, endPoint, direction);
        },
        onTap: (e) => {
            if (!device) {
                return;
            }
            // スマホからの操作はないものとして、強制的に `MouseEvent` にアサートしてる。
            const event = e.event as MouseEvent;

            // これでクリックイベントを設定しているdiv内の相対座標を取得できている。
            const relativeX = event.offsetX;
            const relativeY = event.offsetY;

            const p = createDevicePointFromRelativePoint(
                {
                    x: relativeX,
                    y: relativeY,
                },
                device
            );

            tap(p);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        delta: 4,
    });

    const refPassthrough = (el: any) => {
        swipeHandlers.ref(el);
        simulatorRef.current = el;
    };

    const lastElement = state.nextElement;

    return (
        <Container component="main" maxWidth="xl">
            <LoadingDialog
                isLoading={state.isLaunching}
                loadingIcon="IPHONE"
                message="シミュレータを起動しています..."
            />
            <LoadingDialog isLoading={state.isLoading} />
            <CreateVariableModal
                isOpen={isVariableModalOpen}
                onClose={() => {
                    setVariableModalOpen(false);
                }}
                onCreate={(name, value) => {
                    setVariableModalOpen(false);
                    if (!projectID) {
                        return;
                    }
                    dispatch(createVariable({ projectID, name, value }));
                }}
            />
            <AlertDialog
                message="セッションがタイムアウトしました。"
                isOpen={isTimeout}
                agreeText="閉じる"
                onAgree={() => {
                    history.push("/");
                    dispatch(clearRecordState());
                }}
            />
            <EditDeepLinkModal
                isOpen={isEditingDeepLink}
                onClick={(url) => {
                    setEditingDeepLink(false);
                    openDeepLink(url);
                }}
                onClose={() => {
                    setEditingDeepLink(false);
                }}
            />
            <Grid item container justify="space-around">
                <Grid
                    item
                    container
                    direction="column"
                    xs={7}
                    lg={8}
                    wrap="nowrap"
                    spacing={8}
                >
                    <ConfirmTestCase
                        testCaseName={state.testCaseName}
                        onChangeTestCaseName={(text) => {
                            dispatch(updateTestCaseName(text));
                        }}
                        onClickSaveButton={() => saveTestCase()}
                        onClickCancelButton={() => {
                            if (!state.sessionID) {
                                history.push("/");
                                return;
                            }
                            if (!projectState.selectedProject) {
                                return;
                            }
                            const projectID =
                                projectState.selectedProject.data.id;
                            dispatch(
                                deleteSession({
                                    sessionID: state.sessionID,
                                    projectID,
                                })
                            );
                        }}
                    />
                    <Grid item xs={12} lg={12}>
                        <RecordActionList
                            actionEvents={state.actions}
                            screenshotWidth={SCREENSHOT_WIDTH}
                            device={device ?? undefined}
                            lastActionViewRef={lastActionViewRef}
                            onDelete={(actionEventID) => {
                                const projectID =
                                    projectState.selectedProject?.data.id;
                                const testCaseID = state.testCase?.data.id;
                                if (!projectID || !testCaseID) {
                                    return;
                                }
                                dispatch(
                                    deleteActionEvent({
                                        projectID,
                                        testCaseID,
                                        actionEventID,
                                    })
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    spacing={1}
                    xs={5}
                    lg={4}
                >
                    <Grid item>
                        <Typography variant="caption">
                            画面のキャッシュをクリアする
                        </Typography>
                        <IconButton
                            onClick={() => {
                                dispatch(refreshStreamingURL());
                            }}
                        >
                            <RefreshOutlined />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        spacing={2}
                    >
                        {state.testCase && (
                            <Box
                                style={{
                                    backgroundImage: `url(${state.streamingURL}&timestamp=${state.streamingURLHash})`,
                                    backgroundSize: "contain",
                                    overflow: "hidden",
                                    position: "relative",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: SIMULATOR_WIDTH,
                                    height:
                                        device?.getResizedRect(SIMULATOR_WIDTH)
                                            .height ?? SIMULATOR_WIDTH,
                                }}
                            >
                                {lastElement && (
                                    <Paper
                                        variant="outlined"
                                        style={{
                                            position: "absolute",
                                            left:
                                                (lastElement.data.left *
                                                    SIMULATOR_WIDTH) /
                                                (device?.getRect().width ??
                                                    SIMULATOR_WIDTH),
                                            top:
                                                (lastElement.data.top *
                                                    SIMULATOR_WIDTH) /
                                                (device?.getRect().width ??
                                                    SIMULATOR_WIDTH),
                                            width:
                                                (lastElement.data.width *
                                                    SIMULATOR_WIDTH) /
                                                (device?.getRect().width ??
                                                    SIMULATOR_WIDTH),
                                            height:
                                                (lastElement.data.height *
                                                    SIMULATOR_WIDTH) /
                                                (device?.getRect().width ??
                                                    SIMULATOR_WIDTH),
                                            backgroundColor: "red",
                                            opacity: 0.2,
                                        }}
                                    />
                                )}
                                {state.isSwiping &&
                                    state.fromPoint &&
                                    state.toPoint && (
                                        <SwipeBar
                                            swipeDirection={
                                                state.swipeDirection
                                            }
                                            fromPoint={state.fromPoint}
                                            toPoint={state.toPoint}
                                            swipeLineWidth={SWIPE_LINE_WIDTH}
                                        />
                                    )}

                                <div // MARK: このViewはシミュレータのdivのなかで最前面に配置する必要があります。
                                    style={{
                                        width: SIMULATOR_WIDTH,
                                        height:
                                            SIMULATOR_WIDTH *
                                            (device?.getAspectRatio() ??
                                                SIMULATOR_WIDTH),
                                        backgroundSize: "contain",
                                        overflow: "hidden",
                                        position: "absolute",
                                    }}
                                    {...swipeHandlers}
                                    ref={refPassthrough}
                                    className={classes.simulator}
                                />
                                {state.isActionWaiting && (
                                    <LoadingView
                                        backgroundColor="rgba(66, 66, 66, 0.2)"
                                        width={SIMULATOR_WIDTH}
                                        height={
                                            SIMULATOR_WIDTH *
                                            (device?.getAspectRatio() ??
                                                SIMULATOR_WIDTH)
                                        }
                                    />
                                )}
                            </Box>
                        )}
                        {state.nextElement && (
                            <SendTextForm
                                handleOpenVariableModal={() => {
                                    setVariableModalOpen(true);
                                }}
                                onSubmit={sendKeys}
                            />
                        )}
                        <Grid item container justify="center" spacing={1}>
                            <Grid item>
                                <Tooltip title="Deep Link">
                                    <IconButton
                                        onClick={() => {
                                            setEditingDeepLink(true);
                                        }}
                                    >
                                        <Link />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="シェイクする">
                                    <IconButton onClick={handleShake}>
                                        <img
                                            src={`/${ShakePhoneImage}`}
                                            width={24}
                                            height={24}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
