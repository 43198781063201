import { useSelector } from "react-redux";
import { FirebaseState } from "../../utilities/firebaseState";
import { ForgotPasswordState } from "./slice";

export const useForgotPasswordState = () => {
    return useSelector(
        (state: {
            forgotPassword: ForgotPasswordState;
            firebase: FirebaseState;
        }) => state
    );
};
