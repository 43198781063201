import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import React from "react";

interface AlertDialogProps {
    isOpen: boolean;
    title?: string;
    message?: string;
    agreeText?: string;
    disagreeText?: string;
    onAgree?: () => void;
    onDisagree?: () => void;
    onClose?: () => void;
}

export const AlertDialog = (props: AlertDialogProps) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
        >
            <Grid container direction="column" spacing={2}>
                {props.title && (
                    <Grid item>
                        <Typography>{props.title}</Typography>
                    </Grid>
                )}
                {props.message && (
                    <Grid item container justify="center">
                        <Grid item>
                            <Typography variant="body2">
                                {props.message}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item container justify="space-around">
                    {props.disagreeText && (
                        <Grid item>
                            <Button onClick={props.onDisagree} color="primary">
                                {props.disagreeText}
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button onClick={props.onAgree} color="primary">
                            {props.agreeText ?? "OK"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
