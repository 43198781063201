import {
    Button,
    Dialog,
    Grid,
    DialogActions,
    Typography,
    IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React from "react";
interface ConfirmDeleteModalProps {
    isOpen: boolean;
    title?: string;
    message?: string;
    actionText?: string;
    disabled?: boolean;
    onClick: () => void;
    onClose: () => void;
}

export const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
    const {
        isOpen,
        title,
        actionText,
        message,
        disabled,
        onClick,
        onClose,
    } = props;
    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth="lg"
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            BackdropProps={{ timeout: 500 }}
        >
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="space-around"
                direction="column"
                spacing={4}
            >
                <Grid item container justify="flex-end">
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justify="space-around"
                    >
                        <Typography variant="body2">{title}</Typography>
                        <IconButton onClick={() => onClose()}>
                            <Close htmlColor={grey[500]} />
                        </IconButton>
                    </Grid>
                </Grid>
                {message && (
                    <Grid item>
                        <Typography variant="caption">{message}</Typography>
                    </Grid>
                )}
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary">
                        キャンセル
                    </Button>
                    <Button
                        disabled={disabled}
                        onClick={() => onClick()}
                        color="secondary"
                    >
                        {actionText}
                    </Button>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};
