import { Modelable } from "../../../entities/src/modelable";
import { IRunResult } from "../../../entities/src/runResult";

export class RunResult implements Modelable<IRunResult> {
    constructor(public data: IRunResult) {}

    getReasonDescription = (): string | null => {
        if (!this.data.reason) {
            return null;
        }
        switch (this.data.reason) {
            case "CRASHED":
                return "アプリがクラッシュしました";
            case "TARGET_NODE_NOT_FOUND":
                return "対象の要素が見つかりませんでした";
            case "INTERNAL_ERROR":
                return "実行時エラーが発生しました";
        }
    };
}
