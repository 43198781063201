import {
    Button,
    Dialog,
    Grid,
    DialogActions,
    IconButton,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTestSuitesState } from "../../../ducks/testSuites/selectors";
import {
    addSelectedTestCaseID,
    removeSelectedTestCaseID,
    selectAllTestCaseID,
    removeAllTestCaseID,
} from "../../../ducks/testSuites/slice";
interface SelectTestCaseIDsModalProps {
    isOpen: boolean;
    onClick: () => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        formControl: {
            margin: theme.spacing(3),
        },
    })
);

export const SelectTestCaseIDsModal = (props: SelectTestCaseIDsModalProps) => {
    const classes = useStyles();
    const { isOpen, onClick, onClose } = props;
    const dispatch = useDispatch();
    const state = useTestSuitesState().testSuites;
    const [allTestCaseIdsSelected, setAllTestCaseIdsSelected] = React.useState(
        false
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = state.selectedTestCaseIDs.indexOf(event.target.name);
        if (index === -1) {
            dispatch(addSelectedTestCaseID(event.target.name));
        } else {
            dispatch(removeSelectedTestCaseID(index));
        }
    };

    const handleAllSelected = () => {
        if (!allTestCaseIdsSelected) {
            dispatch(selectAllTestCaseID());
            setAllTestCaseIdsSelected(true);
        } else {
            dispatch(removeAllTestCaseID());
            setAllTestCaseIdsSelected(false);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth="sm"
            scroll="paper"
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            BackdropProps={{ timeout: 500 }}
        >
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="space-around"
                direction="column"
                spacing={4}
            >
                <Grid container justify="flex-end">
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justify="space-around"
                    >
                        <DialogTitle>実行するテストを選択</DialogTitle>
                        <IconButton onClick={() => onClose()}>
                            <Close htmlColor={grey[500]} />
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allTestCaseIdsSelected}
                                onChange={handleAllSelected}
                                color="primary"
                            />
                        }
                        label={"すべて選択する"}
                    />
                </DialogContent>
                <DialogContent dividers>
                    <div className={classes.root}>
                        <FormControl
                            component="fieldset"
                            className={classes.formControl}
                        >
                            <FormGroup>
                                {state.activeTestCases.map((testCase) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.selectedTestCaseIDs.includes(
                                                        testCase.data.id
                                                    )}
                                                    color="primary"
                                                    onChange={handleChange}
                                                    name={testCase.data.id}
                                                />
                                            }
                                            label={testCase.data.name}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary">
                        キャンセル
                    </Button>
                    <Button
                        onClick={() => onClick()}
                        color="primary"
                        disabled={state.selectedTestCaseIDs.length === 0}
                    >
                        テストを実行
                    </Button>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};
