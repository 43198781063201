import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseDevConfig = {
    apiKey: "AIzaSyA8tVxR5LYg2lNbOKTun6dmP38uib_Blvc",
    authDomain: "e2e-test-dev.firebaseapp.com",
    databaseURL: "https://e2e-test-dev.firebaseio.com",
    projectId: "e2e-test-dev",
    storageBucket: "e2e-test-dev.appspot.com",
    messagingSenderId: "548753924478",
    appId: "1:548753924478:web:5062ddc6b105fedb26d32f",
    measurementId: "G-B2KJTGXV20",
};

const firebaseProdConfig = {
    apiKey: "AIzaSyDH_d5qfpy3H3aIZrmgHlhsIcZmlUVRge8",
    authDomain: "smart-qa-prod.firebaseapp.com",
    databaseURL: "https://smart-qa-prod.firebaseio.com",
    projectId: "smart-qa-prod",
    storageBucket: "smart-qa-prod.appspot.com",
    messagingSenderId: "893033505117",
    appId: "1:893033505117:web:91991b6817dff18715c8b4",
    measurementId: "G-M7VC5MM14F",
};

const firebaseConfig =
    process.env.MODE === "PRODUCTION" ? firebaseProdConfig : firebaseDevConfig;

const app = firebase.initializeApp(firebaseConfig);
const functions = app.functions("asia-northeast1");

if (process.env.MODE !== "PRODUCTION" && process.env.MODE !== "DEVELOPMENT") {
    functions.useFunctionsEmulator("http://localhost:5000");
}

firebase.firestore().settings({
    ignoreUndefinedProperties: true,
});

export { app, functions };
