import { IActionResult } from "../../../entities/src/actionResult";
import { Modelable } from "../../../entities/src/modelable";

export class ActionResult implements Modelable<IActionResult> {
    constructor(public data: IActionResult) {}

    getReasonDescription = (): string | null => {
        if (!this.data.reason) {
            return null;
        }
        switch (this.data.reason) {
            case "CRASHED":
                return "クラッシュしました";
            case "TARGET_NODE_NOT_FOUND":
                return "対象の要素が見つかりませんでした";
            case "INTERNAL_ERROR":
                return "実行時エラーが発生しました";
        }
    };
}
