import { Box, Dialog, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import React from "react";
import Lottie from "react-lottie";
import * as circularLoadingData from "../../resources/loading.json";
import * as iPhoneLoadingData from "../../resources/iphone.json";
import * as progressData from "../../resources/progress.json";

type LoadingIcon = "CIRCULAR_PROGRESS" | "IPHONE" | "PROGRESS";

interface ILoading {
    loadingIcon?: LoadingIcon;
    message?: string;
    width: number;
    height: number;
    backgroundColor?: string;
}

export const LoadingView = (props: ILoading) => {
    const { loadingIcon, message, width, height, backgroundColor } = props;

    const getLoadingData = (loadingIcon?: LoadingIcon) => {
        switch (loadingIcon) {
            case "CIRCULAR_PROGRESS":
                return circularLoadingData;
            case "IPHONE":
                return iPhoneLoadingData;
            case "PROGRESS":
                return progressData;
            default:
                return circularLoadingData;
        }
    };

    return (
        <Box
            style={{
                textAlign: "center",
                backgroundColor: backgroundColor,
            }}
        >
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: getLoadingData(loadingIcon),
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid",
                    },
                }}
                style={{ backgroundColor: "transparent" }}
                isClickToPauseDisabled={true}
                height={height}
                width={width}
            />
            <Typography style={{ color: "white" }}>{message}</Typography>
        </Box>
    );
};
