import { Grid, Typography, Slider, Button } from "@material-ui/core";
import React from "react";
import { Assertion } from "../../../models/assertion";

interface EditImageSimilarityProps {
    assertion: Assertion;
    isLoading: boolean;
    onUpdate?: (value: number) => void;
}

const sliderMarks = [
    {
        value: 0,
        label: "Any changes accepted.",
    },
    {
        value: 70,
        label: "Default.",
    },
    {
        value: 100,
        label: "No changes allowed.",
    },
];

export const EditImageSimilarity = (props: EditImageSimilarityProps) => {
    const { assertion, isLoading, onUpdate } = props;
    const [similarity, setSimilarity] = React.useState<number>(
        (assertion?.data.value ?? 0) * 100
    );
    return (
        <Grid item container spacing={4} direction="column">
            <Grid item>
                <Typography variant="body1">
                    許容する画像差分量を決めてください。
                </Typography>
            </Grid>
            <Grid item xs={10} style={{ textAlign: "center" }}>
                <Slider
                    value={similarity ?? 0}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={sliderMarks}
                    getAriaValueText={(text) => `${text}%`}
                    onChange={(_, value) => setSimilarity(value as number)}
                />
                <Typography variant="h6">{`${similarity}%`}</Typography>
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                xs={10}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (!onUpdate) {
                                return;
                            }
                            onUpdate(similarity / 100);
                        }}
                        disabled={isLoading}
                    >
                        更新する
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
