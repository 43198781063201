import { createAsyncThunk } from "@reduxjs/toolkit";
import { ActionEvent } from "../../models/actionEvent";
import { Point } from "./slice";
import { TestCase } from "../../models/testCase";
import firebase, { storage } from "firebase";
import { functions } from "../../firebaseConfig";
import { Element } from "../../models/element";
import { fetchSignedURL } from "../../utilities/fetchSignedURL";
import { parseDocument, parseDocuments } from "../../utilities/parseDocument";
import { ITestCase } from "../../../../entities/src/testCase";
import { IActionEvent } from "../../../../entities/src/actionEvent";
import { IElement } from "../../../../entities/src/element";
import { IAppInfo } from "../../../../entities/src/appInfo";
import { AppInfo } from "../../models/appInfo";

export const fetchTestCase = createAsyncThunk<
    TestCase,
    { projectID: string; testCaseID: string }
>(
    "record/fetchTestCase",
    async (args, thunk): Promise<TestCase> => {
        const { projectID, testCaseID } = args;
        const testCaseDoc = await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(testCaseID)
            .get();

        const testCaseData = parseDocument<ITestCase>(testCaseDoc);
        const testCase = new TestCase(testCaseData);

        return testCase;
    }
);

export const launchApp = createAsyncThunk<
    { sessionID: string; streamingURL: string },
    { projectID: string; testCaseID: string }
>("record/launchApp", async (args) => {
    const { projectID, testCaseID } = args;
    const body = {
        projectID: projectID,
        testCaseID: testCaseID,
    };

    const launchApp = functions.httpsCallable("launchApp", {
        timeout: 540 * 1000,
    });

    functions.httpsCallable("actionEvents")({ isWarming: true });
    functions.httpsCallable("actionEvents")({ isWarming: true });

    const response = await launchApp(body);
    const result = response.data as {
        sessionID: string;
        streamingURL: string;
    };

    return result;
});

export const executeTestCase = createAsyncThunk<
    void,
    { projectID: string; testCaseID: string; sessionID: string }
>("record/executeTestCase", async (args) => {
    const { projectID, testCaseID, sessionID } = args;
    const body = {
        projectID,
        testCaseID,
        sessionID,
    };

    const executeTestCase = functions.httpsCallable("executeTestCase", {
        timeout: 540 * 1000,
    });
    await executeTestCase(body);
});

export const createTapActionEvent = createAsyncThunk<
    {
        actionEvent: ActionEvent;
        nextElement: Element | null;
    },
    { projectID: string; testCaseID: string; point: Point; sessionID: string }
>("record/createTapActionEvent", async (args, thunk) => {
    const { projectID, testCaseID, point, sessionID } = args;
    const body = {
        action: "CLICK",
        toX: point.x,
        toY: point.y,
        testCaseID: testCaseID,
        projectID: projectID,
        sessionID: sessionID,
    };

    const createActionEvent = functions.httpsCallable("actionEvents");
    const response = await createActionEvent(body);
    const result = response.data as {
        actionEvent: IActionEvent;
        nextElement: IElement | null;
    };

    const actionEvent = new ActionEvent(result.actionEvent);
    const nextElement =
        result.nextElement === null ? null : new Element(result.nextElement);

    return { actionEvent: actionEvent, nextElement: nextElement };
});

export const createSendKeyActionEvent = createAsyncThunk<
    {
        actionEvent: ActionEvent;
        nextElement: Element | null;
    },
    {
        projectID: string;
        testCaseID: string;
        text?: string;
        variableID?: string;
        sessionID: string;
    }
>("record/createSendKeyActionEvent", async (args, thunk) => {
    const { projectID, testCaseID, text, variableID, sessionID } = args;
    const body = {
        action: "INPUT_TEXT",
        text,
        variableID,
        projectID,
        testCaseID,
        sessionID,
    };

    const createActionEvent = functions.httpsCallable("actionEvents");
    const response = await createActionEvent(body);
    const result = response.data as {
        actionEvent: IActionEvent;
        nextElement: IElement | null;
    };

    const actionEvent = new ActionEvent(result.actionEvent);
    const nextElement =
        result.nextElement === null ? null : new Element(result.nextElement);

    return { actionEvent: actionEvent, nextElement: nextElement };
});

export const createSwipeActionEvent = createAsyncThunk<
    {
        actionEvent: ActionEvent;
        nextElement: Element | null;
    },
    {
        projectID: string;
        testCaseID: string;
        sessionID: string;
        from: Point;
        to: Point;
        direction: "up" | "down" | "left" | "right";
    }
>("record/createSwipeActionEvent", async (args) => {
    const { projectID, testCaseID, sessionID, from, to, direction } = args;
    const body = {
        action: "SWIPE",
        fromX: from.x,
        fromY: from.y,
        toX: to.x,
        toY: to.y,
        projectID: projectID,
        testCaseID: testCaseID,
        sessionID: sessionID,
        direction: direction,
    };
    const createActionEvent = functions.httpsCallable("actionEvents");
    const response = await createActionEvent(body);
    const result = response.data as {
        actionEvent: IActionEvent;
        nextElement: IElement | null;
    };

    const actionEvent = new ActionEvent(result.actionEvent);
    const nextElement =
        result.nextElement === null ? null : new Element(result.nextElement);

    return { actionEvent: actionEvent, nextElement: nextElement };
});

export const createOpenURLActionEvent = createAsyncThunk<
    {
        actionEvent: ActionEvent;
        nextElement: Element | null;
    },
    {
        projectID: string;
        testCaseID: string;
        sessionID: string;
        url: string;
    }
>("record/createOpenURLEvent", async (args) => {
    const { projectID, testCaseID, sessionID, url } = args;
    const body = {
        action: "OPEN_URL",
        projectID,
        testCaseID,
        sessionID,
        url,
    };
    const createActionEvent = functions.httpsCallable("actionEvents");
    const response = await createActionEvent(body);
    const result = response.data as {
        actionEvent: IActionEvent;
        nextElement: IElement | null;
    };

    const actionEvent = new ActionEvent(result.actionEvent);
    const nextElement =
        result.nextElement === null ? null : new Element(result.nextElement);

    return { actionEvent: actionEvent, nextElement: nextElement };
});

export const createShakeActionEvent = createAsyncThunk<
    {
        actionEvent: ActionEvent;
        nextElement: Element | null;
    },
    {
        projectID: string;
        testCaseID: string;
        sessionID: string;
    }
>("record/createShakeEvent", async (args) => {
    const { projectID, testCaseID, sessionID } = args;
    const body = {
        action: "SHAKE",
        projectID,
        testCaseID,
        sessionID,
    };
    const createActionEvent = functions.httpsCallable("actionEvents");
    const response = await createActionEvent(body);
    const result = response.data as {
        actionEvent: IActionEvent;
        nextElement: IElement | null;
    };

    const actionEvent = new ActionEvent(result.actionEvent);
    const nextElement =
        result.nextElement === null ? null : new Element(result.nextElement);

    return { actionEvent: actionEvent, nextElement: nextElement };
});

export const endTestCase = createAsyncThunk<
    void,
    {
        projectID: string;
        testCaseID: string;
        sessionID: string;
        testCaseName: string;
        originalTestCaseID?: string;
    }
>("record/endTestCase", async (args) => {
    const {
        projectID,
        testCaseID,
        sessionID,
        testCaseName,
        originalTestCaseID,
    } = args;
    const body = {
        action: "END",
        projectID: projectID,
        testCaseID: testCaseID,
        sessionID: sessionID,
        testCaseName: testCaseName,
    };

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .update({ name: testCaseName } as Pick<ITestCase, "name">);

    if (originalTestCaseID) {
        const dependedTestCasesDoc = await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .where("dependedTestCaseID", "==", originalTestCaseID)
            .get();
        dependedTestCasesDoc.docs.forEach(async (doc) => {
            await doc.ref.update({ dependedTestCaseID: testCaseID } as Pick<
                ITestCase,
                "dependedTestCaseID"
            >);
        });

        await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(originalTestCaseID)
            .update({ deletedAt: new Date() });
    }

    const createActionEvent = functions.httpsCallable("actionEvents");
    await createActionEvent(body);
});

export const deleteSession = createAsyncThunk<
    void,
    { sessionID: string; projectID: string }
>("record/deleteSession", async (args) => {
    const { sessionID, projectID } = args;

    const deleteSession = functions.httpsCallable("deleteSession");
    deleteSession({ sessionID, projectID });

    await firebase.firestore().collection("session").doc(sessionID).delete();
});

export const deleteActionEvent = createAsyncThunk<
    void,
    { projectID: string; testCaseID: string; actionEventID: string }
>("record/deleteActionEvent", async (args) => {
    const { projectID, testCaseID, actionEventID } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("actionEvent")
        .doc(actionEventID)
        .delete();

    const assertions = await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .where("actionEventID", "==", actionEventID)
        .get();
    assertions.forEach(async (assertion) => {
        await assertion.ref.delete();
    });
});

export const updateUsingSession = createAsyncThunk<
    void,
    { projectID: string; sessionID: string }
>("record/updateUsingSession", async (args) => {
    const { projectID, sessionID } = args;

    const appInfoDocs = await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("appInfo")
        .where("deletedAt", "==", null)
        .orderBy("createdAt", "desc")
        .limit(1)
        .get();

    const appInfos = parseDocuments<IAppInfo>(appInfoDocs).map(
        (doc) => new AppInfo(doc)
    );

    await firebase.firestore().collection("session").doc(sessionID).set({
        projectID,
        sessionID,
        lastActiveAt: new Date(),
        bundleID: appInfos[0].data.bundleIdentifier,
    });
});
