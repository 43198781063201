import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../../firebaseConfig";
import { ISlack } from "../../../../entities/src/project";

interface SlackChatsRequest {
    slack: ISlack;
    text: string;
}

export const sendMessage = createAsyncThunk<void, SlackChatsRequest>(
    "integrations/slackChats",
    async (params, thunk) => {
        const client = functions.httpsCallable("slackChats");
        await client({
            slack: params.slack,
            text: params.text,
        });
    }
);
