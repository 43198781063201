import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authorizeSlackWithToken } from "./effects";

export type authCallbackState = {
    hasRequestedSlack: Boolean;
};

export const initialState: authCallbackState = {
    hasRequestedSlack: false,
};

const authCallback = createSlice({
    name: "authCallback",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(authorizeSlackWithToken.fulfilled, (state) => {
            state.hasRequestedSlack = true;
        });
    },
});

export const {} = authCallback.actions;

export default authCallback;
