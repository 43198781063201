import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";

interface ConfirmTestCaseProps {
    testCaseName: string;
    onChangeTestCaseName?: (text: string) => void;
    onClickSaveButton?: () => void;
    onClickCancelButton?: () => void;
}

export const ConfirmTestCase = (props: ConfirmTestCaseProps) => {
    return (
        <Grid item container>
            <Paper elevation={0} style={{ padding: 16 }}>
                <Grid container spacing={4} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <TextField
                            required
                            variant="outlined"
                            style={{ width: 256 }}
                            placeholder="テストケース名(必須)"
                            value={props.testCaseName}
                            onChange={(event) => {
                                if (!props.onChangeTestCaseName) {
                                    return;
                                }
                                props.onChangeTestCaseName(
                                    event.currentTarget.value
                                );
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={props.onClickCancelButton}
                        >
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!props.testCaseName}
                            onClick={props.onClickSaveButton}
                        >
                            <Typography noWrap>保存する</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
