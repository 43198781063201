import {
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableContainer,
    Typography,
    TableBody,
    Divider,
    IconButton,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useProjectState } from "../../ducks/project/selectors";
import { CreateVariableModal } from "../organisms/variables/createVariableModal";
import { createVariable } from "../../ducks/project/effects";
import {
    deleteVariable,
    fetchActionEventsWithVariable,
    fetchMyTestCases,
} from "../../ducks/variables/effects";
import { ConfirmDeleteModal } from "../organisms/confirmDeleteModal";
import { clearUsedActionEvents } from "../../ducks/variables/slice";
import { useVariablesState } from "../../ducks/variables/selectors";

export const Variables = () => {
    const dispatch = useDispatch();
    const projectState = useProjectState().project;
    const state = useVariablesState().variables;

    const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
    /**
     * variableIDがセットされたときに、削除モーダルを表示する
     */
    const [deletingVariableID, setDeletingVariableID] = React.useState<
        string | null
    >(null);

    const projectID = projectState.selectedProject?.data.id;

    useEffect(() => {
        if (!projectID) {
            return;
        }
        dispatch(fetchMyTestCases({ projectID }));
    }, []);

    useEffect(() => {
        if (deletingVariableID) {
            return;
        }

        dispatch(clearUsedActionEvents());
    }, [deletingVariableID]);

    const usedTestCases = Object.keys(state.usedActionEvents ?? {})
        .flatMap((testCaseID) =>
            state.testCases.filter(
                (testCase) => testCase.data.id === testCaseID
            )
        )
        .map((testCase) => testCase.data.name);
    const canDelete = usedTestCases.length === 0;

    return (
        <Container maxWidth="sm">
            <ConfirmDeleteModal
                isOpen={
                    deletingVariableID !== null &&
                    state.usedActionEvents !== null
                }
                title="この変数を削除しますか？"
                actionText="削除する"
                message={(() => {
                    if (canDelete) {
                        return undefined;
                    }
                    return (
                        usedTestCases.reduce(
                            (prev, curr) => prev + "、" + curr
                        ) + "で使用されているため、削除できません。"
                    );
                })()}
                disabled={!canDelete}
                onClick={() => {
                    if (!projectID || !deletingVariableID || !canDelete) {
                        return;
                    }
                    dispatch(
                        deleteVariable({
                            projectID,
                            variableID: deletingVariableID,
                        })
                    );
                    setDeletingVariableID(null);
                }}
                onClose={() => {
                    setDeletingVariableID(null);
                }}
            />
            <CreateVariableModal
                isOpen={isModalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                onCreate={(name, value) => {
                    if (!projectID) {
                        return;
                    }
                    dispatch(createVariable({ projectID, name, value }));
                    setModalOpen(false);
                }}
            />
            <Grid
                container
                direction="column"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
                spacing={3}
            >
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h6">変数</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            追加する
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container justify="center">
                    <TableContainer>
                        <Divider />
                        <Table>
                            <TableHead style={{ backgroundColor: grey["50"] }}>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2">
                                            変数名
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2">
                                            値
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectState.variables.map((variable) => (
                                    <TableRow>
                                        <TableCell>
                                            {variable.data.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {variable.data.value}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    if (!projectID) {
                                                        return;
                                                    }
                                                    setDeletingVariableID(
                                                        variable.data.id
                                                    );
                                                    dispatch(
                                                        fetchActionEventsWithVariable(
                                                            {
                                                                projectID,
                                                                variableID:
                                                                    variable
                                                                        .data
                                                                        .id,
                                                            }
                                                        )
                                                    );
                                                }}
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
};
