import { IAssertionResult } from "../../../entities/src/assertionResult";
import { Modelable } from "../../../entities/src/modelable";
import {
    SIMILARITY_ABOVE_TITLE,
    ELEMENT_EXISTS_TITLE,
    ELEMENT_SIZE_TITLE,
    ELEMENT_POSITION_TITLE,
    TEXT_EQUALS_TITLE,
    TEXT_CONTAINS_TITLE,
} from "./assertion";

export class AssertionResult implements Modelable<IAssertionResult> {
    constructor(public data: IAssertionResult) {}

    getTitle = () => {
        switch (this.data.criteria) {
            case "SIMILARITY_ABOVE":
                return SIMILARITY_ABOVE_TITLE(this.data.value);
            case "ELEMENT_EXISTS":
                return ELEMENT_EXISTS_TITLE;
            case "ELEMENT_SIZE":
                return ELEMENT_SIZE_TITLE;
            case "ELEMENT_POSITION":
                return ELEMENT_POSITION_TITLE;
            case "TEXT_EQUALS":
                return TEXT_EQUALS_TITLE;
            case "TEXT_CONTAINS":
                return TEXT_CONTAINS_TITLE;
        }
    };
}
