import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { useHeaderBarState } from "../../ducks/headerBar/selectors";
import { useFirebase } from "react-redux-firebase";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { grey } from "@material-ui/core/colors";
import smartQALogo from "../../assets/small-logo.png";
import project from "../../ducks/project/slice";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            boxShadow: "none",
            backgroundColor: "rgba(0, 0, 0, 0)",
        },
        title: { marginLeft: theme.spacing(4) },
        test: {
            marginLeft: theme.spacing(8),
        },
        run: {
            marginLeft: theme.spacing(2),
        },
        build: {
            marginLeft: theme.spacing(2),
            flexGrow: 1,
        },
    })
);

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export const HeaderBar = () => {
    const classes = useStyles();
    const authState = useHeaderBarState().firebase.auth;
    const projectState = useHeaderBarState().project;
    const firebase = useFirebase();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickConstantRun = () => {
        setAnchorEl(null);
        history.push("/constant-run");
    };

    const handleClickIntegration = () => {
        setAnchorEl(null);
        history.push("/integrations");
    };

    const handleClickAPIKey = () => {
        setAnchorEl(null);
        history.push("/api-key");
    };

    const handleClickMember = () => {
        setAnchorEl(null);
        history.push("/member");
    };

    const handleClickVariable = () => {
        setAnchorEl(null);
        history.push("/variables");
    };

    const handleClickBilling = () => {
        setAnchorEl(null);
        history.push("/billing");
    };

    const handleClickCheckout = () => {
        setAnchorEl(null);
        history.push("/checkout");
    };

    const handleClickLogOut = async () => {
        setAnchorEl(null);
        await firebase.auth().signOut();
        history.push("/login");
    };

    return (
        /// AppBarを固定にしたら、ホーム画面の`テストケースを追加する`が食い込むようになってしまったので回避
        <AppBar
            position="relative"
            className={classes.appBar}
            style={{ backgroundColor: grey[50] }}
        >
            {!authState.isEmpty && authState.isLoaded ? (
                <Grid
                    container
                    component={Toolbar}
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid
                        item
                        container
                        alignItems="center"
                        spacing={3}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Link to="/" className={classes.title}>
                                <img
                                    src={`/${smartQALogo}`}
                                    height="16"
                                    style={{ marginTop: 4 }}
                                />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/">
                                <Typography color="primary">
                                    テスト一覧
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/test-suites">
                                <Typography color="primary">
                                    テスト結果
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/builds">
                                <Typography color="primary">ビルド</Typography>
                            </Link>
                        </Grid>
                        {projectState.subscription === null && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleClickCheckout();
                                    }}
                                >
                                    アップグレード
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justify="flex-end"
                        spacing={1}
                        wrap="nowrap"
                    >
                        <Grid item wrap="nowrap">
                            <a
                                href="https://www.notion.so/levetty/SmartQA-e6e9394102bc4402b12c8d474835f7cf"
                                target="_blank"
                            >
                                <Typography color="primary" noWrap>
                                    ドキュメント
                                </Typography>
                            </a>
                        </Grid>
                        {projectState.selectedProject ? (
                            <Grid item>
                                <Button
                                    color="primary"
                                    endIcon={<Icon>arrow_drop_down</Icon>}
                                    onClick={handleClick}
                                >
                                    <Typography noWrap>
                                        {projectState.selectedProject.data.name}
                                    </Typography>
                                </Button>
                                <StyledMenu
                                    id="customized-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClickConstantRun}>
                                        <ListItemText
                                            primary="定期実行"
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem onClick={handleClickIntegration}>
                                        <ListItemText
                                            primary="Slack通知"
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem onClick={handleClickAPIKey}>
                                        <ListItemText
                                            primary="連携"
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemText
                                            onClick={handleClickMember}
                                            primary="メンバー"
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <ListItemText
                                            onClick={handleClickVariable}
                                            primary="変数"
                                            primaryTypographyProps={{
                                                variant: "body2",
                                            }}
                                        />
                                    </MenuItem>
                                    {projectState.subscription && (
                                        <MenuItem>
                                            <ListItemText
                                                onClick={handleClickBilling}
                                                primary="請求"
                                                primaryTypographyProps={{
                                                    variant: "body2",
                                                }}
                                            />
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleClickLogOut}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: "secondary",
                                                variant: "body2",
                                            }}
                                            primary="ログアウト"
                                        />
                                    </MenuItem>
                                </StyledMenu>
                            </Grid>
                        ) : (
                            <div>
                                <Button
                                    color="primary"
                                    endIcon={<Icon>arrow_drop_down</Icon>}
                                    onClick={handleClick}
                                >
                                    <Typography>メニュー</Typography>
                                </Button>
                                <StyledMenu
                                    id="customized-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClickLogOut}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: "secondary",
                                                variant: "body2",
                                            }}
                                            primary="ログアウト"
                                        />
                                    </MenuItem>
                                </StyledMenu>
                            </div>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Toolbar>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        color="primary"
                    >
                        <Link to="/">
                            <img
                                src={smartQALogo}
                                height="16"
                                style={{ marginTop: 4 }}
                            />
                        </Link>
                    </Typography>
                </Toolbar>
            )}
        </AppBar>
    );
};
