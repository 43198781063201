import {
    Box,
    Button,
    Checkbox,
    Dialog,
    Fade,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { AssertionCriteria } from "../../../../../entities/src/assertion";
import { AssertionList } from "../../../components/molecules/assertionList";
import { ActionEvent } from "../../../models/actionEvent";
import { Assertion } from "../../../models/assertion";
import { IOSNode } from "../../../models/iOSNode";
import { EditImageSimilarity } from "./editImageSimilarity";
import { useDispatch } from "react-redux";
import {
    createAssertion,
    deleteAssertion,
    fetchTreeXML,
    updateActionEventText,
    updateActionEventVariable,
    updateAssertion,
    updateSimilarityAssertionValue,
    updateTapElement,
} from "../../../ducks/home/effects";
import Shuffle from "@material-ui/icons/Shuffle";
import { Device } from "../../../models/device";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { EditActionView } from "./editActionView";
import { useProjectState } from "../../../ducks/project/selectors";
const randomColor = require("randomcolor");

interface AssertionModalProps {
    isOpen: boolean;
    actionEvent: ActionEvent;
    assertions?: Assertion[];
    isLoading: boolean;
    iOSNodes: IOSNode[];
    projectID: string;
    testCaseID: string;
    device: Device;
    onModifyTestCase?: (actionEvent: ActionEvent) => void;
    onClose?: () => void;
    onMoveToLeft?: () => void;
    onMoveToRight?: () => void;
}

type TabMenu = "ASSERTION" | "ACTION";
export type EditType =
    | "SCREENSHOT_SIMILARITY"
    | "ASSERTION_ELEMENT"
    | "INPUT_ACTION"
    | "TAP_ELEMENT";

const isEditElement = (editType: EditType | null): boolean => {
    if (!editType) {
        return false;
    }
    switch (editType) {
        case "SCREENSHOT_SIMILARITY":
        case "INPUT_ACTION":
            return false;
        case "ASSERTION_ELEMENT":
        case "TAP_ELEMENT":
            return true;
    }
};

export const AssertionModal = (props: AssertionModalProps) => {
    const {
        isOpen,
        actionEvent,
        assertions,
        isLoading,
        iOSNodes,
        projectID,
        testCaseID,
        device,
        onModifyTestCase,
        onClose,
        onMoveToLeft,
        onMoveToRight,
    } = props;
    const SCREENSHOT_WIDTH = device.data.name.startsWith("iPad") ? 480 : 320;
    const scale = SCREENSHOT_WIDTH / device.getRect().width;

    const projectState = useProjectState().project;

    const dispatch = useDispatch();

    const [
        selectedIOSNode,
        setSelectedIOSNode,
    ] = React.useState<IOSNode | null>(null);
    const [nodeValue, setNodeValue] = React.useState<string>("");
    const [
        selectedCriteria,
        setSelectedCriteria,
    ] = React.useState<AssertionCriteria>("ELEMENT_EXISTS");

    /**
     * 編集の種類
     */
    const [editType, setEditType] = React.useState<EditType | null>(null);

    const [inputText, setInputText] = React.useState<string>("");
    const [isReversed, setReversed] = React.useState<boolean>(false);
    const [
        editingAssertion,
        setEditingAssertion,
    ] = React.useState<Assertion | null>(null);

    const [
        selectedAssertion,
        setSelectedAssertion,
    ] = React.useState<Assertion | null>(null);
    const [isMenuOpened, setMenuOpened] = React.useState<boolean>(false);
    const [
        anchorElement,
        setAnchorElement,
    ] = React.useState<null | HTMLElement>(null);
    const [hoveredIOSNode, setHoveredIOSNode] = React.useState<IOSNode | null>(
        null
    );
    const [isOnlyImportant, setOnlyImportant] = React.useState<boolean>(true);
    const [tabMenu, setTabMenu] = React.useState<TabMenu>("ASSERTION");

    let similarityAssertion: Assertion | null = null;
    if (assertions) {
        similarityAssertion = assertions.filter(
            (assertion) => assertion.data.criteria === "SIMILARITY_ABOVE"
        )[0];
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case "ArrowRight":
                if (onMoveToRight) {
                    onMoveToRight();
                }
                break;
            case "ArrowLeft":
                if (onMoveToLeft) {
                    onMoveToLeft();
                }
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        clearState();
    }, [isOpen]);

    useEffect(() => {
        clearState();

        const treeURL = actionEvent.data.treeURL;
        if (treeURL) {
            dispatch(fetchTreeXML(treeURL));
        }
    }, [actionEvent.data.id]);

    const clearState = () => {
        setSelectedIOSNode(null);
        setNodeValue("");
        setSelectedCriteria("ELEMENT_EXISTS");
        setEditType(null);
        setReversed(false);
        setEditingAssertion(null);
        setTabMenu("ASSERTION");
        setInputText(actionEvent.getDescription(projectState.variables) ?? "");
    };

    const clearMenuState = () => {
        setMenuOpened(false);
        setAnchorElement(null);
        setSelectedAssertion(null);
        setHoveredIOSNode(null);
    };

    const getBorderProperty = (iOSNode: IOSNode) => {
        if (editingAssertion?.data.criteria === "SIMILARITY_ABOVE") {
            return "0px";
        }

        if (selectedIOSNode && iOSNode.equals(selectedIOSNode)) {
            return "4px solid rgba(255, 82, 82, 0.5)";
        } else if (hoveredIOSNode && iOSNode.equals(hoveredIOSNode)) {
            return "4px solid rgba(255, 82, 82, 0.5)";
        }
        return "0px";
    };
    const getIOSNodeColor = (iOSNode: IOSNode) => {
        if (isEditElement(editType)) {
            return randomColor({
                seed: JSON.stringify(iOSNode.data),
                luminosity: "light",
                format: "rgba",
                alpha: 0.6,
            });
        }
        if (hoveredIOSNode && iOSNode.equals(hoveredIOSNode)) {
            return "rgba(0, 0, 255, 0.4)";
        }

        return "transparent";
    };

    const _filteredIOSNodes = iOSNodes.filter(
        (node) => node.data.type !== "XCUIElementTypeOther" || !isOnlyImportant
    );
    const sortedIOSNodes = isReversed
        ? _filteredIOSNodes.slice().reverse()
        : _filteredIOSNodes;

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                if (!onClose) {
                    return;
                }
                onClose();
            }}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={isOpen} timeout={500}>
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-around"
                    direction="column"
                    spacing={4}
                >
                    <Grid container justify="flex-end">
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    if (!onClose) {
                                        return;
                                    }
                                    onClose();
                                }}
                            >
                                <Close htmlColor={grey[500]} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" justify="space-around">
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            spacing={2}
                            justify="space-around"
                            xs={6}
                        >
                            <Grid item>
                                <Box
                                    style={{
                                        backgroundSize: "contain",
                                        overflow: "hidden",
                                        position: "relative",
                                    }}
                                    width={SCREENSHOT_WIDTH}
                                >
                                    <img
                                        src={actionEvent?.data.screenshotURL}
                                        width={SCREENSHOT_WIDTH}
                                        style={{ position: "relative" }}
                                    />
                                    {editType === null &&
                                        actionEvent.data.iOSNode && (
                                            <Paper
                                                variant="outlined"
                                                style={{
                                                    position: "absolute",
                                                    left:
                                                        (actionEvent.data
                                                            .iOSNode.x *
                                                            SCREENSHOT_WIDTH) /
                                                        device.getRect().width,
                                                    top:
                                                        (actionEvent.data
                                                            .iOSNode.y *
                                                            SCREENSHOT_WIDTH) /
                                                        device.getRect().width,
                                                    width:
                                                        (actionEvent.data
                                                            .iOSNode.width *
                                                            SCREENSHOT_WIDTH) /
                                                        device.getRect().width,
                                                    height:
                                                        (actionEvent.data
                                                            .iOSNode.height *
                                                            SCREENSHOT_WIDTH) /
                                                        device.getRect().width,
                                                    backgroundColor:
                                                        "rgba(82, 82, 255, 0.2)",
                                                    border:
                                                        "4px solid rgba(82, 82, 255, 0.5)",
                                                }}
                                                square
                                            />
                                        )}
                                    {sortedIOSNodes.map((node) => {
                                        return (
                                            <Paper
                                                key={JSON.stringify(node.data)}
                                                square
                                                variant="outlined"
                                                style={{
                                                    position: "absolute",
                                                    left: node.data.x * scale,
                                                    top: node.data.y * scale,
                                                    width:
                                                        node.data.width * scale,
                                                    height:
                                                        node.data.height *
                                                        scale,
                                                    backgroundColor: getIOSNodeColor(
                                                        node
                                                    ),
                                                    cursor: "pointer",
                                                    border: getBorderProperty(
                                                        node
                                                    ),
                                                }}
                                                onClick={() => {
                                                    if (
                                                        !isEditElement(editType)
                                                    ) {
                                                        return;
                                                    }
                                                    setSelectedIOSNode(node);
                                                    setNodeValue(
                                                        node.data.value ??
                                                            node.data.label ??
                                                            ""
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                </Box>
                            </Grid>
                            {isEditElement(editType) ? (
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="caption">
                                            表示順をかえる
                                        </Typography>
                                        <IconButton
                                            onClick={() =>
                                                setReversed(!isReversed)
                                            }
                                        >
                                            <Shuffle />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">
                                            重要な要素のみ表示
                                        </Typography>
                                        <Checkbox
                                            checked={isOnlyImportant}
                                            color="primary"
                                            onClick={() => {
                                                setOnlyImportant(
                                                    !isOnlyImportant
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    direction="column"
                                >
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => {
                                                if (!onModifyTestCase) {
                                                    return;
                                                }
                                                onModifyTestCase(actionEvent);
                                            }}
                                        >
                                            このステップ以降の修正をする
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">
                                            ※シミュレータが起動します
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            spacing={4}
                            xs={6}
                        >
                            {!editType && (
                                <Grid item container>
                                    <Grid item>
                                        <Tabs
                                            value={tabMenu}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={(_, value) => {
                                                setTabMenu(value);
                                                clearMenuState();
                                            }}
                                        >
                                            <Tab
                                                label="アサーション"
                                                value="ASSERTION"
                                            />
                                            <Tab
                                                label="アクション"
                                                value="ACTION"
                                            />
                                        </Tabs>
                                    </Grid>
                                </Grid>
                            )}

                            {!editType && tabMenu === "ASSERTION" && (
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    spacing={2}
                                    justify="space-around"
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        spacing={1}
                                        justify="space-around"
                                    >
                                        {assertions &&
                                            assertions?.map((assertion) => (
                                                <AssertionList
                                                    key={assertion.data.id}
                                                    assertion={assertion}
                                                    onClickMoreButton={(
                                                        element
                                                    ) => {
                                                        setMenuOpened(true);
                                                        setAnchorElement(
                                                            element
                                                        );
                                                        setSelectedAssertion(
                                                            assertion
                                                        );
                                                    }}
                                                    onHover={(assertion) => {
                                                        if (
                                                            !assertion.data
                                                                .iOSNode
                                                        ) {
                                                            setHoveredIOSNode(
                                                                null
                                                            );
                                                            return;
                                                        }
                                                        const iOSNode = new IOSNode(
                                                            assertion.data.iOSNode
                                                        );
                                                        setHoveredIOSNode(
                                                            iOSNode
                                                        );
                                                    }}
                                                />
                                            ))}
                                    </Grid>
                                    <Menu
                                        keepMounted={false}
                                        open={isMenuOpened}
                                        onClose={() => {
                                            clearMenuState();
                                        }}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        anchorEl={anchorElement}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                if (!selectedAssertion) {
                                                    return;
                                                }
                                                if (
                                                    selectedAssertion.data
                                                        .iOSNode
                                                ) {
                                                    setSelectedIOSNode(
                                                        new IOSNode(
                                                            selectedAssertion.data.iOSNode
                                                        )
                                                    );
                                                }
                                                if (
                                                    selectedAssertion.data
                                                        .criteria ===
                                                    "SIMILARITY_ABOVE"
                                                ) {
                                                    setEditType(
                                                        "SCREENSHOT_SIMILARITY"
                                                    );
                                                } else {
                                                    setEditType(
                                                        "ASSERTION_ELEMENT"
                                                    );
                                                }

                                                setSelectedCriteria(
                                                    selectedAssertion.data
                                                        .criteria
                                                );
                                                setNodeValue(
                                                    selectedAssertion.data
                                                        .text ?? ""
                                                );
                                                setEditingAssertion(
                                                    selectedAssertion
                                                );

                                                clearMenuState();
                                            }}
                                        >
                                            <Typography>編集する</Typography>
                                        </MenuItem>
                                        {selectedAssertion &&
                                            selectedAssertion.data.criteria !==
                                                "SIMILARITY_ABOVE" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        if (
                                                            !selectedAssertion
                                                        ) {
                                                            return;
                                                        }
                                                        dispatch(
                                                            deleteAssertion({
                                                                projectID: projectID,
                                                                testCaseID: testCaseID,
                                                                assertionID:
                                                                    selectedAssertion
                                                                        .data
                                                                        .id,
                                                            })
                                                        );
                                                        clearMenuState();
                                                    }}
                                                >
                                                    <Typography color="error">
                                                        削除する
                                                    </Typography>
                                                </MenuItem>
                                            )}
                                    </Menu>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setEditType(
                                                    "ASSERTION_ELEMENT"
                                                );
                                                clearMenuState();
                                            }}
                                        >
                                            アサーションを追加する
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {tabMenu === "ACTION" && (
                                <EditActionView
                                    actionEvent={actionEvent}
                                    editType={editType}
                                    setEditType={setEditType}
                                    selectedIOSNode={selectedIOSNode}
                                    setSelectedIOSNode={setSelectedIOSNode}
                                    handleUpdateInputText={(text) => {
                                        dispatch(
                                            updateActionEventText({
                                                testCaseID,
                                                projectID,
                                                actionEventID:
                                                    actionEvent.data.id,
                                                text,
                                            })
                                        );
                                    }}
                                    handleUpdateVariable={(variable) => {
                                        dispatch(
                                            updateActionEventVariable({
                                                testCaseID,
                                                projectID,
                                                actionEventID:
                                                    actionEvent.data.id,
                                                variableID: variable.data.id,
                                            })
                                        );
                                    }}
                                    handleUpdateTapElement={(iOSNode) => {
                                        dispatch(
                                            updateTapElement({
                                                testCaseID,
                                                projectID,
                                                actionEventID:
                                                    actionEvent.data.id,
                                                iOSNode: iOSNode,
                                            })
                                        );
                                    }}
                                />
                            )}
                            {editType === "SCREENSHOT_SIMILARITY" && (
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    justify="center"
                                    xs={10}
                                >
                                    <EditImageSimilarity
                                        assertion={similarityAssertion!}
                                        isLoading={isLoading}
                                        onUpdate={(value) => {
                                            if (editingAssertion) {
                                                dispatch(
                                                    updateSimilarityAssertionValue(
                                                        {
                                                            testCaseID: testCaseID,
                                                            assertionID:
                                                                editingAssertion
                                                                    .data.id,
                                                            value: value,
                                                            projectID: projectID,
                                                        }
                                                    )
                                                );
                                            }
                                            clearState();
                                        }}
                                    />
                                </Grid>
                            )}
                            {editType === "ASSERTION_ELEMENT" && (
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    justify="center"
                                    xs={10}
                                >
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        component={Paper}
                                        style={{ padding: 8 }}
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <IconButton
                                                onClick={() => clearState()}
                                            >
                                                <NavigateBefore />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {selectedIOSNode?.data.type ??
                                                    "要素をクリックしてください"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                fullWidth
                                                onChange={(event) => {
                                                    setSelectedCriteria(
                                                        event.target
                                                            .value as AssertionCriteria
                                                    );
                                                }}
                                                value={selectedCriteria}
                                            >
                                                {Object.entries(
                                                    Assertion.getNodeAssertionTitles()
                                                ).map(([criteria, title]) => (
                                                    <MenuItem value={criteria}>
                                                        {title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        {selectedCriteria.startsWith(
                                            "TEXT"
                                        ) && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    value={nodeValue}
                                                    onChange={(event) =>
                                                        setNodeValue(
                                                            event.currentTarget
                                                                .value
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    if (!selectedIOSNode) {
                                                        return;
                                                    }
                                                    if (editingAssertion) {
                                                        // アサーションの編集の場合
                                                        dispatch(
                                                            updateAssertion({
                                                                assertion: editingAssertion,
                                                                assertionCriteria: selectedCriteria,
                                                                iOSNode: selectedIOSNode,
                                                                text: nodeValue,
                                                                projectID: projectID,
                                                                testCaseID: testCaseID,
                                                            })
                                                        );
                                                    } else {
                                                        // アサーションを作成する場合
                                                        dispatch(
                                                            createAssertion({
                                                                actionEvent: actionEvent,
                                                                assertionCriteria: selectedCriteria,
                                                                iOSNode: selectedIOSNode,
                                                                text: nodeValue,
                                                                projectID: projectID,
                                                                testCaseID: testCaseID,
                                                            })
                                                        );
                                                    }
                                                    clearState();
                                                }}
                                            >
                                                {editingAssertion === null
                                                    ? "追加する"
                                                    : "更新する"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justify="space-around"
                        direction="column"
                    >
                        <Grid item container wrap="nowrap" justify="center">
                            <IconButton onClick={onMoveToLeft}>
                                <ArrowBack />
                            </IconButton>
                            <IconButton onClick={onMoveToRight}>
                                <ArrowForward />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                「←」または「→」キーを押すことでも遷移できます
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        </Dialog>
    );
};
