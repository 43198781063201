import {
    Box,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import { ActionEvent } from "../../../models/actionEvent";
import { Assertion } from "../../../models/assertion";
import { TestCase } from "../../../models/testCase";
import documentImage from "../../../assets/document.png";
import { ActionView } from "../../../components/molecules/actionView";
import { Device } from "../../../models/device";

interface HomeActionProps {
    testCase: TestCase;
    dependedTestCases: TestCase[];
    actionEvents?: ActionEvent[];
    assertions: Assertion[] | undefined;
    deviceWidth: number;
    deviceHeight: number;
    screenshotWidth: number;
    onClick?: (testCaseID: string, actionEventID: string) => void;
}

export const HomeActionList = (props: HomeActionProps) => {
    const {
        testCase,
        dependedTestCases,
        actionEvents,
        deviceWidth,
        deviceHeight,
        screenshotWidth,
        onClick,
    } = props;

    const device = new Device(testCase.data.device);

    let assertionsDict: { [actionEventID: string]: Assertion[] } = {};

    props.assertions?.forEach((assertion) => {
        if (!assertionsDict[assertion.data.actionEventID]) {
            assertionsDict[assertion.data.actionEventID] = [];
        }
        assertionsDict[assertion.data.actionEventID].push(assertion);
    });

    return (
        <List
            style={{
                overflowY: "hidden",
                overflowX: "scroll",
                display: "flex",
                alignItems: "flex-end",
                height: device.getResizedRect(screenshotWidth).height + 96,
            }}
        >
            {dependedTestCases.map((testCase) => (
                <ListItem
                    id={testCase.data.id}
                    divider={true}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: 160,
                        cursor: "pointer",
                    }}
                >
                    <ListItemText
                        primary={testCase.data.name}
                        secondary="省略"
                        primaryTypographyProps={{ variant: "body2" }}
                        secondaryTypographyProps={{ variant: "caption" }}
                        style={{ textAlign: "center" }}
                    />
                    <Box
                        style={{
                            width: screenshotWidth,
                            height:
                                (screenshotWidth * deviceHeight) / deviceWidth,
                            backgroundColor: grey[200],
                            backgroundSize: "contain",
                            overflow: "hidden",
                            position: "relative",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Box
                            style={{
                                width: screenshotWidth / 2,
                                height: screenshotWidth / 2,
                                backgroundSize: "contain",
                                backgroundImage: `url("${documentImage}")`,
                            }}
                        />
                    </Box>
                </ListItem>
            ))}
            {actionEvents &&
                actionEvents.map((actionEvent) => (
                    <ActionView
                        key={actionEvent.data.id}
                        testCase={testCase}
                        actionEvent={actionEvent}
                        assertions={assertionsDict[actionEvent.data.id]}
                        device={new Device(testCase.data.device)}
                        screenshotWidth={screenshotWidth}
                        onClickWithInfo={onClick}
                        canDelete={false}
                    />
                ))}
        </List>
    );
};
