import { useSelector } from "react-redux";
import { FirebaseState } from "../../utilities/firebaseState";
import { ProjectState } from "../project/slice";
import { BuildsState } from "./slice";

export const useBuildsState = () => {
    return useSelector(
        (state: {
            builds: BuildsState;
            project: ProjectState;
            firebase: FirebaseState;
        }) => state
    );
};
