import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { IRunSchedule } from "../../../../entities/src/runSchedule";
import { RunSchedule } from "../../models/runSchedule";
import { parseDocument } from "../../utilities/parseDocument";

export const fetchRunSchedule = createAsyncThunk<
    RunSchedule,
    { projectID: string }
>("constantRun/fetchRunSchedule", async (params) => {
    const { projectID } = params;
    const firestore = getFirebase().firestore();

    const runScheduleDoc = await firestore
        .collection("project")
        .doc(projectID)
        .collection("runSchedule")
        .where("deletedAt", "==", null)
        .get();

    const runSchedule = new RunSchedule(
        parseDocument<IRunSchedule>(runScheduleDoc.docs[0])
    );
    return runSchedule;
});

export const updateRunSchedule = createAsyncThunk<
    RunSchedule,
    {
        projectID: string;
        runSchedule: RunSchedule;
        dayOfWeek: number[];
        hour: number;
    }
>("constantRun/updateRunSchedule", async (params) => {
    const { projectID, runSchedule, dayOfWeek, hour } = params;
    const firestore = getFirebase().firestore();

    const sortedDayOfWeek = dayOfWeek.slice().sort();

    await firestore
        .collection("project")
        .doc(projectID)
        .collection("runSchedule")
        .doc(runSchedule.data.id)
        .update({ dayOfWeek: sortedDayOfWeek, hour, updatedAt: new Date() });

    const resultRunSchedule = new RunSchedule({
        ...runSchedule.data,
        dayOfWeek: sortedDayOfWeek,
        hour,
        updatedAt: new Date(),
    });

    return resultRunSchedule;
});
