import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { ActionResult } from "../../models/actionResult";
import { RunResult } from "../../models/runResult";
import { TestCase } from "../../models/testCase";
import { TestSuite } from "../../models/testSuite";
import { functions } from "../../firebaseConfig";
import { parseDocument, parseDocuments } from "../../utilities/parseDocument";
import { ITestSuite } from "../../../../entities/src/testSuite";
import { ITestCase } from "../../../../entities/src/testCase";
import { IRunResult } from "../../../../entities/src/runResult";
import { IActionResult } from "../../../../entities/src/actionResult";
import { AssertionResult } from "../../models/assertionResult";
import { IAssertionResult } from "../../../../entities/src/assertionResult";

export const listenMyTestSuites = createAsyncThunk<void, string>(
    "testSuites/listenMyTestSuites",
    async (projectID, thunk) => {
        const firebase = getFirebase();
        const firestore = firebase.firestore();

        await firestore
            .collection("project")
            .doc(projectID)
            .collection("testSuite")
            .orderBy("runNumber", "desc")
            .limit(10)
            .onSnapshot((snapshot) => {
                const docs = snapshot
                    .docChanges()
                    .filter((doc) => doc.type !== "removed");
                const testSuites = docs.map(
                    (doc) => new TestSuite(parseDocument<ITestSuite>(doc.doc))
                );
                thunk.dispatch(updateMyTestSuites(testSuites));
            });
    }
);

export const updateMyTestSuites = createAsyncThunk<TestSuite[], TestSuite[]>(
    "testSuites/updateMyTestSuites",
    (args) => args
);

export const fetchMyTestCases = createAsyncThunk<TestCase[], string>(
    "testSuites/fetchMyTestCases",
    async (projectID, thunk) => {
        const firebase = getFirebase();
        const firestore = firebase.firestore();

        const testCasesDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .where("isSaved", "==", true)
            .orderBy("createdAt", "desc")
            .get();

        const testCases = parseDocuments<ITestCase>(testCasesDoc).map(
            (testCaseData) => new TestCase(testCaseData)
        );

        return testCases;
    }
);

export const listenRunResults = createAsyncThunk<
    void,
    { projectID: string; testSuiteID: string }
>("testSuites/listenRunResults", async (args, thunk) => {
    const { projectID, testSuiteID } = args;

    const firebase = getFirebase();
    const firestore = firebase.firestore();

    await firestore
        .collection("project")
        .doc(projectID)
        .collection("testSuite")
        .doc(testSuiteID)
        .collection("runResult")
        .where("deletedAt", "==", null)
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
            const docs = snapshot
                .docChanges()
                .filter((doc) => doc.type !== "removed");
            const runResults = docs.map(
                (doc) => new RunResult(parseDocument<IRunResult>(doc.doc))
            );

            const deletedDocs = snapshot
                .docChanges()
                .filter((doc) => doc.type === "removed");
            const deletedRunResults = deletedDocs.map(
                (doc) => new RunResult(parseDocument<IRunResult>(doc.doc))
            );

            thunk.dispatch(
                updateRunResults({ testSuiteID, runResults, deletedRunResults })
            );
        });
});

export const updateRunResults = createAsyncThunk<
    {
        testSuiteID: string;
        runResults: RunResult[];
        deletedRunResults: RunResult[];
    },
    {
        testSuiteID: string;
        runResults: RunResult[];
        deletedRunResults: RunResult[];
    }
>("testSuites/updateRunResults", (args) => args);

export const listenActionResults = createAsyncThunk<
    void,
    { projectID: string; testSuiteID: string; runResultIDs: string[] }
>("testSuites/listenActionResults", async (args, thunk) => {
    const { projectID, testSuiteID, runResultIDs } = args;

    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const listenTasks = runResultIDs.map((runResultID) => {
        return new Promise<{
            runResultID: string;
            actionResults: ActionResult[];
        }>(async (resolve) => {
            await firestore
                .collection("project")
                .doc(projectID)
                .collection("testSuite")
                .doc(testSuiteID)
                .collection("runResult")
                .doc(runResultID)
                .collection("actionResult")
                .orderBy("createdAt", "asc")
                .onSnapshot((snapshot) => {
                    const docs = snapshot
                        .docChanges()
                        .filter((doc) => doc.type !== "removed");
                    const actionResults = docs.map(
                        (doc) =>
                            new ActionResult(
                                parseDocument<IActionResult>(doc.doc)
                            )
                    );
                    thunk.dispatch(
                        updateActionResults({ runResultID, actionResults })
                    );
                });
        });
    });
    await Promise.all(listenTasks);
});

export const updateActionResults = createAsyncThunk<
    { runResultID: string; actionResults: ActionResult[] },
    { runResultID: string; actionResults: ActionResult[] }
>("testSuites/updateActionResults", (args) => args);

export const startTestSuite = createAsyncThunk<
    TestSuite,
    { projectID: string; selectedTestCaseIDs?: string[] }
>("testSuites/startTestSuite", async (args) => {
    const { projectID, selectedTestCaseIDs } = args;
    const body = {
        projectID: projectID,
        selectedTestCaseIDs: selectedTestCaseIDs,
    };

    const client = functions.httpsCallable("run");
    const response = await client(body);
    const testSuite = new TestSuite({
        ...response.data,
        createdAt: new Date(response.data.createdAt),
        updatedAt: new Date(response.data.updatedAt),
    });

    return testSuite;
});

export const listenAssertionResults = createAsyncThunk<
    void,
    { projectID: string; testSuiteID: string; runResultID: string }
>("testSuites/listenAssertionResults", async (args, thunk) => {
    const { projectID, testSuiteID, runResultID } = args;
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const assertionDocs = await firestore
        .collection("project")
        .doc(projectID)
        .collection("testSuite")
        .doc(testSuiteID)
        .collection("runResult")
        .doc(runResultID)
        .collection("assertionResult")
        .where("deletedAt", "==", null)
        .orderBy("createdAt")
        .onSnapshot((snapshot) => {
            const docs = snapshot
                .docChanges()
                .filter((doc) => doc.type !== "removed");
            const assertionResults = docs.map(
                (doc) =>
                    new AssertionResult(
                        parseDocument<IAssertionResult>(doc.doc)
                    )
            );
            thunk.dispatch(
                updateAssertionResults({ runResultID, assertionResults })
            );
        });
});

export const updateAssertionResults = createAsyncThunk<
    { runResultID: string; assertionResults: AssertionResult[] },
    { runResultID: string; assertionResults: AssertionResult[] }
>("testSuite/updateAssertionResults", (args) => args);
