import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";
import { useDispatch } from "react-redux";
import {
    duplicateTestCase,
    fetchActionEvents,
    fetchAssertions,
} from "../../../ducks/home/effects";
import {
    openTestCaseModal,
    dismissTestCaseModal,
    openRenameTestModal,
    openConfirmDeleteModal,
    openAssertionModal,
} from "../../../ducks/home/slice";
import { openPopup } from "../../../ducks/popup/slice";
import { ActionEvent } from "../../../models/actionEvent";
import { Assertion } from "../../../models/assertion";
import { TestCase } from "../../../models/testCase";
import { HomeActionList } from "./homeActionList";

// iPhone 8を想定
const DEVICE_WIDTH = 375;
const DEVICE_HEIGHT = 667;

// 表示するスクリーンショットの幅
const SCREENSHOT_WIDTH = 128;

interface TestCaseListProps {
    testCase: TestCase;
    actionEvents?: ActionEvent[];
    testCases: TestCase[];
    assertions: Assertion[];
    projectID?: string;
    isOpen: boolean;
    canDuplicateTestCase: boolean;
}

export const TestCaseList = (props: TestCaseListProps) => {
    const {
        testCase,
        actionEvents,
        testCases,
        assertions,
        projectID,
        isOpen,
        canDuplicateTestCase,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useDispatch();

    const handleDuplicateTestCase = () => {
        if (!projectID) {
            return;
        }

        if (!canDuplicateTestCase) {
            dispatch(
                openPopup({
                    type: "error",
                    message: "テストケース数が上限に達しています。",
                })
            );
            return;
        }

        dispatch(
            duplicateTestCase({
                projectID,
                testCaseID: testCase.data.id,
            })
        );
    };

    const getDependedTestCases = (
        testCase: TestCase,
        dependedTestCases: TestCase[]
    ): TestCase[] => {
        const dependedTestCase = testCases
            .filter(
                (_testCase) =>
                    _testCase.data.id === testCase.data.dependedTestCaseID
            )
            .slice(-1)[0];

        if (!dependedTestCase) {
            return dependedTestCases;
        }

        const result = [dependedTestCase].concat(dependedTestCases);

        if (dependedTestCase?.data.dependedTestCaseID) {
            return getDependedTestCases(dependedTestCase, result);
        } else {
            return result;
        }
    };

    const fetchTestCaseContents = () => {
        if (actionEvents) {
            return;
        }
        if (!projectID) {
            return;
        }
        dispatch(
            fetchActionEvents({
                testCaseID: testCase.data.id,
                projectID,
            })
        );
        dispatch(
            fetchAssertions({
                testCaseID: testCase.data.id,
                projectID,
            })
        );
    };

    const dependedTestCases = getDependedTestCases(testCase, []);

    return (
        <Grid item container alignItems="center" key={testCase.data.id}>
            <Accordion
                style={{ width: "100%" }}
                onClick={fetchTestCaseContents}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    dispatch(
                                        openTestCaseModal(testCase.data.id)
                                    );
                                    setAnchorEl(event.currentTarget);
                                }}
                                onFocus={(event) => {
                                    event.stopPropagation();
                                }}
                                aria-haspopup="true"
                            >
                                <MoreHoriz htmlColor={grey[500]} />
                            </IconButton>
                        </Grid>
                        <Menu
                            open={isOpen}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onClose={() => {
                                dispatch(dismissTestCaseModal());
                                setAnchorEl(null);
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            anchorEl={anchorEl}
                        >
                            <MenuItem
                                onClick={() => {
                                    dispatch(openRenameTestModal());
                                }}
                            >
                                <Typography variant="body2" noWrap>
                                    名前を変更する
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleDuplicateTestCase}>
                                <Typography variant="body2">
                                    複製する
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    dispatch(openConfirmDeleteModal());
                                }}
                            >
                                <Typography color="error" variant="body2">
                                    削除する
                                </Typography>
                            </MenuItem>
                        </Menu>
                        <Grid item xs={7}>
                            <Typography variant="body1">
                                {testCase.data.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">
                                {testCase.data.device.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="caption">
                                {`作成日: ${testCase.data.createdAt?.toLocaleDateString()}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <HomeActionList
                        testCase={testCase}
                        dependedTestCases={dependedTestCases}
                        actionEvents={actionEvents}
                        assertions={assertions}
                        deviceWidth={DEVICE_WIDTH}
                        deviceHeight={DEVICE_HEIGHT}
                        screenshotWidth={SCREENSHOT_WIDTH}
                        onClick={(actionEventID) => {
                            dispatch(
                                openAssertionModal({
                                    actionEventID: actionEventID,
                                    testCaseID: testCase.data.id,
                                })
                            );
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
