import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    createTapActionEvent,
    createSendKeyActionEvent,
    fetchTestCase,
    launchApp,
    createSwipeActionEvent,
    endTestCase,
    deleteSession,
    executeTestCase,
    createOpenURLActionEvent,
    deleteActionEvent,
    createShakeActionEvent,
} from "./effects";
import { ActionEvent } from "../../models/actionEvent";
import { Element } from "../../models/element";
import { TestCase } from "../../models/testCase";
import { createTestCase, editTestCase } from "../home/effects";

export interface RecordState {
    tapPoint: Point;
    text: string;
    testCase: TestCase | null;
    actions: ActionEvent[];
    isLoading: boolean;
    isActionWaiting: boolean;
    isLaunching: boolean;
    sessionID: string | null;
    streamingURL: string | null;
    streamingURLHash: string;
    nextElement: Element | null;
    isSwiping: boolean;
    fromPoint: Point | null;
    toPoint: Point | null;
    swipeDirection: "up" | "down" | "left" | "right" | null;
    testCaseName: string;
    shouldLeave: boolean;
    shouldExecTestCase: boolean;
    // リフレッシュやページ遷移対策のため1度だけレコードできるようにする。
    isReady: boolean;
    lastActionDate: Date;
}

export type Point = {
    x: number;
    y: number;
};

export const initialState: RecordState = {
    tapPoint: { x: 0, y: 0 },
    text: "",
    actions: [],
    isLoading: false,
    isActionWaiting: false,
    isLaunching: false,
    sessionID: null,
    streamingURL: null,
    streamingURLHash: "",
    nextElement: null,
    isSwiping: false,
    fromPoint: null,
    toPoint: null,
    swipeDirection: null,
    testCaseName: "",
    shouldLeave: false,
    testCase: null,
    shouldExecTestCase: false,
    isReady: false,
    lastActionDate: new Date(),
};

const recorder = createSlice({
    name: "recorder",
    initialState,
    reducers: {
        updateText: (state, action: PayloadAction<string>) => {
            state.text = action.payload;
        },
        tap: (state, action: PayloadAction<Point>) => {
            state.tapPoint = action.payload;
        },
        updateSwiping: (
            state,
            action: PayloadAction<{
                isSwiping: boolean;
                fromPoint: Point;
                toPoint: Point | null;
                direction: "up" | "down" | "left" | "right";
            }>
        ) => {
            const { isSwiping, fromPoint, toPoint, direction } = action.payload;

            if (isSwiping) {
                state.toPoint = toPoint;
                state.swipeDirection = direction;
            }

            // isSwipingの値が変わった時のみ処理する
            if (state.isSwiping === isSwiping) {
                return;
            }
            state.isSwiping = isSwiping;

            if (isSwiping) {
                // スワイプし始めた時
                state.fromPoint = fromPoint;
            } else {
                // スワイプをやめた or カーソルがSimulatorViewの外になった時
                state.fromPoint = null;
                state.toPoint = null;
                state.swipeDirection = null;
            }
        },
        updateTestCaseName: (state, action: PayloadAction<string>) => {
            state.testCaseName = action.payload;
        },
        refreshStreamingURL: (state) => {
            state.streamingURLHash = Date.now().toString();
        },
        clearRecordState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(createTestCase.fulfilled, () => ({
            ...initialState,
            isReady: true,
        }));
        builder.addCase(editTestCase.fulfilled, () => ({
            ...initialState,
            isReady: true,
        }));
        builder.addCase(launchApp.pending, (state) => {
            state.isLaunching = true;
            state.isReady = false;
            state.lastActionDate = new Date();
        });
        builder.addCase(launchApp.rejected, (state) => {
            state.isLaunching = false;
        });
        builder.addCase(launchApp.fulfilled, (state, action) => {
            state.isLaunching = false;
            state.sessionID = action.payload.sessionID;
            state.streamingURL = action.payload.streamingURL;
            state.streamingURLHash = Date.now().toString();
            state.lastActionDate = new Date();

            if (
                state.testCase?.data.dependedTestCaseID ||
                state.testCase?.data.originalTestCaseID
            ) {
                state.shouldExecTestCase = true;
                state.isActionWaiting = true;
            }
        });
        builder.addCase(executeTestCase.pending, (state) => {
            state.shouldExecTestCase = false;
            state.isActionWaiting = true;
            state.lastActionDate = new Date();
        });
        builder.addCase(executeTestCase.rejected, (state) => {
            state.isActionWaiting = false;
        });
        builder.addCase(executeTestCase.fulfilled, (state) => {
            state.isActionWaiting = false;
            state.lastActionDate = new Date();
        });
        builder.addCase(fetchTestCase.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTestCase.fulfilled, (state, action) => {
            state.testCase = action.payload;
            state.isLoading = false;
            state.testCaseName = action.payload.data.name;
        });
        builder.addCase(createTapActionEvent.pending, (state, action) => {
            state.isActionWaiting = true;
            state.lastActionDate = new Date();
        });
        builder.addCase(createTapActionEvent.fulfilled, (state, action) => {
            const { actionEvent, nextElement } = action.payload;
            state.lastActionDate = new Date();

            if (!actionEvent.data.toX || !actionEvent.data.toY) {
                return;
            }
            state.tapPoint = {
                x: actionEvent.data.toX,
                y: actionEvent.data.toY,
            };
            state.actions.push(actionEvent);
            state.isActionWaiting = false;
            state.nextElement = nextElement;
        });
        builder.addCase(createTapActionEvent.rejected, (state, acation) => {
            state.isActionWaiting = false;
        });
        builder.addCase(createSendKeyActionEvent.pending, (state, action) => {
            state.isActionWaiting = true;
            state.lastActionDate = new Date();
        });
        builder.addCase(createSendKeyActionEvent.fulfilled, (state, action) => {
            const { actionEvent, nextElement } = action.payload;
            state.lastActionDate = new Date();

            state.text = "";
            state.actions.push(actionEvent);
            state.nextElement = nextElement;
            state.isActionWaiting = false;
        });
        builder.addCase(createSendKeyActionEvent.rejected, (state, action) => {
            state.isActionWaiting = false;
        });
        builder.addCase(createSwipeActionEvent.pending, (state) => {
            state.lastActionDate = new Date();
            state.isActionWaiting = true;
            state.isSwiping = false;
            state.fromPoint = null;
            state.toPoint = null;
            state.swipeDirection = null;
        });
        builder.addCase(createSwipeActionEvent.fulfilled, (state, action) => {
            state.lastActionDate = new Date();
            const { actionEvent, nextElement } = action.payload;
            state.actions.push(actionEvent);
            state.nextElement = nextElement;
            state.isActionWaiting = false;
        });
        builder.addCase(createSwipeActionEvent.rejected, (state) => {
            state.isActionWaiting = false;
        });
        builder.addCase(createOpenURLActionEvent.pending, (state, action) => {
            state.isActionWaiting = true;
            state.lastActionDate = new Date();
        });
        builder.addCase(createOpenURLActionEvent.fulfilled, (state, action) => {
            const { actionEvent, nextElement } = action.payload;

            state.lastActionDate = new Date();
            state.text = "";
            state.actions.push(actionEvent);
            state.nextElement = nextElement;
            state.isActionWaiting = false;
        });
        builder.addCase(createOpenURLActionEvent.rejected, (state, action) => {
            state.isActionWaiting = false;
        });
        builder.addCase(createShakeActionEvent.pending, (state, action) => {
            state.isActionWaiting = true;
            state.lastActionDate = new Date();
        });
        builder.addCase(createShakeActionEvent.fulfilled, (state, action) => {
            const { actionEvent, nextElement } = action.payload;

            state.lastActionDate = new Date();
            state.actions.push(actionEvent);
            state.nextElement = nextElement;
            state.isActionWaiting = false;
        });
        builder.addCase(createShakeActionEvent.rejected, (state, action) => {
            state.isActionWaiting = false;
        });
        builder.addCase(endTestCase.pending, (state) => {
            state.lastActionDate = new Date();
            state.isLoading = true;
        });
        builder.addCase(endTestCase.fulfilled, (state) => {
            state.lastActionDate = new Date();
            state.isLoading = false;
            state.shouldLeave = true;
        });
        builder.addCase(endTestCase.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteSession.fulfilled, (state) => {
            state.shouldLeave = true;
        });
        builder.addCase(deleteActionEvent.pending, (state, action) => {
            const deletedActionEventID = action.meta.arg.actionEventID;
            state.actions = state.actions.filter(
                (actionEvent) => actionEvent.data.id !== deletedActionEventID
            );
        });
    },
});

export const {
    tap,
    updateText,
    updateSwiping,
    updateTestCaseName,
    refreshStreamingURL,
    clearRecordState,
} = recorder.actions;

export default recorder;
