import { useSelector } from "react-redux";
import { ProjectState } from "../project/slice";
import { IntegrationsState } from "./slice";

export const useIntegrationsState = () => {
    return useSelector(
        (state: { integrations: IntegrationsState; project: ProjectState }) =>
            state
    );
};
