import {
    Box,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Typography,
} from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React from "react";
import { ActionResult } from "../../../models/actionResult";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import { IOSNode } from "../../../models/iOSNode";
import { AssertionResult } from "../../../models/assertionResult";
import { Device } from "../../../models/device";

interface ActionResultModalProps {
    actionResult: ActionResult;
    device: Device;
    isOpen: boolean;
    allAssertionResults: AssertionResult[];
    onClose: () => void;
    onMoveToRight: () => void;
    onMoveToLeft: () => void;
}

const WIDTH = 280;

export const ActionResultModal = (props: ActionResultModalProps) => {
    const {
        actionResult,
        device,
        isOpen,
        allAssertionResults,
        onClose,
        onMoveToRight,
        onMoveToLeft,
    } = props;

    const [
        hoveredOriginalIOSNode,
        setHoveredOriginalIOSNode,
    ] = React.useState<IOSNode | null>(null);
    const [
        hoveredResultIOSNode,
        setHoveredResultIOSNode,
    ] = React.useState<IOSNode | null>(null);

    const scorePercent =
        actionResult.data.score !== undefined
            ? Math.round(actionResult.data.score * 100)
            : null;

    const assertions = allAssertionResults.filter(
        (assertion) =>
            assertion.data.actionEventID === actionResult.data.actionEventID
    );

    const scale = WIDTH / device.getRect().width;

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
        >
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="space-around"
                direction="column"
                spacing={8}
            >
                <Grid container justify="flex-end">
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <Close htmlColor={grey[500]} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-around"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        justify="space-around"
                        xs={3}
                    >
                        <Grid item style={{ textAlign: "center" }}>
                            <Typography variant="body2">作成時点</Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                style={{
                                    backgroundSize: "contain",
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                                width={WIDTH}
                            >
                                <img
                                    src={
                                        actionResult.data.originalScreenshotURL
                                    }
                                    width={WIDTH}
                                    style={{ position: "relative" }}
                                />
                                {hoveredOriginalIOSNode && (
                                    <Paper
                                        square
                                        style={{
                                            position: "absolute",
                                            left:
                                                hoveredOriginalIOSNode.data.x *
                                                scale,
                                            top:
                                                hoveredOriginalIOSNode.data.y *
                                                scale,
                                            width:
                                                hoveredOriginalIOSNode.data
                                                    .width * scale,
                                            height:
                                                hoveredOriginalIOSNode.data
                                                    .height * scale,
                                            backgroundColor:
                                                green["A400"] + "33",
                                            border: `4px solid ${
                                                green["A400"] + "7F"
                                            }`,
                                        }}
                                        elevation={0}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        justify="space-around"
                        xs={3}
                    >
                        <Grid item style={{ textAlign: "center" }}>
                            <Typography variant="body2">今回</Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                style={{
                                    backgroundSize: "contain",
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                                width={WIDTH}
                            >
                                <img
                                    src={actionResult.data.screenshotURL}
                                    width={WIDTH}
                                    style={{ position: "relative" }}
                                />
                                {hoveredResultIOSNode && (
                                    <Paper
                                        square
                                        style={{
                                            position: "absolute",
                                            left:
                                                hoveredResultIOSNode.data.x *
                                                scale,
                                            top:
                                                hoveredResultIOSNode.data.y *
                                                scale,
                                            width:
                                                hoveredResultIOSNode.data
                                                    .width * scale,
                                            height:
                                                hoveredResultIOSNode.data
                                                    .height * scale,
                                            backgroundColor:
                                                green["A400"] + "33",
                                            border: `4px solid ${
                                                green["A400"] + "7F"
                                            }`,
                                        }}
                                        elevation={0}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={5} spacing={2}>
                        {assertions.map((assertion) => (
                            <Grid
                                item
                                container
                                justify="space-around"
                                alignItems="center"
                                component={Paper}
                                style={{ margin: 8 }}
                                xs={10}
                                onMouseEnter={() => {
                                    if (assertion.data.iOSNode) {
                                        const iOSNode = new IOSNode(
                                            assertion.data.iOSNode
                                        );
                                        setHoveredOriginalIOSNode(iOSNode);
                                    } else {
                                        setHoveredOriginalIOSNode(null);
                                    }
                                    if (assertion.data.resultIOSNode) {
                                        const iOSNode = new IOSNode(
                                            assertion.data.resultIOSNode
                                        );
                                        setHoveredResultIOSNode(iOSNode);
                                    } else {
                                        setHoveredResultIOSNode(null);
                                    }
                                }}
                            >
                                <Grid
                                    item
                                    alignItems="center"
                                    justify="center"
                                    xs={1}
                                >
                                    {assertion.data.isPassed ? (
                                        <CheckCircle
                                            style={{ display: "block" }}
                                            htmlColor={green["A400"]}
                                        />
                                    ) : (
                                        <Error
                                            style={{ display: "block" }}
                                            htmlColor={red["A400"]}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    style={{ overflow: "scroll" }}
                                    xs={10}
                                >
                                    <Typography>
                                        {assertion.getTitle()}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        noWrap
                                        color="textSecondary"
                                    >
                                        {assertion.data.criteria ===
                                        "SIMILARITY_ABOVE"
                                            ? `${scorePercent}%`
                                            : assertion.data.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-around"
                    direction="column"
                >
                    {actionResult.data.reason && (
                        <Grid item>
                            <Typography variant="body2" color="error">
                                {actionResult.getReasonDescription()}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item container wrap="nowrap" justify="center">
                        <IconButton onClick={onMoveToLeft}>
                            <ArrowBack />
                        </IconButton>
                        <IconButton onClick={onMoveToRight}>
                            <ArrowForward />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            「←」または「→」キーを押すことでも遷移できます
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
