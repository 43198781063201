import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import firebase, { storage } from "firebase";
import { getFirebase } from "react-redux-firebase";
import { IActionEvent } from "../../../../entities/src/actionEvent";
import {
    AssertionCriteria,
    IAssertion,
} from "../../../../entities/src/assertion";
import { DeviceName } from "../../../../entities/src/device";
import { ITestCase } from "../../../../entities/src/testCase";
import { ActionEvent } from "../../models/actionEvent";
import { Assertion } from "../../models/assertion";
import { IOSNode } from "../../models/iOSNode";
import { TestCase } from "../../models/testCase";
import { fetchSignedURL } from "../../utilities/fetchSignedURL";
import { getElements } from "../../utilities/getElements";
import { parseDocument, parseDocuments } from "../../utilities/parseDocument";

export const fetchMyTestCases = createAsyncThunk<
    TestCase[],
    { projectID: string }
>("home/fetchMyTestCases", async (args, thunk) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const { projectID } = args;
    const testCaseDocs = await firestore
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .where("isSaved", "==", true)
        .where("deletedAt", "==", null)
        .orderBy("createdAt", "desc")
        .get();

    const testCases = parseDocuments<ITestCase>(testCaseDocs).map(
        (testCaseData) => new TestCase(testCaseData)
    );

    return testCases;
});

export const fetchActionEvents = createAsyncThunk<
    { testCaseID: string; actions: ActionEvent[] },
    { projectID: string; testCaseID: string }
>("home/fetchActionEvents", async (args, thunk) => {
    const { projectID, testCaseID } = args;
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const actionEventDocs = await firestore
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("actionEvent")
        .orderBy("createdAt")
        .get();

    const actionEvents = parseDocuments<IActionEvent>(actionEventDocs).map(
        (doc) => new ActionEvent(doc)
    );

    return { testCaseID: testCaseID, actions: actionEvents };
});

export const fetchTreeXML = createAsyncThunk<IOSNode[], string>(
    "home/fetchTreeXML",
    async (treeURL) => {
        const response = await Axios.get(treeURL);
        const treeXML = response.data;
        const iOSNodes = getElements(treeXML);

        // (x: 0, y: 0)の要素がめっちゃ多くて不必要なものしかない気がするから除いてる
        return iOSNodes
            .filter((node) => node.data.x > 0 || node.data.y > 0)
            .filter(
                (node) =>
                    !node.data.name ||
                    (!node.data.name.includes("スクロールバー") &&
                        !node.data.name.includes("scroll bar"))
            );
    }
);

export const fetchAssertions = createAsyncThunk<
    { testCaseID: string; assertions: Assertion[] },
    { projectID: string; testCaseID: string }
>("home/fetchAssertions", async (args, thunk) => {
    const { projectID, testCaseID } = args;
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const assertionDocs = await firestore
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .where("deletedAt", "==", null)
        .orderBy("createdAt")
        .get();

    const assertions = parseDocuments<IAssertion>(assertionDocs).map(
        (doc) => new Assertion(doc)
    );

    return { testCaseID: testCaseID, assertions: assertions };
});

export const createTestCase = createAsyncThunk<
    TestCase,
    {
        projectID: string;
        name: string;
        deviceName: string;
        dependedTestCaseID?: string;
    }
>(
    "home/createTestCase",
    async (args, thunk): Promise<TestCase> => {
        const { projectID, name, deviceName, dependedTestCaseID } = args;
        const testCase = new TestCase({
            id: "",
            device: {
                name: deviceName as DeviceName,
                os: "iOS",
                language: "ja",
            },
            name: name,
            projectID: projectID,
            createdAt: new Date(),
            updatedAt: new Date(),
            deletedAt: null,
            isSaved: false,
            dependedTestCaseID: dependedTestCaseID,
        });

        const testCaseDoc = await firebase
            .firestore()
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .add(testCase.data);

        testCase.data.id = testCaseDoc.id;

        return testCase;
    }
);

export const duplicateTestCase = createAsyncThunk<
    TestCase,
    {
        projectID: string;
        testCaseID: string;
    }
>(
    "home/duplicateTestCase",
    async (args, thunk): Promise<TestCase> => {
        const { projectID, testCaseID } = args;
        const firestore = firebase.firestore();

        const testCaseDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(testCaseID)
            .get();
        const testCase = new TestCase(parseDocument<ITestCase>(testCaseDoc));
        testCase.data.originalTestCaseID = testCaseID;
        testCase.data.createdAt = new Date();
        testCase.data.updatedAt = new Date();
        testCase.data.isSaved = true;
        testCase.data.deletedAt = null;
        testCase.data.id = "";

        const duplicatedTestCaseDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .add(testCase.data);

        testCase.data.id = duplicatedTestCaseDoc.id;

        const actionEventsDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(testCaseID)
            .collection("actionEvent")
            .orderBy("createdAt", "asc")
            .get();
        const actionEvents = parseDocuments<IActionEvent>(actionEventsDoc).map(
            (data) => new ActionEvent(data)
        );

        const assertionsDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(testCaseID)
            .collection("assertion")
            .get();
        const assertions = parseDocuments<IAssertion>(assertionsDoc).map(
            (data) => new Assertion(data)
        );
        await Promise.all(
            actionEvents.map(async (actionEvent) => {
                await firestore
                    .collection("project")
                    .doc(projectID)
                    .collection("testCase")
                    .doc(duplicatedTestCaseDoc.id)
                    .collection("actionEvent")
                    .doc(actionEvent.data.id)
                    .set(actionEvent.data);
                await Promise.all(
                    assertions
                        .filter(
                            (assertion) =>
                                assertion.data.actionEventID ===
                                actionEvent.data.id
                        )
                        .map(async (assertion) => {
                            await firestore
                                .collection("project")
                                .doc(projectID)
                                .collection("testCase")
                                .doc(duplicatedTestCaseDoc.id)
                                .collection("assertion")
                                .doc(assertion.data.id)
                                .set(assertion.data);
                        })
                );
            })
        );
        return testCase;
    }
);

export const editTestCase = createAsyncThunk<
    TestCase,
    {
        projectID: string;
        testCaseID: string;
        lastActionEvent?: ActionEvent;
    }
>(
    "home/editTestCase",
    async (args, thunk): Promise<TestCase> => {
        const { projectID, testCaseID, lastActionEvent } = args;
        const firestore = firebase.firestore();

        const testCaseDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .doc(testCaseID)
            .get();
        const testCase = new TestCase(parseDocument<ITestCase>(testCaseDoc));
        testCase.data.originalTestCaseID = testCaseID;
        testCase.data.createdAt = new Date();
        testCase.data.updatedAt = new Date();
        testCase.data.isSaved = false;
        testCase.data.deletedAt = null;
        testCase.data.id = "";

        const duplicatedTestCaseDoc = await firestore
            .collection("project")
            .doc(projectID)
            .collection("testCase")
            .add(testCase.data);

        testCase.data.id = duplicatedTestCaseDoc.id;

        if (lastActionEvent && lastActionEvent.data.createdAt) {
            const actionEventsDoc = await firestore
                .collection("project")
                .doc(projectID)
                .collection("testCase")
                .doc(testCaseID)
                .collection("actionEvent")
                .orderBy("createdAt", "asc")
                .endBefore(lastActionEvent.data.createdAt)
                .get();
            const actionEvents = parseDocuments<IActionEvent>(
                actionEventsDoc
            ).map((data) => new ActionEvent(data));

            const assertionsDoc = await firestore
                .collection("project")
                .doc(projectID)
                .collection("testCase")
                .doc(testCaseID)
                .collection("assertion")
                .get();
            const assertions = parseDocuments<IAssertion>(assertionsDoc).map(
                (data) => new Assertion(data)
            );
            await Promise.all(
                actionEvents.map(async (actionEvent) => {
                    await firestore
                        .collection("project")
                        .doc(projectID)
                        .collection("testCase")
                        .doc(duplicatedTestCaseDoc.id)
                        .collection("actionEvent")
                        .doc(actionEvent.data.id)
                        .set(actionEvent.data);
                    await Promise.all(
                        assertions
                            .filter(
                                (assertion) =>
                                    assertion.data.actionEventID ===
                                    actionEvent.data.id
                            )
                            .map(async (assertion) => {
                                await firestore
                                    .collection("project")
                                    .doc(projectID)
                                    .collection("testCase")
                                    .doc(duplicatedTestCaseDoc.id)
                                    .collection("assertion")
                                    .doc(assertion.data.id)
                                    .set(assertion.data);
                            })
                    );
                })
            );
        }

        return testCase;
    }
);

export const deleteTestCase = createAsyncThunk<
    string,
    { projectID: string; testCaseID: string }
>("home/deleteTestCase", async (args) => {
    const { projectID, testCaseID } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .update({ deletedAt: new Date() });

    return testCaseID;
});

export const renameTestCase = createAsyncThunk<
    { testCaseID: string; afterTestName: string },
    { projectID: string; testCaseID: string; afterTestName: string }
>("home/renameTestCase", async (args) => {
    const { projectID, testCaseID, afterTestName } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .update({ name: afterTestName });

    return { testCaseID: testCaseID, afterTestName: afterTestName };
});

export const updateActionEventText = createAsyncThunk<
    void,
    {
        projectID: string;
        actionEventID: string;
        testCaseID: string;
        text: string;
    }
>("home/updateActionEventText", async (args) => {
    const { projectID, actionEventID, testCaseID, text } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("actionEvent")
        .doc(actionEventID)
        .update({
            text,
            updatedAt: new Date(),
            variableID: firebase.firestore.FieldValue.delete(),
        } as Pick<IActionEvent, "text" | "updatedAt">);
});

export const updateActionEventVariable = createAsyncThunk<
    void,
    {
        projectID: string;
        actionEventID: string;
        testCaseID: string;
        variableID: string;
    }
>("home/updateActionEventVariable", async (args) => {
    const { projectID, actionEventID, testCaseID, variableID } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("actionEvent")
        .doc(actionEventID)
        .update({
            variableID,
            updatedAt: new Date(),
            text: firebase.firestore.FieldValue.delete(),
        } as Pick<IActionEvent, "variableID" | "updatedAt">);
});

export const updateTapElement = createAsyncThunk<
    void,
    {
        projectID: string;
        actionEventID: string;
        testCaseID: string;
        iOSNode: IOSNode;
    }
>("home/updateTapElement", async (args) => {
    const { projectID, actionEventID, testCaseID, iOSNode } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("actionEvent")
        .doc(actionEventID)
        .update({ iOSNode: iOSNode.data, updatedAt: new Date() } as Pick<
            IActionEvent,
            "iOSNode" | "updatedAt"
        >);
});

export const updateSimilarityAssertionValue = createAsyncThunk<
    { assertionID: string; testCaseID: string; value: number },
    {
        projectID: string;
        assertionID: string;
        testCaseID: string;
        value: number;
    }
>("home/updateSimilarityAssertionValue", async (args) => {
    const { projectID, assertionID, testCaseID, value } = args;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .doc(assertionID)
        .update({ value: value, updatedAt: new Date() } as Pick<
            IAssertion,
            "value" | "updatedAt"
        >);

    return args;
});

export const createAssertion = createAsyncThunk<
    { assertion: Assertion; testCaseID: string },
    {
        actionEvent: ActionEvent;
        assertionCriteria: AssertionCriteria;
        iOSNode: IOSNode;
        value?: number;
        text?: string;
        projectID: string;
        testCaseID: string;
    }
>("home/createAssertion", async (args) => {
    const {
        actionEvent,
        assertionCriteria,
        iOSNode,
        value,
        text,
        projectID,
        testCaseID,
    } = args;

    if (!actionEvent.data.screenshotURL || !actionEvent.data.treeURL) {
        throw new Error("ActionEvent does not have screenshotURL or treeURL.");
    }

    const assertion = new Assertion({
        id: "",
        criteria: assertionCriteria,
        expectedTreeURL: actionEvent.data.treeURL,
        expectedScreenshotURL: actionEvent.data.screenshotURL,
        iOSNode: iOSNode.data,
        actionEventID: actionEvent.data.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
    });

    if (assertionCriteria === "SIMILARITY_ABOVE") {
        assertion.data.value = value;
    }
    if (assertionCriteria.startsWith("TEXT")) {
        assertion.data.text = text;
    }

    const assertionDoc = firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .doc();

    assertion.data.id = assertionDoc.id;

    await assertionDoc.set(assertion.data);

    return { assertion: assertion, testCaseID: testCaseID };
});

export const updateAssertion = createAsyncThunk<
    { assertion: Assertion; testCaseID: string },
    {
        assertion: Assertion;
        assertionCriteria: AssertionCriteria;
        iOSNode: IOSNode;
        value?: number;
        text?: string;
        projectID: string;
        testCaseID: string;
    }
>("home/updateAssertion", async (args) => {
    const {
        assertion,
        assertionCriteria,
        iOSNode,
        value,
        text,
        projectID,
        testCaseID,
    } = args;

    const newData = {
        criteria: assertionCriteria,
        iOSNode: iOSNode.data,
        value: value,
        text: text,
        updatedAt: new Date(),
    } as Pick<
        IAssertion,
        "criteria" | "iOSNode" | "value" | "text" | "updatedAt"
    >;

    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .doc(assertion.data.id)
        .update(newData)
        .catch((error) => console.error(error));

    const updatedAssertion = new Assertion({
        ...assertion.data,
        ...newData,
    });

    return { assertion: updatedAssertion, testCaseID: testCaseID };
});

export const deleteAssertion = createAsyncThunk<
    { deletedAssertionID: string; testCaseID: string },
    { projectID: string; testCaseID: string; assertionID: string }
>("home/deleteAssertion", async (args) => {
    const { projectID, testCaseID, assertionID } = args;
    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("testCase")
        .doc(testCaseID)
        .collection("assertion")
        .doc(assertionID)
        .update({ deletedAt: new Date() } as Pick<IAssertion, "deletedAt">);
    return { deletedAssertionID: assertionID, testCaseID: testCaseID };
});
