import { IActionEvent } from "../../../entities/src/actionEvent";
import { Modelable } from "../../../entities/src/modelable";
import { Variable } from "./variable";

export class ActionEvent implements Modelable<IActionEvent> {
    constructor(public data: IActionEvent) {}

    getActionName = (): string => {
        switch (this.data.action) {
            case "CLICK":
                return "タップ";
            case "INPUT_TEXT":
                return "テキスト";
            case "SWIPE":
                return "スワイプ";
            case "SELECT_PICKER":
                return "ピッカー";
            case "OPEN_URL":
                return "リンクを開く";
            case "SHAKE":
                return "シェイク";
            case "END":
                return "終了";
        }
    };

    getDescription = (variables: Variable[]): string | null => {
        switch (this.data.action) {
            case "CLICK":
                if (this.data.iOSNode) {
                    if (this.data.iOSNode.name) {
                        return this.data.iOSNode.name;
                    }
                    return this.data.iOSNode.type;
                }
                return `(${this.data.toX}, ${this.data.toY})`;
            case "INPUT_TEXT":
                if (this.data.variableID) {
                    const variable = variables.filter(
                        (variable) => variable.data.id === this.data.variableID
                    )[0];
                    if (variable) {
                        return `$${variable.data.name}`;
                    } else {
                        return "変数が見つかりませんでした";
                    }
                }
                if (
                    this.data.activeIOSNode?.type ===
                    "XCUIElementTypeSecureTextField"
                ) {
                    const textLength = (this.data.text ?? "").length;
                    return "*".repeat(textLength);
                }
                return this.data.text ?? null;
            case "SWIPE":
                return this.data.direction ?? null;
            case "SELECT_PICKER":
                return this.data.text ?? null;
            case "OPEN_URL":
                return this.data.url ?? null;
            case "SHAKE":
                return null;
            case "END":
                return null;
        }
    };
}
