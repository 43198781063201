import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppInfo } from "../../models/appInfo";
import {
    createAppInfo,
    deleteAppInfo,
    fetchMyAppInfos,
    uploadApp,
} from "./effects";

export type BuildsState = {
    appInfos: AppInfo[];
    uploadedAppName: string | null;
    uploadedAppURL: string | null;
    isConfirmDeleteModelOpen: boolean;
    isUploading: boolean;
};

export const initialState: BuildsState = {
    appInfos: [],
    uploadedAppName: null,
    uploadedAppURL: null,
    isConfirmDeleteModelOpen: false,
    isUploading: false,
};

const builds = createSlice({
    name: "builds",
    initialState,
    reducers: {
        updateApp: (state, action: PayloadAction<File>) => {
            state.uploadedAppName = action.payload.name;
        },
        openConfirmDeleteModal: (state) => {
            state.isConfirmDeleteModelOpen = true;
        },
        dismissConfirmDeleteModal: (state) => {
            state.isConfirmDeleteModelOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyAppInfos.fulfilled, (state, action) => {
            state.appInfos = action.payload;
        });
        builder.addCase(uploadApp.pending, (state) => {
            state.isUploading = true;
        });
        builder.addCase(uploadApp.rejected, (state) => {
            state.isUploading = false;
        });
        builder.addCase(uploadApp.fulfilled, (state, action) => {
            state.uploadedAppURL = action.payload;
            state.isUploading = false;
        });
        builder.addCase(deleteAppInfo.fulfilled, (state, action) => {
            const deletedAppInfoID = action.payload;
            state.appInfos = state.appInfos.filter(
                (appInfo) => appInfo.data.id !== deletedAppInfoID
            );
        });
        builder.addCase(createAppInfo.fulfilled, (state, action) => {
            const addedAppInfo = action.payload;
            state.appInfos = [addedAppInfo].concat(state.appInfos);
            state.uploadedAppName = null;
            state.uploadedAppURL = null;
        });
    },
});

export const {
    updateApp,
    openConfirmDeleteModal,
    dismissConfirmDeleteModal,
} = builds.actions;

export default builds;
