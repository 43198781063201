import React, { useEffect } from "react";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { useProjectState } from "../../ducks/project/selectors";
import { useDispatch } from "react-redux";
import { fetchProjectMembers } from "../../ducks/project/effects";
import { LoadingView } from "../../components/molecules/loadingView";

export const Member = () => {
    const state = useProjectState().project;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!state.selectedProject) {
            return;
        }

        const projectID = state.selectedProject.data.id;
        dispatch(fetchProjectMembers(projectID));
    }, []);

    if (
        !state.selectedProject ||
        !state.members[state.selectedProject.data.id]
    ) {
        return <LoadingView width={256} height={256} />;
    }

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                alignItems="center"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    justify="space-around"
                    xs={10}
                    spacing={4}
                >
                    <Grid item>
                        <Typography variant="h6">メンバー</Typography>
                    </Grid>
                    {state.members[state.selectedProject.data.id]?.map(
                        (member) => (
                            <Grid
                                key={member.email}
                                item
                                container
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item>
                                    <Typography variant="body2">
                                        {member.email}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">
                                        Admin
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};
