import {
    Badge,
    Box,
    IconButton,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React from "react";
import { useProjectState } from "../../ducks/project/selectors";
import { ActionEvent } from "../../models/actionEvent";
import { ActionResult } from "../../models/actionResult";
import { Assertion } from "../../models/assertion";
import { AssertionResult } from "../../models/assertionResult";
import { Device } from "../../models/device";
import { TestCase } from "../../models/testCase";

interface ActionViewProps {
    testCase?: TestCase;
    actionEvent: ActionEvent;
    actionResult?: ActionResult;
    assertions?: Assertion[];
    assertionResults?: AssertionResult[];
    device: Device;
    screenshotWidth: number;
    onClick?: () => void;
    onClickWithInfo?: (testCaseID: string, actionEventID: string) => void;
    canDelete: boolean;
    onDelete?: () => void;
}

const TAP_IMAGE_URL =
    "https://firebasestorage.googleapis.com/v0/b/e2e-test-dev.appspot.com/o/assets%2FCircle-24.png?alt=media&token=6db2b5d7-e10c-43f4-808c-925b27ecc3a6"; // タップを表すサークルの画像URL
const TAP_IMAGE_WIDTH = 24;
const LARGE_TAP_IMAGE_URL =
    "https://firebasestorage.googleapis.com/v0/b/e2e-test-dev.appspot.com/o/assets%2FCircle.png?alt=media&token=488e32b3-3111-4a18-a463-1f6604b76a74";
const LARGE_TAP_IMAGE_WIDTH = 46;

export const ActionView = (props: ActionViewProps) => {
    const {
        testCase,
        actionEvent,
        actionResult,
        device,
        screenshotWidth,
        onClick,
        onClickWithInfo,
        canDelete,
        onDelete,
    } = props;

    const projectState = useProjectState().project;

    const deviceRect = device.getRect();
    const deviceWidth = deviceRect.width;
    const deviceHeight = deviceRect.height;

    const isLarge = screenshotWidth > 240;
    const tapImageWidth = isLarge ? LARGE_TAP_IMAGE_WIDTH : TAP_IMAGE_WIDTH;
    const tapImageURL = isLarge ? LARGE_TAP_IMAGE_URL : TAP_IMAGE_URL;

    let assertionsDict: { [actionEventID: string]: Assertion[] } = {};

    props.assertions?.forEach((assertion) => {
        if (!assertionsDict[assertion.data.actionEventID]) {
            assertionsDict[assertion.data.actionEventID] = [];
        }
        assertionsDict[assertion.data.actionEventID].push(assertion);
    });

    let diffThreshold: number | null = null;

    const assertions = assertionsDict[actionEvent.data.id];

    if (assertions && assertions.length > 0) {
        const assertion = assertions
            .filter(
                (assertion) => assertion.data.criteria === "SIMILARITY_ABOVE"
            )
            .slice(-1)[0];
        if (assertion && assertion.data.value !== undefined) {
            diffThreshold = assertion.data.value * 100;
        }
    }

    if (actionResult) {
        diffThreshold = null;
    }

    const assertionResults = props.assertionResults?.filter(
        (result) => result.data.actionResultID === actionResult?.data.id
    );

    const isError =
        assertionResults?.some((result) => !result.data.isPassed) ||
        actionResult?.data.reason;

    const badgeContent: string =
        (isError && "!") ??
        (assertions && assertions.length.toString()) ??
        (assertionResults && assertionResults.length.toString()) ??
        "1";

    let screenshotURL =
        actionEvent.data.screenshotURL ??
        actionResult?.data.screenshotURL ??
        "";

    // TODO: リサイズ後の画像が存在するのが4/27 20:00移行なのでここで分岐しています
    if (
        actionEvent.data.createdAt &&
        actionEvent.data.createdAt > new Date(2021, 3, 27, 20)
    ) {
        if (screenshotWidth <= 128) {
            screenshotURL = screenshotURL.replace(".png", "_128x512.png");
        } else if (screenshotWidth <= 256) {
            screenshotURL = screenshotURL.replace(".png", "_256x1024.png");
        } else if (screenshotWidth <= 512) {
            screenshotURL = screenshotURL.replace(".png", "_512x2048.png");
        }
    }

    return (
        <ListItem
            divider={true}
            key={actionEvent.data.id}
            style={{
                display: "flex",
                flexDirection: "column",
                width: screenshotWidth + 32,
                cursor: canDelete ? "default" : "pointer",
            }}
            onClick={() => {
                if (onClick) {
                    onClick();
                    return;
                }

                if (!onClickWithInfo || !testCase) {
                    return;
                }
                onClickWithInfo(actionEvent.data.id, testCase.data.id);
            }}
        >
            <ListItemText
                primary={actionEvent.getActionName()}
                secondary={actionEvent.getDescription(projectState.variables)}
                primaryTypographyProps={{ variant: "body2" }}
                secondaryTypographyProps={{
                    variant: "caption",
                    noWrap: true,
                }}
                style={{ textAlign: "center", width: "100%" }}
            />
            {diffThreshold !== null && (
                <Typography variant="caption">{`${diffThreshold}%`}</Typography>
            )}
            <Box>
                {!canDelete && (
                    <Badge
                        badgeContent={
                            <Typography variant="caption">
                                {badgeContent}
                            </Typography>
                        }
                        color={isError ? "secondary" : "primary"}
                        style={{ position: "absolute", right: 16 }}
                    />
                )}
                <Box
                    style={{
                        width: screenshotWidth,
                        height: (screenshotWidth * deviceHeight) / deviceWidth,
                        backgroundImage: `url("${screenshotURL}")`,
                        backgroundSize: "contain",
                        position: "relative",
                    }}
                >
                    {canDelete && (
                        <IconButton
                            color="primary"
                            style={{
                                position: "absolute",
                                right: -16,
                                top: -16,
                                backgroundColor: grey["400"],
                            }}
                            size="small"
                            onClick={onDelete}
                        >
                            <Close fontSize="small" htmlColor="#ffffff" />
                        </IconButton>
                    )}
                    {actionEvent.data.activeIOSNode && (
                        <Paper
                            variant="outlined"
                            style={{
                                position: "absolute",
                                left:
                                    (actionEvent.data.activeIOSNode.x *
                                        screenshotWidth) /
                                    deviceWidth,
                                top:
                                    (actionEvent.data.activeIOSNode.y *
                                        screenshotWidth) /
                                    deviceWidth,
                                width:
                                    (actionEvent.data.activeIOSNode.width *
                                        screenshotWidth) /
                                    deviceWidth,
                                height:
                                    (actionEvent.data.activeIOSNode.height *
                                        screenshotWidth) /
                                    deviceWidth,
                                backgroundColor: "rgba(255, 82, 82, 0.2)",
                                border: "4px solid rgba(255, 82, 82, 0.5)",
                            }}
                            square
                        />
                    )}
                    {actionEvent.data.iOSNode && (
                        <Paper
                            variant="outlined"
                            style={{
                                position: "absolute",
                                left:
                                    (actionEvent.data.iOSNode.x *
                                        screenshotWidth) /
                                    deviceWidth,
                                top:
                                    (actionEvent.data.iOSNode.y *
                                        screenshotWidth) /
                                    deviceWidth,
                                width:
                                    (actionEvent.data.iOSNode.width *
                                        screenshotWidth) /
                                    deviceWidth,
                                height:
                                    (actionEvent.data.iOSNode.height *
                                        screenshotWidth) /
                                    deviceWidth,
                                backgroundColor: "rgba(82, 82, 255, 0.2)",
                                border: "4px solid rgba(82, 82, 255, 0.5)",
                            }}
                            square
                        />
                    )}
                    {actionEvent.data.toX !== undefined &&
                        actionEvent.data.toY !== undefined && (
                            <Box
                                style={{
                                    position: "absolute",
                                    left:
                                        (actionEvent.data.toX *
                                            screenshotWidth) /
                                            deviceWidth -
                                        tapImageWidth / 2,
                                    top:
                                        (actionEvent.data.toY *
                                            screenshotWidth) /
                                            deviceWidth -
                                        tapImageWidth / 2,
                                    width: tapImageWidth,
                                    height: tapImageWidth,
                                    backgroundImage: `url("${tapImageURL}")`,
                                }}
                            />
                        )}
                    {actionEvent.data.fromX !== undefined &&
                        actionEvent.data.fromY !== undefined && (
                            <Box
                                style={{
                                    position: "absolute",
                                    left:
                                        (actionEvent.data.fromX *
                                            screenshotWidth) /
                                            deviceWidth -
                                        tapImageWidth / 2,
                                    top:
                                        (actionEvent.data.fromY *
                                            screenshotWidth) /
                                            deviceWidth -
                                        tapImageWidth / 2,
                                    width: tapImageWidth,
                                    height: tapImageWidth,
                                    backgroundImage: `url("${tapImageURL}")`,
                                }}
                            />
                        )}
                </Box>
            </Box>
        </ListItem>
    );
};

interface TappedElementProps {
    actionEvent: ActionEvent;
    actionResult?: ActionResult;
    screenshotWidth: number;
    deviceWidth: number;
}

const TappedElement = (props: TappedElementProps) => {
    const { actionEvent, actionResult, screenshotWidth, deviceWidth } = props;
    if (actionResult && actionResult.data.iOSNode)
        if (actionEvent.data.iOSNode) {
            return (
                <Paper
                    variant="outlined"
                    style={{
                        position: "absolute",
                        left:
                            (actionEvent.data.iOSNode.x * screenshotWidth) /
                            deviceWidth,
                        top:
                            (actionEvent.data.iOSNode.y * screenshotWidth) /
                            deviceWidth,
                        width:
                            (actionEvent.data.iOSNode.width * screenshotWidth) /
                            deviceWidth,
                        height:
                            (actionEvent.data.iOSNode.height *
                                screenshotWidth) /
                            deviceWidth,
                        backgroundColor: "rgba(82, 82, 255, 0.2)",
                        border: "4px solid rgba(82, 82, 255, 0.5)",
                    }}
                    square
                />
            );
        }
};
