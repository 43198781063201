import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import React from "react";
import { Assertion } from "../../models/assertion";

interface AssertionListProps {
    assertion: Assertion;
    onClickMoreButton?: (event: HTMLElement) => void;
    onHover?: (assertion: Assertion) => void;
}

export const AssertionList = (props: AssertionListProps) => {
    const { assertion, onClickMoreButton, onHover } = props;
    return (
        <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="space-around"
            style={{ margin: 8 }}
            xs={10}
            component={Paper}
            key={assertion.data.id}
            wrap="nowrap"
            onMouseEnter={() => {
                if (!onHover) {
                    return;
                }
                onHover(assertion);
            }}
        >
            <Grid item xs={9} style={{ overflow: "scroll" }}>
                <Typography>{assertion.getTitle()}</Typography>
                <Typography variant="caption" noWrap color="textSecondary">
                    {assertion.data.text}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={(event) => {
                        if (!onClickMoreButton) {
                            return;
                        }
                        onClickMoreButton(event.currentTarget);
                    }}
                >
                    <MoreVert />
                </IconButton>
            </Grid>
        </Grid>
    );
};
