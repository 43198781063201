import { Modelable } from "../../../entities/src/modelable";
import {
    IProject,
    IAuthenticatedApp,
    ISlack,
} from "../../../entities/src/project";

export class Project implements Modelable<IProject> {
    constructor(public data: IProject) {}
}

export class AuthenticatedApp implements Modelable<IAuthenticatedApp> {
    constructor(public data: IAuthenticatedApp) {}
}

export class Slack implements Modelable<ISlack> {
    constructor(public data: ISlack) {}
}
