import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APIKey } from "../../models/apiKey";
import { Project, Slack } from "../../models/project";
import { Subscription } from "../../models/subscription";
import { Variable } from "../../models/variable";
import { authorizeSlackWithToken } from "../authCallback/effects";
import { deleteVariable } from "../variables/effects";
import {
    createProject,
    createSlackIntegration,
    createVariable,
    deleteSlackIntegration,
    fetchActiveSubscriptions,
    fetchAPIKey,
    fetchMyProjects,
    fetchProjectMembers,
    fetchVariables,
} from "./effects";

export interface ProjectState {
    name: string;
    appName?: string;
    appURL?: string;
    hasCreated: boolean;
    isLoading: boolean;
    myProjects?: Project[];
    selectedProject?: Project;
    apiKey: APIKey | null;
    subscription?: Subscription | null;
    productName?: string;
    slack?: Slack;
    members: { [projectID: string]: { email: string }[] };
    variables: Variable[];
}

export const initialState: ProjectState = {
    name: "",
    hasCreated: false,
    isLoading: false,
    apiKey: null,
    members: {},
    variables: [],
};

const project = createSlice({
    name: "project",
    initialState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        updateApp: (state, action: PayloadAction<File>) => {
            state.appName = action.payload.name;
        },
        clearHasCreated: (state) => {
            state.hasCreated = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProject.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(createProject.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createProject.fulfilled, (state, action) => {
            state.name = "";
            state.appName = undefined;
            state.appURL = undefined;
            state.hasCreated = true;
            state.isLoading = false;
            if (!state.myProjects) {
                state.myProjects = [];
            }
            state.myProjects.push(action.payload);
            state.selectedProject = action.payload;
        });
        builder.addCase(fetchMyProjects.fulfilled, (state, action) => {
            state.myProjects = action.payload;
            state.selectedProject = action.payload[0];
            const slackData =
                state.selectedProject?.data.authenticatedApp?.slack;
            if (slackData) {
                state.slack = new Slack(slackData);
            }
        });
        builder.addCase(authorizeSlackWithToken.fulfilled, (state, action) => {
            if (!state.selectedProject) {
                return;
            }
            state.selectedProject.data.authenticatedApp = action.payload;
        });
        builder.addCase(fetchAPIKey.fulfilled, (state, action) => {
            state.apiKey = action.payload;
        });
        builder.addCase(fetchActiveSubscriptions.fulfilled, (state, action) => {
            const result = action.payload;
            if (!result) {
                state.subscription = null;
                return;
            }
            const { subscription, productName } = result;
            state.subscription = subscription;
            state.productName = productName;
        });
        builder.addCase(createSlackIntegration.fulfilled, (state, action) => {
            if (!state.selectedProject || !state.myProjects) {
                return;
            }
            const { channelName, webhookURL, userName } = action.meta.arg;
            const slack = {
                channelName,
                webhookURL,
                userName,
            };
            state.slack = new Slack(slack);
        });
        builder.addCase(deleteSlackIntegration.fulfilled, (state) => {
            state.slack = undefined;
        });
        builder.addCase(fetchProjectMembers.fulfilled, (state, action) => {
            const projectID = action.meta.arg;
            state.members[projectID] = action.payload;
        });
        builder.addCase(fetchVariables.fulfilled, (state, action) => {
            state.variables = action.payload;
        });
        builder.addCase(createVariable.fulfilled, (state, action) => {
            state.variables.push(action.payload);
        });
        builder.addCase(deleteVariable.fulfilled, (state, action) => {
            state.variables = state.variables.filter(
                (variable) => variable.data.id !== action.meta.arg.variableID
            );
        });
    },
});

export const { updateName, updateApp, clearHasCreated } = project.actions;

export default project;
