import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { existsCanceledSubscription } from "./effects";

export interface CheckoutState {
    isExistsCancelSubscription?: boolean;
}

export const initialState: CheckoutState = {};

const checkout = createSlice({
    name: "checkout",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            existsCanceledSubscription.fulfilled,
            (state, action) => {
                state.isExistsCancelSubscription = action.payload;
            }
        );
    },
});

export const {} = checkout.actions;

export default checkout;
