import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authorizeSlackWithToken } from "../authCallback/effects";
import { createAppInfo, deleteAppInfo } from "../builds/effects";
import {
    createAssertion,
    deleteAssertion,
    duplicateTestCase,
    updateAssertion,
    updateSimilarityAssertionValue,
} from "../home/effects";
import { loginWithEmail } from "../login/effects";
import {
    createOpenURLActionEvent,
    createSendKeyActionEvent,
    createShakeActionEvent,
    createSwipeActionEvent,
    createTapActionEvent,
    endTestCase,
    executeTestCase,
    launchApp,
} from "../record/effects";
import { registerWithEmail } from "../register/effects";
import { startTestSuite } from "../testSuites/effects";
import { resetPasswordWithEmail } from "../forgotPassword/effects";
import { updateRunSchedule } from "../constantRun/effects";
import { createProject, createSlackIntegration } from "../project/effects";

type PopupType = "success" | "info" | "error" | "warning";

export type PopupState = {
    isOpen: boolean;
    type?: PopupType;
    message: string;
};

export const initialState: PopupState = {
    isOpen: false,
    type: undefined,
    message: "",
};

const popup = createSlice({
    name: "popup",
    initialState,
    reducers: {
        openPopup: (
            state,
            action: PayloadAction<{ type: PopupType; message: string }>
        ) => {
            state.isOpen = true;
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        dismissPopup: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(loginWithEmail.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                "メールアドレスかパスワードが間違っている可能性があります";
        });
        builder.addCase(registerWithEmail.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "エラーが発生しました";
        });
        builder.addCase(registerWithEmail.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "登録が完了しました";
        });
        builder.addCase(startTestSuite.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "まもなくテストが実行されます！";
        });
        builder.addCase(startTestSuite.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message = action.error.message ?? "エラーが発生しました。";
        });
        builder.addCase(endTestCase.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "テストケースが作成されました！";
        });
        builder.addCase(updateSimilarityAssertionValue.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "アサーションを更新しました！";
        });
        builder.addCase(updateAssertion.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "アサーションを更新しました！";
        });
        builder.addCase(deleteAssertion.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "アサーションを削除しました。";
        });
        builder.addCase(createAssertion.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "アサーションを作成しました！";
        });
        builder.addCase(updateSimilarityAssertionValue.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "通信に失敗しました！";
        });
        builder.addCase(deleteAppInfo.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "ビルドを削除しました。";
        });
        builder.addCase(createAppInfo.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "新しいビルドをアップロードしました！";
        });
        builder.addCase(authorizeSlackWithToken.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "Slackと連携出来ました。";
        });
        builder.addCase(authorizeSlackWithToken.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "Slackとの連携に失敗しました。";
        });
        builder.addCase(resetPasswordWithEmail.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "リセットリンクを送信しました。";
        });
        builder.addCase(resetPasswordWithEmail.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "エラーが発生しました。";
        });
        builder.addCase(createTapActionEvent.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "セッションがタイムアウトしました。もう一度やり直してください。";
        });
        builder.addCase(createSwipeActionEvent.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "セッションがタイムアウトしました。もう一度やり直してください。";
        });
        builder.addCase(createSendKeyActionEvent.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "セッションがタイムアウトしました。もう一度やり直してください。";
        });
        builder.addCase(createOpenURLActionEvent.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "セッションがタイムアウトしました。もう一度やり直してください。";
        });
        builder.addCase(createShakeActionEvent.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "セッションがタイムアウトしました。もう一度やり直してください。";
        });
        builder.addCase(launchApp.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "アプリの起動に失敗しました。もう一度やり直すか、運営までお問い合わせください。";
        });
        builder.addCase(updateRunSchedule.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "定期実行スケジュールを更新しました！";
        });
        builder.addCase(updateRunSchedule.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "定期実行スケジュールの更新に失敗しました。";
        });
        builder.addCase(createSlackIntegration.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "Slack連携が完了しました！";
        });
        builder.addCase(duplicateTestCase.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "テストケースを複製しました！";
        });
        builder.addCase(createProject.fulfilled, (state) => {
            state.isOpen = true;
            state.type = "success";
            state.message = "プロジェクトを作成しました！";
        });
        builder.addCase(createProject.rejected, (state) => {
            state.isOpen = true;
            state.type = "error";
            state.message = "プロジェクトの作成に失敗しました。";
        });
        builder.addCase(executeTestCase.rejected, (state, action) => {
            state.isOpen = true;
            state.type = "error";
            state.message =
                action.error.message ??
                "依存するテストケースが正常に実行できませんでした。";
        });
    },
});

export const { openPopup, dismissPopup } = popup.actions;

export default popup;
