import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import {
    updateEmail,
    updateFirstName,
    updateLastName,
    updatePassword,
} from "../../ducks/register/slice";
import { registerWithEmail } from "../../ducks/register/effects";
import { useRegisterState } from "../../ducks/register/selectors";
import { useLoginState } from "../../ducks/login/selectors";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <a color="inherit" href="https://levetty.co.jp/">
                Levetty, inc.
            </a>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
}));

export const Register = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useRegisterState().register;
    const authState = useLoginState().firebase.auth;
    const history = useHistory();

    useEffect(() => {
        if (!authState.isEmpty && authState.isLoaded && !state.isFailed) {
            history.push("/");
        }
    }, [authState.isEmpty, state.isFailed]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}></Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                        dispatch(registerWithEmail({ ...state }));
                    }}
                >
                    <Grid container direction="column" spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    error={state.firstNameError != undefined}
                                    helperText={
                                        state.firstNameError != undefined
                                            ? state.firstNameError.message
                                            : undefined
                                    }
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="名字"
                                    placeholder="山田"
                                    autoFocus
                                    value={state.firstName}
                                    onChange={(event) => {
                                        dispatch(
                                            updateFirstName(event.target.value)
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    error={state.lastNameError != undefined}
                                    helperText={
                                        state.lastNameError != undefined
                                            ? state.lastNameError.message
                                            : undefined
                                    }
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="名前"
                                    name="lastName"
                                    placeholder="太郎"
                                    autoComplete="lname"
                                    value={state.lastName}
                                    onChange={(event) => {
                                        dispatch(
                                            updateLastName(event.target.value)
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    error={state.emailError != undefined}
                                    helperText={
                                        state.emailError != undefined
                                            ? state.emailError.message
                                            : undefined
                                    }
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    placeholder="info@smartqa.jp"
                                    name="email"
                                    autoComplete="email"
                                    value={state.email}
                                    onChange={(event) => {
                                        dispatch(
                                            updateEmail(event.target.value)
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    error={state.passwordError != undefined}
                                    helperText={
                                        state.passwordError != undefined
                                            ? state.passwordError.message
                                            : undefined
                                    }
                                    required
                                    fullWidth
                                    name="password"
                                    label="パスワード(半角英数記号を含む8文字以上)"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={state.password}
                                    onChange={(event) => {
                                        dispatch(
                                            updatePassword(event.target.value)
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item container justify="space-around">
                                <Grid item>
                                    <a
                                        href="https://smart-qa.io/terms-of-use"
                                        target="_blank"
                                    >
                                        <Typography variant="caption">
                                            利用規約
                                        </Typography>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a
                                        href="https://smart-qa.io/privacy-policy"
                                        target="_blank"
                                    >
                                        <Typography variant="caption">
                                            プライバシーポリシー
                                        </Typography>
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={!state.isValid}
                                    color="primary"
                                >
                                    利用規約に同意の上アカウントを作成
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container justify="flex-end">
                            <Grid item>
                                <Link to="/login">
                                    <Typography variant="body2">
                                        アカウントをお持ちの場合はこちら
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};
