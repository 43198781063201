import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import CheckCircle from "@material-ui/icons/CheckCircle";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    existsCanceledSubscription,
    redirectToCheckout,
} from "../../ducks/checkout/effects";
import { useCheckoutState } from "../../ducks/checkout/selectors";
import { useProjectState } from "../../ducks/project/selectors";

export const Checkout = () => {
    const dispatch = useDispatch();
    const projectState = useProjectState().project;
    const history = useHistory();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const state = useCheckoutState();

    const handleCheckout = () => {
        if (
            !projectState.selectedProject ||
            state.isExistsCancelSubscription === undefined
        ) {
            return;
        }
        const isTrial = !state.isExistsCancelSubscription;
        setLoading(true);
        dispatch(
            redirectToCheckout({
                projectID: projectState.selectedProject.data.id,
                successURL: window.location.origin,
                cancelURL: window.location.origin,
                isTrial: isTrial,
            })
        );
    };

    useEffect(() => {
        if (!projectState.selectedProject) {
            return;
        }
        dispatch(
            existsCanceledSubscription(projectState.selectedProject.data.id)
        );
    }, []);

    if (projectState.subscription) {
        history.push("/");
    }

    const Content = (props: { title: string; value: string }) => {
        const { title, value } = props;
        return (
            <Grid item container justify="space-between" xs={12} wrap="nowrap">
                <Grid item container spacing={1}>
                    <Grid item>
                        <CheckCircle htmlColor={green["A400"]} />
                    </Grid>
                    <Grid item>
                        <Typography noWrap>{title}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography noWrap>{value}</Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
                spacing={3}
            >
                <Grid item container justify="center">
                    <Grid item container direction="column" xs={10} spacing={3}>
                        <Grid item>
                            <Typography variant="h6">ミニマムプラン</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                        >
                            <Content title="並列実行数" value="4端末" />
                            <Content
                                title="月間テスト実行回数"
                                value="無制限"
                            />
                            <Content title="テストケース数" value="50件" />
                            <Content title="プロジェクト数" value="1" />
                            <Content
                                title="対応端末"
                                value="iPhone 8/SE/12 Pro Max・iPad Pro"
                            />
                            <Content
                                title="サポート"
                                value="チャットサポート(Slack)"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    direction="column"
                    spacing={1}
                >
                    <Grid item container direction="column" alignItems="center">
                        <Grid item>
                            <Typography color="primary" variant="body1">
                                14,980円/月
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="textSecondary" variant="caption">
                                50テストケース追加ごとに+5,000円
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleCheckout}
                            disabled={
                                isLoading ||
                                state.isExistsCancelSubscription === undefined
                            }
                        >
                            {isLoading ||
                            state.isExistsCancelSubscription === undefined ? (
                                <CircularProgress size={32} color="primary" />
                            ) : state.isExistsCancelSubscription === true ? (
                                "はじめる"
                            ) : (
                                "30日間お試しする"
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
