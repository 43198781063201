import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginWithEmail } from "./effects";

export interface LoginState {
    email: string;
    password: string;
}

export const initialState: LoginState = {
    email: "",
    password: "",
};

const login = createSlice({
    name: "login",
    initialState,
    reducers: {
        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        updatePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginWithEmail.fulfilled, (state, action) => {
            state.email = "";
            state.password = "";
        });
        builder.addCase(loginWithEmail.rejected, (state) => {
            state.password = "";
        });
    },
});

export const { updateEmail, updatePassword } = login.actions;

export default login;
