import { useSelector } from "react-redux";
import { FirebaseState } from "../../utilities/firebaseState";
import { ProjectState } from "../project/slice";
import { TestSuitesState } from "./slice";

export const useTestSuitesState = () => {
    return useSelector(
        (state: {
            testSuites: TestSuitesState;
            project: ProjectState;
            firebase: FirebaseState;
        }) => state
    );
};
