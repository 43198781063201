import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { usePopupState } from "../../ducks/popup/selectors";
import { dismissPopup } from "../../ducks/popup/slice";
import { useDispatch } from "react-redux";

export const Popup = () => {
    const popupState = usePopupState().popup;
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(dismissPopup());
    };
    return (
        <Snackbar
            open={popupState.isOpen}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            {popupState.isOpen ? (
                <Alert severity={popupState.type}>{popupState.message}</Alert>
            ) : (
                <div />
            )}
        </Snackbar>
    );
};
