import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetPasswordWithEmail } from "./effects";

export interface ForgotPasswordState {
    email: string;
    emailError?: Error;
    isValid: boolean;
    hasSentLink: boolean;
}

export const initialState: ForgotPasswordState = {
    email: "",
    isValid: false,
    hasSentLink: false,
};

const validateEmail = (state: ForgotPasswordState) => {
    if (!state.email) {
        state.isValid = false;
        return;
    }

    if (!state.emailError) {
        state.isValid = true;
    } else {
        state.isValid = false;
    }
};

const isMatchedPattern = (text: string, patternString: string) => {
    const pattern = new RegExp(patternString);
    return pattern.test(text);
};

const forgotPassword = createSlice({
    name: "forgotPassword",
    initialState,
    reducers: {
        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            const isValidEmail = isMatchedPattern(
                state.email,
                "^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,}$"
            );
            state.emailError = isValidEmail
                ? undefined
                : new Error("不正なメールアドレスです。");
            validateEmail(state);
        },
        clearForgotPasswordState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetPasswordWithEmail.fulfilled, (state) => {
            state.email = "";
            state.hasSentLink = true;
        });
        builder.addCase(resetPasswordWithEmail.rejected, (state) => {
            state.email = "";
            state.isValid = false;
        });
    },
});

export const { updateEmail, clearForgotPasswordState } = forgotPassword.actions;

export default forgotPassword;
