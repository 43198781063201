import { useSelector } from "react-redux";
import { ProjectState } from "../project/slice";
import { authCallbackState } from "./slice";

export const useAuthCallbackState = () => {
    return useSelector(
        (state: { authCallback: authCallbackState; project: ProjectState }) =>
            state
    );
};
