import { Modelable } from "../../../entities/src/modelable";
import { IRunSchedule } from "../../../entities/src/runSchedule";

export class RunSchedule implements Modelable<IRunSchedule> {
    constructor(public data: IRunSchedule) {}

    static getHourTitles = (): string[] => {
        const hours = [...Array(24).keys()].map(
            (hour) => `${hour}:00 ~ ${hour + 1}:00`
        );
        return hours;
    };
}
