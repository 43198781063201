import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    dismissDiffImageModal,
    openDiffImageModal,
    selectTestSuite,
    openSelectTestCaseIDsModal,
    dismissSelectTestCaseIDsModal,
} from "../../ducks/testSuites/slice";
import {
    Button,
    Container,
    Grid,
    Typography,
    makeStyles,
    Paper,
    CircularProgress,
    Avatar,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { useTestSuitesState } from "../../ducks/testSuites/selectors";
import {
    listenActionResults,
    listenAssertionResults,
    listenMyTestSuites,
    listenRunResults,
    fetchMyTestCases,
    startTestSuite,
} from "../../ducks/testSuites/effects";
import {
    getStatusColor,
    getStatusMessage,
} from "../../utilities/testResultStatusColor";
import { ActionResultList } from "../organisms/testSuites/actionResultList";
import { ActionResultModal } from "../organisms/testSuites/actionResultModal";
import { SelectTestCaseIDsModal } from "../organisms/testSuites/selectTestCaseIDsModal";
import { green, red } from "@material-ui/core/colors";
import { convertUnixTimeToHourMin } from "../../utilities/unixtime";
import { LoadingView } from "../../components/molecules/loadingView";
import { Device } from "../../models/device";

const useStyles = makeStyles((theme) => ({
    testSuite: {
        padding: theme.spacing(2),
    },
    testSuiteTitlePaper: {
        padding: theme.spacing(2),
    },
}));

export const TestSuites = () => {
    // MARK: Variables
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useTestSuitesState().testSuites;
    const projectState = useTestSuitesState().project;
    const authState = useTestSuitesState().firebase.auth;

    const [selectedActionResultID, _setSelectedActionResultID] = React.useState(
        ""
    );
    const selectedActionResultIDRef = React.useRef(selectedActionResultID);
    const setSelectedActionResultID = (actionResultID: string) => {
        selectedActionResultIDRef.current = actionResultID;
        _setSelectedActionResultID(actionResultID);
    };

    const [selectedRunResultID, _setSelectedRunResultID] = React.useState("");
    const selectedRunResultIDRef = React.useRef(selectedRunResultID);
    const setSelectedRunResultID = (runResultID: string) => {
        selectedRunResultIDRef.current = runResultID;
        _setSelectedRunResultID(runResultID);
    };

    const [isDisplayedOnlyFailed, setDisplayedOnlyFailed] = React.useState(
        false
    );

    useEffect(() => {
        const myProject = projectState.selectedProject;
        if (!authState.uid || !myProject) {
            return;
        }
        const projectID = myProject.data.id;

        dispatch(listenMyTestSuites(projectID));
        dispatch(fetchMyTestCases(projectID));
    }, [authState.uid]);

    useEffect(() => {
        if (state.selectedActionResultID) {
            setSelectedActionResultID(state.selectedActionResultID);
        }
    }, [state.selectedActionResultID]);

    useEffect(() => {
        if (state.selectedRunResultID) {
            setSelectedRunResultID(state.selectedRunResultID);
        }
    }, [state.selectedRunResultID]);

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case "ArrowRight":
                swipeToNext(
                    selectedActionResultIDRef.current,
                    selectedRunResultIDRef.current
                );
                break;
            case "ArrowLeft":
                swipeToPrev(
                    selectedActionResultIDRef.current,
                    selectedRunResultIDRef.current
                );
                break;
        }
    };

    useEffect(() => {
        if (!state.selectedTestSuiteID || !projectState.selectedProject) {
            return;
        }

        // テストスイートを切り替える度に、全てのテストケースを表示するように
        setDisplayedOnlyFailed(false);

        if (state.runResults[state.selectedTestSuiteID]) {
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        dispatch(
            listenRunResults({
                projectID: projectID,
                testSuiteID: state.selectedTestSuiteID,
            })
        );
    }, [state.selectedTestSuiteID]);

    useEffect(() => {
        if (!state.runResults || !projectState.selectedProject) {
            return;
        }

        const projectID = projectState.selectedProject.data.id;

        // ActionResultのフェッチ
        const unfetchActionResultTestSuiteIDs = Object.keys(
            state.runResults
        ).filter((key) => {
            const runResults = state.runResults[key];
            const shouldFetch = runResults
                .map((result) => result.data.id)
                .some((resultID) => !state.actionResults[resultID]);
            return shouldFetch;
        });

        unfetchActionResultTestSuiteIDs.forEach((testSuiteID) => {
            const runResultIDs = state.runResults[testSuiteID].map(
                (runResult) => runResult.data.id
            );
            dispatch(
                listenActionResults({
                    projectID: projectID,
                    testSuiteID: testSuiteID,
                    runResultIDs: runResultIDs,
                })
            );
        });

        // AssertionResultのフェッチ
        const unfetchAssertionResultTestSuiteIDs = Object.keys(
            state.runResults
        ).filter((key) => {
            const runResults = state.runResults[key];
            const shouldFetch = runResults
                .map((result) => result.data.id)
                .some((resultID) => !state.assertionResults[resultID]);
            return shouldFetch;
        });

        unfetchAssertionResultTestSuiteIDs.forEach((testSuiteID) => {
            state.runResults[testSuiteID]
                .map((runResult) => runResult.data.id)
                .forEach((runResultID) => {
                    dispatch(
                        listenAssertionResults({
                            projectID: projectID,
                            testSuiteID: testSuiteID,
                            runResultID: runResultID,
                        })
                    );
                });
        });
    }, [state.runResults]);

    const selectedTestSuite = state.testSuites
        .filter((testSuite) => testSuite.data.id === state.selectedTestSuiteID)
        .slice(-1)[0];

    const getPaperShadow = (isSelected: boolean) => {
        if (isSelected) {
            return "0 0 1px 3px rgba(108, 117, 241, 0.25)";
        } else {
            return "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";
        }
    };

    const swipeToNext = (
        selectedActionResultID: string,
        selectedRunResultID: string
    ): void => {
        const actionResultID = selectedActionResultID;
        const runResultID = selectedRunResultID;
        if (!actionResultID || !runResultID) {
            return;
        }
        const selectedActionResultIDs = state.actionResults[runResultID].map(
            (actionResult) => actionResult.data.id
        );
        const currentIndex = selectedActionResultIDs.indexOf(actionResultID);
        if (currentIndex === selectedActionResultIDs.length - 1) return;
        dispatch(
            openDiffImageModal({
                actionID: selectedActionResultIDs[currentIndex + 1],
                runResultID: runResultID,
            })
        );
    };

    const swipeToPrev = (
        selectedActionResultID: string,
        selectedRunResultID: string
    ): void => {
        const actionResultID = selectedActionResultID;
        const runResultID = selectedRunResultID;
        if (!actionResultID || !runResultID) {
            return;
        }
        const selectedActionResultIDs = state.actionResults[runResultID].map(
            (actionResult) => actionResult.data.id
        );
        const currentIndex = selectedActionResultIDs.indexOf(actionResultID);
        if (currentIndex === 0) return;
        dispatch(
            openDiffImageModal({
                actionID: selectedActionResultIDs[currentIndex - 1],
                runResultID: runResultID,
            })
        );
    };

    const selectedActionResult = Object.values(state.actionResults)
        .flat()
        .filter(
            (actionResult) =>
                actionResult.data.id === state.selectedActionResultID
        )
        .slice(-1)[0];

    const selectedTestCaseID = Object.values(state.runResults)
        .flat()
        .filter((runResult) => runResult.data.id === state.selectedRunResultID)
        .slice(-1)[0]?.data.testCaseID;

    const selectedTestCase = state.allTestCases.filter(
        (testCase) => testCase.data.id === selectedTestCaseID
    )[0];

    const passedCount = state.runResults[
        state.selectedTestSuiteID ?? ""
    ]?.reduce(
        (prev, curr) => prev + (curr.data.status === "success" ? 1 : 0),
        0
    );

    const failedCount = state.runResults[
        state.selectedTestSuiteID ?? ""
    ]?.reduce(
        (prev, curr) => prev + (curr.data.status === "failed" ? 1 : 0),
        0
    );

    const shownRunResults =
        state.selectedTestSuiteID &&
        state.runResults[state.selectedTestSuiteID] &&
        state.runResults[state.selectedTestSuiteID].filter(
            (runResult) =>
                runResult.data.status === "failed" || !isDisplayedOnlyFailed
        );

    return (
        <Container component="main" maxWidth="xl" style={{ marginTop: 16 }}>
            {selectedActionResult && (
                <ActionResultModal
                    isOpen={state.isDiffImageModalOpen}
                    device={new Device(selectedTestCase.data.device)}
                    actionResult={selectedActionResult}
                    allAssertionResults={
                        state.assertionResults[selectedRunResultID] ?? []
                    }
                    onClose={() => {
                        dispatch(dismissDiffImageModal());
                        document.removeEventListener("keydown", handleKeyDown);
                    }}
                    onMoveToLeft={() => {
                        if (
                            !state.selectedActionResultID ||
                            !state.selectedRunResultID
                        ) {
                            return;
                        }
                        swipeToPrev(
                            state.selectedActionResultID,
                            state.selectedRunResultID
                        );
                    }}
                    onMoveToRight={() => {
                        if (
                            !state.selectedActionResultID ||
                            !state.selectedRunResultID
                        ) {
                            return;
                        }
                        swipeToNext(
                            state.selectedActionResultID,
                            state.selectedRunResultID
                        );
                    }}
                />
            )}
            <Grid
                container
                justify="space-between"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid
                    container
                    item
                    direction="column"
                    xs={2}
                    spacing={2}
                    justify="space-around"
                >
                    {state.testSuites?.map((testSuite) => (
                        <Grid item key={testSuite.data.id}>
                            <Paper
                                className={classes.testSuite}
                                elevation={4}
                                style={{
                                    cursor: "pointer",
                                    boxShadow: getPaperShadow(
                                        state.selectedTestSuiteID ===
                                            testSuite.data.id
                                    ),
                                }}
                                onClick={() =>
                                    dispatch(selectTestSuite(testSuite.data.id))
                                }
                            >
                                <Typography variant="body1">
                                    #{testSuite.data.runNumber}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: getStatusColor(
                                            testSuite.data.status
                                        ),
                                    }}
                                >
                                    {getStatusMessage(testSuite.data.status)}
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    {`${testSuite.data.createdAt?.toLocaleDateString()} ${testSuite.data.createdAt?.toLocaleTimeString()}`}
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    {testSuite.data.startAt &&
                                        testSuite.data.endAt &&
                                        `実行時間: ${convertUnixTimeToHourMin(
                                            Math.floor(
                                                (testSuite.data.endAt.getTime() -
                                                    testSuite.data.startAt.getTime()) /
                                                    (1000 * 60)
                                            )
                                        )}`}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    container
                    item
                    xs={10}
                    zeroMinWidth
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-between"
                        wrap="nowrap"
                    >
                        <Grid item>
                            {selectedTestSuite && (
                                <Paper
                                    elevation={0}
                                    className={classes.testSuiteTitlePaper}
                                >
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography variant="body1" noWrap>
                                                Run #
                                                {
                                                    selectedTestSuite.data
                                                        .runNumber
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: getStatusColor(
                                                        selectedTestSuite.data
                                                            .status
                                                    ),
                                                }}
                                                noWrap
                                            >
                                                {getStatusMessage(
                                                    selectedTestSuite.data
                                                        .status
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            wrap="nowrap"
                                            spacing={1}
                                        >
                                            {passedCount > 0 && (
                                                <Grid item>
                                                    <Avatar
                                                        color="primary"
                                                        style={{
                                                            backgroundColor:
                                                                green["A400"],
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            noWrap
                                                        >
                                                            {passedCount}
                                                        </Typography>
                                                    </Avatar>
                                                </Grid>
                                            )}
                                            {failedCount > 0 && (
                                                <Grid item>
                                                    <Avatar
                                                        color="primary"
                                                        style={{
                                                            backgroundColor:
                                                                red["A400"],
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            noWrap
                                                        >
                                                            {failedCount}
                                                        </Typography>
                                                    </Avatar>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )}
                        </Grid>
                        {selectedTestSuite &&
                            selectedTestSuite.data.status === "running" && (
                                <Grid item>
                                    <LoadingView
                                        width={64}
                                        height={64}
                                        loadingIcon="PROGRESS"
                                    />
                                </Grid>
                            )}
                        {failedCount > 0 && (
                            <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Checkbox
                                        checked={isDisplayedOnlyFailed}
                                        onChange={() =>
                                            setDisplayedOnlyFailed(
                                                !isDisplayedOnlyFailed
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" noWrap>
                                        失敗したテストケースのみ表示
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container justify="flex-end" spacing={1}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={state.isLoading}
                                    onClick={() => {
                                        if (!projectState.selectedProject) {
                                            return;
                                        }
                                        dispatch(
                                            startTestSuite({
                                                projectID:
                                                    projectState.selectedProject
                                                        .data.id,
                                            })
                                        );
                                    }}
                                >
                                    {state.isLoading ? (
                                        <CircularProgress
                                            size={32}
                                            color="primary"
                                        />
                                    ) : (
                                        "全てのテストを実行する"
                                    )}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={state.isLoading}
                                    onClick={() => {
                                        dispatch(openSelectTestCaseIDsModal());
                                    }}
                                >
                                    {state.isLoading ? (
                                        <CircularProgress
                                            size={32}
                                            color="primary"
                                        />
                                    ) : (
                                        "テストを選択して実行する"
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SelectTestCaseIDsModal
                        isOpen={state.isSelectTestCaseIDsModalOpen}
                        onClick={() => {
                            if (!projectState.selectedProject) {
                                return;
                            }
                            dispatch(
                                startTestSuite({
                                    projectID:
                                        projectState.selectedProject.data.id,
                                    selectedTestCaseIDs:
                                        state.selectedTestCaseIDs,
                                })
                            );
                        }}
                        onClose={() =>
                            dispatch(dismissSelectTestCaseIDsModal())
                        }
                    />
                    {state.selectedTestSuiteID &&
                        shownRunResults &&
                        shownRunResults.map((runResult) => (
                            <ActionResultList
                                runResult={runResult}
                                actionResults={
                                    state.actionResults[runResult.data.id]
                                }
                                assertionResults={
                                    state.assertionResults[runResult.data.id]
                                }
                                testCase={
                                    state.allTestCases
                                        .filter(
                                            (testCase) =>
                                                testCase.data.id ===
                                                runResult.data.testCaseID
                                        )
                                        .slice(-1)[0]
                                }
                                onClickActionResult={(
                                    actionResultID,
                                    runResultID
                                ) => {
                                    document.addEventListener(
                                        "keydown",
                                        handleKeyDown
                                    );
                                    dispatch(
                                        openDiffImageModal({
                                            actionID: actionResultID,
                                            runResultID: runResultID,
                                        })
                                    );
                                }}
                            />
                        ))}
                </Grid>
            </Grid>
        </Container>
    );
};
