import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { loadStripe } from "@stripe/stripe-js";

export const existsCanceledSubscription = createAsyncThunk<boolean, string>(
    "checkout/existsCanceledSubscription",
    async (projectID) => {
        const firestore = getFirebase().firestore();

        const products = await firestore
            .collection("customer")
            .doc(projectID)
            .collection("subscriptions")
            .where("status", "==", "canceled")
            .get();

        const isExists = products.docs.length > 0;

        return isExists;
    }
);
export const redirectToCheckout = createAsyncThunk<
    void,
    {
        projectID: string;
        successURL: string;
        cancelURL: string;
        isTrial: boolean;
    }
>("checkout/redirectToCheckout", async (params) => {
    const { projectID, successURL, cancelURL, isTrial } = params;
    const firestore = getFirebase().firestore();

    const products = await firestore
        .collection("product")
        .where("active", "==", true)
        .get();
    const prices = await products.docs[0].ref
        .collection("prices")
        .where("active", "==", true)
        .get();
    const priceID = prices.docs[0].id;

    const taxes = await firestore
        .collection("product")
        .doc("tax_rates")
        .collection("tax_rates")
        .where("active", "==", true)
        .get();
    const taxID = taxes.docs[0].id;

    const docRef = await firestore
        .collection("customer")
        .doc(projectID)
        .collection("checkout_sessions")
        .add({
            line_items: [{ price: priceID, tax_rates: [taxID] }],
            success_url: successURL,
            cancel_url: cancelURL,
            allow_promotion_codes: true,
            trial_from_plan: isTrial,
            billing_address_collection: "auto",
        });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data() as {
            error: Error;
            sessionId: string;
        };
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await loadStripe(
                process.env.STRIPE_API_KEY as string
            ).catch((error) => {
                alert(error);
            });
            if (!stripe) {
                return;
            }
            await stripe.redirectToCheckout({ sessionId });
        }
    });
});
