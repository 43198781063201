import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import React from "react";
import { useProjectState } from "../../ducks/project/selectors";
import { useDispatch } from "react-redux";
import { showSubscriptionDetail } from "../../ducks/billing/effects";

export const Billing = () => {
    const projectState = useProjectState().project;
    const dispatch = useDispatch();

    const [isLoading, setLoading] = React.useState<boolean>(false);

    const Content = (props: {
        title: string;
        value: string;
        valueColor?: "primary" | "secondary" | "textPrimary" | "textSecondary";
    }) => {
        const { title, value, valueColor } = props;
        return (
            <Grid item container justify="space-between" xs={8} wrap="nowrap">
                <Grid item container spacing={1}>
                    <Grid item>
                        <Typography noWrap>{title}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography color={valueColor ?? "textPrimary"} noWrap>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
                spacing={3}
                alignItems="center"
            >
                <Grid item container justify="center">
                    <Grid item container direction="column" xs={10} spacing={3}>
                        <Grid item>
                            <Typography variant="h6">請求</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                        >
                            <Content
                                title="プラン"
                                value={`${
                                    projectState.productName ?? "フリープラン"
                                }`}
                                valueColor="primary"
                            />
                            {!projectState.subscription?.data.cancel_at ? (
                                <Content
                                    title="次回引き落とし"
                                    value={
                                        projectState.subscription?.data.current_period_end?.toLocaleDateString() ??
                                        ""
                                    }
                                />
                            ) : (
                                <Content
                                    title="プラン終了日"
                                    value={
                                        projectState.subscription?.data.cancel_at?.toLocaleDateString() ??
                                        ""
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        onClick={() => {
                            if (!projectState.selectedProject) {
                                return;
                            }
                            setLoading(true);
                            const projectID =
                                projectState.selectedProject.data.id;
                            dispatch(showSubscriptionDetail(projectID));
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress size={32} color="primary" />
                        ) : (
                            "詳細を確認する"
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
