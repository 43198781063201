import {
    Button,
    Chip,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useProjectState } from "../../../ducks/project/selectors";
import { ActionEvent } from "../../../models/actionEvent";
import { IOSNode } from "../../../models/iOSNode";
import { Variable } from "../../../models/variable";
import { EditType } from "./assertionModal";

interface EditActionViewProps {
    actionEvent: ActionEvent;
    editType: EditType | null;
    setEditType: (editType: EditType | null) => void;
    selectedIOSNode: IOSNode | null;
    setSelectedIOSNode: (iOSNode: IOSNode | null) => void;
    handleUpdateInputText: (text: string) => void;
    handleUpdateVariable: (variable: Variable) => void;
    handleUpdateTapElement: (iOSNode: IOSNode) => void;
}

export const EditActionView = (props: EditActionViewProps) => {
    const {
        actionEvent,
        editType,
        setEditType,
        selectedIOSNode,
        setSelectedIOSNode,
        handleUpdateInputText,
        handleUpdateVariable,
        handleUpdateTapElement,
    } = props;

    const projectState = useProjectState().project;

    const [inputText, setInputText] = React.useState<string>("");
    const [variable, setVariable] = React.useState<Variable | null>(null);

    useEffect(() => {
        if (!variable) {
            return;
        }

        setInputText("");
    }, [variable]);

    useEffect(() => {
        setInputText(actionEvent.data.text ?? "");

        if (actionEvent.data.variableID) {
            const variable = projectState.variables.filter(
                (variable) => variable.data.id === actionEvent.data.variableID
            )[0];
            if (variable) {
                setVariable(variable);
            }
        }
    }, [actionEvent]);

    return (
        <Grid item container alignItems="center" xs={8}>
            <Grid
                item
                container
                direction="column"
                component={Paper}
                spacing={2}
                style={{ padding: 8 }}
            >
                {editType && (
                    <Grid item>
                        <IconButton
                            onClick={() => {
                                setEditType(null);
                                setSelectedIOSNode(null);
                            }}
                        >
                            <ArrowBack />
                        </IconButton>
                    </Grid>
                )}

                <Grid item>
                    <Typography>{actionEvent.getActionName()}</Typography>
                </Grid>
                <Grid item style={{ height: 48 }}>
                    {editType === "INPUT_ACTION" && (
                        <TextField
                            fullWidth
                            value={
                                variable ? `$${variable.data.name}` : inputText
                            }
                            onChange={(event) => {
                                setInputText(
                                    event.currentTarget.value as string
                                );
                            }}
                            InputProps={{
                                endAdornment: variable ? (
                                    <IconButton
                                        onClick={() => {
                                            setInputText("");
                                            setVariable(null);
                                        }}
                                        size="small"
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                ) : null,
                            }}
                        />
                    )}
                    {!editType && (
                        <Typography variant="body2">
                            {actionEvent.getDescription(projectState.variables)}
                        </Typography>
                    )}
                    {editType === "TAP_ELEMENT" && (
                        <Typography variant="body2">
                            {selectedIOSNode?.getDescription()}
                        </Typography>
                    )}
                </Grid>
                {variable && (
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">
                            {variable.data.value}
                        </Typography>
                    </Grid>
                )}
                {editType === "INPUT_ACTION" && (
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        justify="center"
                        style={{
                            overflowX: "scroll",
                            scrollbarWidth: "none",
                        }}
                        spacing={1}
                    >
                        {projectState.variables.map((variable) => (
                            <Grid item>
                                <Chip
                                    clickable
                                    color="primary"
                                    variant="outlined"
                                    label={variable.data.name}
                                    onClick={() => {
                                        setVariable(variable);
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Grid item container justify="center">
                    <Grid item>
                        {editType ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (
                                        editType === "INPUT_ACTION" &&
                                        !variable
                                    ) {
                                        handleUpdateInputText(inputText);
                                    }
                                    if (
                                        editType === "INPUT_ACTION" &&
                                        variable
                                    ) {
                                        handleUpdateVariable(variable);
                                    }
                                    if (
                                        editType === "TAP_ELEMENT" &&
                                        selectedIOSNode
                                    ) {
                                        handleUpdateTapElement(selectedIOSNode);
                                    }
                                    setEditType(null);
                                    setSelectedIOSNode(null);
                                }}
                            >
                                保存する
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (
                                        actionEvent.data.action === "INPUT_TEXT"
                                    ) {
                                        setEditType("INPUT_ACTION");
                                    } else if (
                                        actionEvent.data.action === "CLICK"
                                    ) {
                                        setEditType("TAP_ELEMENT");
                                    }
                                }}
                            >
                                編集する
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
