import { Box } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import React from "react";
import { Point } from "../../../ducks/record/slice";

interface SwipeBarProps {
    swipeDirection: "up" | "down" | "left" | "right" | null;
    fromPoint: Point;
    toPoint: Point;
    swipeLineWidth: number;
}

export const SwipeBar = (props: SwipeBarProps) => {
    const { swipeDirection, fromPoint, toPoint, swipeLineWidth } = props;
    return (
        <Box
            style={{
                position: "absolute",
                left:
                    Math.min(fromPoint.x, toPoint.x) -
                    (["up", "down"].includes(swipeDirection ?? "")
                        ? swipeLineWidth
                        : 0),
                top:
                    Math.min(fromPoint.y, toPoint.y) -
                    (["left", "right"].includes(swipeDirection ?? "")
                        ? swipeLineWidth
                        : 0),
                width: ["up", "down"].includes(swipeDirection ?? "")
                    ? swipeLineWidth
                    : Math.abs(toPoint.x - fromPoint.x),
                height: ["left", "right"].includes(swipeDirection ?? "")
                    ? swipeLineWidth
                    : Math.abs(toPoint.y - fromPoint.y),
                backgroundColor: blue[100],
            }}
        />
    );
};
