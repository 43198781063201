import {
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    ListItemText,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import Apple from "@material-ui/icons/Apple";
import DeleteForever from "@material-ui/icons/DeleteForever";
import GetApp from "@material-ui/icons/GetApp";
import { blue, grey } from "@material-ui/core/colors";
import { useBuildsState } from "../../ducks/builds/selectors";
import {
    createAppInfo,
    deleteAppInfo,
    uploadApp,
} from "../../ducks/builds/effects";
import {
    updateApp,
    openConfirmDeleteModal,
    dismissConfirmDeleteModal,
} from "../../ducks/builds/slice";
import { ConfirmDeleteModal } from "../organisms/confirmDeleteModal";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
        display: "flex",
        alignItems: "center",
    },
    uploadApp: {
        marginTop: theme.spacing(2),
        backgroundColor: blue[50],
        height: 128,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    fileInput: {
        opacity: "0",
        appearance: "none",
        position: "absolute",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: 256,
    },
}));

export const Builds: React.FC<{}> = (props) => {
    const classes = useStyles();
    const state = useBuildsState().builds;
    const projectState = useBuildsState().project;
    const dispatch = useDispatch();
    const [selectedToDeleteAppInfoID, setSelectedToDeleteAppInfoID] = useState(
        ""
    );

    const handleFileDragged = (files: File[]) => {
        if (!projectState.selectedProject) {
            return;
        }
        if (files.length > 1 || files.length === 0) {
            return;
        }
        const file = files[0];
        if (
            ![
                "application/zip",
                "application/x-zip-compressed",
                "multipart/x-zip",
            ].includes(file.type)
        ) {
            return;
        }
        dispatch(updateApp(file));
        dispatch(
            uploadApp({
                projectID: projectState.selectedProject.data.id,
                build: file,
            })
        );
    };

    return (
        <Container component="main" maxWidth="lg">
            <Paper className={classes.paper} elevation={0}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Typography component="h1" variant="h6">
                            ビルド一覧
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        spacing={2}
                        wrap="nowrap"
                    >
                        {state.appInfos &&
                            state.appInfos.length > 0 &&
                            state.appInfos.map((appInfo) => {
                                return (
                                    <Grid
                                        container
                                        item
                                        spacing={2}
                                        key={appInfo.data.id}
                                        wrap="nowrap"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        <Grid item xs={1}>
                                            <Paper
                                                style={{
                                                    textAlign: "center",
                                                    width: 48,
                                                    height: 48,
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {appInfo.data.appIconURL ? (
                                                    <img
                                                        src={
                                                            appInfo.data
                                                                .appIconURL
                                                        }
                                                        width="48"
                                                        height="48"
                                                    />
                                                ) : (
                                                    <Apple
                                                        fontSize="large"
                                                        htmlColor={grey[400]}
                                                        style={{ height: 48 }}
                                                    ></Apple>
                                                )}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                primary={
                                                    appInfo.data.bundleName
                                                }
                                                secondary={
                                                    appInfo.data
                                                        .bundleIdentifier
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                secondary={`Bundle: ${appInfo.data.bundleVersion} - Release: ${appInfo.data.releaseVersion}`}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText
                                                secondary={`>= iOS: ${appInfo.data.minPlatformVersion}`}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText
                                                secondary={`Uploaded: ${appInfo.data.createdAt?.toLocaleDateString()}`}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            xs={1}
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        window.open(
                                                            appInfo.data.appURL
                                                        )
                                                    }
                                                >
                                                    <GetApp />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => {
                                                        dispatch(
                                                            openConfirmDeleteModal()
                                                        );
                                                        setSelectedToDeleteAppInfoID(
                                                            appInfo.data.id
                                                        );
                                                    }}
                                                >
                                                    <DeleteForever />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <ConfirmDeleteModal
                        isOpen={state.isConfirmDeleteModelOpen}
                        onClose={() => {
                            dispatch(dismissConfirmDeleteModal());
                            setSelectedToDeleteAppInfoID("");
                        }}
                        onClick={() => {
                            if (!projectState.selectedProject) {
                                return;
                            }
                            dispatch(
                                deleteAppInfo({
                                    projectID:
                                        projectState.selectedProject?.data.id,
                                    appInfoID: selectedToDeleteAppInfoID,
                                })
                            );
                            dispatch(dismissConfirmDeleteModal());
                            setSelectedToDeleteAppInfoID("");
                        }}
                        title="ビルドを削除してよろしいですか？"
                        actionText="削除する"
                    />
                    <FormControl className={classes.form}>
                        <Dropzone
                            onDrop={handleFileDragged}
                            accept={[
                                "application/zip",
                                "application/x-zip-compressed",
                                "multipart/x-zip",
                            ]}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Paper
                                    {...getRootProps()}
                                    className={classes.uploadApp}
                                    variant="outlined"
                                    style={{ cursor: "pointer" }}
                                >
                                    <input
                                        required
                                        {...getInputProps()}
                                    ></input>
                                    <Apple fontSize="large" />
                                    <Typography>
                                        {state.uploadedAppName
                                            ? state.uploadedAppName
                                            : "ドラッグorクリックしてビルド(.app.zip)をアップロードする"}
                                    </Typography>
                                </Paper>
                            )}
                        </Dropzone>
                        <Button
                            className={classes.submit}
                            disabled={!state.uploadedAppURL}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                if (
                                    !projectState.selectedProject ||
                                    !state.uploadedAppURL
                                ) {
                                    return;
                                }
                                dispatch(
                                    createAppInfo({
                                        projectID:
                                            projectState.selectedProject.data
                                                .id,
                                        appURL: state.uploadedAppURL,
                                    })
                                );
                            }}
                        >
                            {state.isUploading ? (
                                <CircularProgress size={32} color="primary" />
                            ) : (
                                "アップロードする"
                            )}
                        </Button>
                    </FormControl>
                </Grid>
            </Paper>
        </Container>
    );
};
