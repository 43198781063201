import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Container,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import { useIntegrationsState } from "../../ducks/integrations/selectors";
import { sendMessage } from "../../ducks/integrations/effects";
import slackIcon from "../../assets/slack.png";
import {
    createSlackIntegration,
    deleteSlackIntegration,
} from "../../ducks/project/effects";
import { Slack } from "../../models/project";

export const Integrations: React.FC<{}> = (props) => {
    const state = useIntegrationsState();
    const project = state.project;
    const dispatch = useDispatch();

    const onClickSlackConnectButton = (
        webhookURL: string,
        channelName: string,
        userName: string
    ) => {
        if (!project.selectedProject) {
            return;
        }

        const projectID = project.selectedProject.data.id;

        dispatch(
            createSlackIntegration({
                projectID,
                webhookURL,
                channelName,
                userName,
            })
        );
    };

    const onClickDeleteSlackIntegration = () => {
        if (!project.selectedProject) {
            return;
        }

        const projectID = project.selectedProject.data.id;
        dispatch(deleteSlackIntegration({ projectID }));
    };

    const onClickSendTestMessage = () => {
        if (!project.slack) {
            return;
        }
        dispatch(
            sendMessage({
                slack: project.slack.data,
                text:
                    "SmartQAからテストメッセージが送信されました！このメッセージが送られているチャンネルに、テスト結果が通知されます。",
            })
        );
    };

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                alignItems="center"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    xs={10}
                    spacing={4}
                >
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item style={{ lineHeight: 0 }}>
                            <img src={slackIcon} width="32" height="32" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" noWrap>
                                Slack
                            </Typography>
                        </Grid>
                    </Grid>
                    {project.slack ? (
                        <SlackDetail
                            slack={project.slack}
                            handleDelete={onClickDeleteSlackIntegration}
                            handleSendTestMessage={onClickSendTestMessage}
                        />
                    ) : (
                        <IntegrateSlack onSubmit={onClickSlackConnectButton} />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

interface SlackDetailProps {
    slack: Slack;
    handleDelete?: () => void;
    handleSendTestMessage?: () => void;
}

const SlackDetail = (props: SlackDetailProps) => {
    const { slack, handleDelete, handleSendTestMessage } = props;
    return (
        <Grid item container direction="column" alignItems="center" spacing={3}>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="body2">
                        SmartQAからテスト結果が通知されます。
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            if (!handleSendTestMessage) {
                                return;
                            }
                            handleSendTestMessage();
                        }}
                    >
                        テストメッセージを送信
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">
                        チャンネル名
                    </Typography>
                    <Typography>{slack.data.channelName}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="textSecondary">
                        表示名
                    </Typography>
                    <Typography>{slack.data.userName}</Typography>
                </Grid>
                <Grid item style={{ overflowX: "scroll" }} xs={12}>
                    <Typography variant="caption" color="textSecondary" noWrap>
                        Incoming Webhook URL
                    </Typography>
                    <br />
                    <Typography variant="caption">
                        {slack.data.webhookURL}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                        if (!handleDelete) {
                            return;
                        }
                        handleDelete();
                    }}
                >
                    解除する
                </Button>
            </Grid>
        </Grid>
    );
};

interface IntegrateSlackProps {
    onSubmit?: (
        webhookURL: string,
        channelNale: string,
        userName: string
    ) => void;
}

const IntegrateSlack = (props: IntegrateSlackProps) => {
    const { onSubmit } = props;

    const [webhookURL, setwebhookURL] = React.useState<string>("");
    const [channelName, setChannelName] = React.useState<string>("");
    const [userName, setUserName] = React.useState<string>("SmartQA");

    const iswebhookError =
        webhookURL.length > 0 && !webhookURL.startsWith("https://");
    const isChannelNameError =
        channelName.length > 0 && !channelName.startsWith("#");
    const isUserNameError = userName.length === 0;

    const isButtonDisabled =
        iswebhookError ||
        isChannelNameError ||
        isUserNameError ||
        !webhookURL ||
        !channelName ||
        !userName;
    return (
        <Grid item container direction="column" alignItems="center" spacing={4}>
            <Grid item container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="body2">
                        Slackと連携すると、SmartQAからテスト結果が通知されます。
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        Incoming webhookを使用して設定します。
                        <br />
                        <a
                            href="https://get.slack.help/hc/en-us/articles/115005265063-Incoming-webhooks-for-Slack"
                            target="_blank"
                        >
                            Slackのドキュメント
                        </a>
                        から設定できます。
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={2}
                xs={12}
            >
                <Grid item container xs={12}>
                    <TextField
                        value={webhookURL}
                        variant="outlined"
                        label="Incoming webhook URL"
                        placeholder="https://hooks.slack.com/services/XXX/XXX/XXX"
                        size="small"
                        fullWidth
                        required
                        onChange={(event) => {
                            setwebhookURL(event.target.value);
                        }}
                        error={iswebhookError}
                        helperText={
                            iswebhookError && "有効なURLを入力してください"
                        }
                    />
                </Grid>
                <Grid item container xs={12}>
                    <TextField
                        value={channelName}
                        variant="outlined"
                        label="チャンネル名"
                        placeholder="#general"
                        size="small"
                        fullWidth
                        required
                        onChange={(event) => {
                            setChannelName(event.target.value);
                        }}
                        error={isChannelNameError}
                        helperText={
                            isChannelNameError &&
                            "Slack チャンネル名の先頭には「#」をつけてください"
                        }
                    />
                </Grid>
                <Grid item container xs={12}>
                    <TextField
                        value={userName}
                        variant="outlined"
                        label="投稿ユーザー名"
                        placeholder="SmartQA"
                        size="small"
                        fullWidth
                        required
                        onChange={(event) => {
                            setUserName(event.target.value);
                        }}
                        error={isUserNameError}
                        helperText={
                            isUserNameError &&
                            "ユーザーネームを入力してください"
                        }
                    />
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isButtonDisabled}
                        onClick={() => {
                            if (!onSubmit) {
                                return;
                            }
                            onSubmit(webhookURL, channelName, userName);
                        }}
                    >
                        連携する
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
