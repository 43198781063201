import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions } from "../../firebaseConfig";
import { IAuthenticatedApp } from "../../../../entities/src/project";

interface VerifyAccessTokenRequest {
    projectID: string;
    accessToken: string;
}

export const authorizeSlackWithToken = createAsyncThunk<
    IAuthenticatedApp,
    {
        projectID: string;
        accessToken: string;
    }
>("authCallback/verifyAccessToken", async (args) => {
    const { projectID, accessToken } = args;
    const body: VerifyAccessTokenRequest = {
        projectID: projectID,
        accessToken: accessToken,
    };
    const client = functions.httpsCallable("verifyAccessToken");
    const response = await client(body);
    const result = response.data as IAuthenticatedApp;

    return result;
});
