import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";
import createStore from "./ducks/createStore";
import { Recorder } from "./containers/pages/record";
import { Home } from "./containers/pages/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { app } from "./firebaseConfig";
import { Login } from "./containers/pages/login";
import "./index.css";
import { HeaderBar } from "./containers/organisms/headerBar";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { Project } from "./containers/pages/project";
import { Popup } from "./containers/organisms/popup";
import { Auth } from "./containers/pages/auth";
import { TestSuites } from "./containers/pages/testSuites";
import { Integrations } from "./containers/pages/integrations";
import { AuthCallback } from "./containers/pages/authCallback";
import { Builds } from "./containers/pages/builds";
import { ForgotPassword } from "./containers/pages/forgotPassword";
import { APIKeyPage } from "./containers/pages/apiKey";
import { blue, red } from "@material-ui/core/colors";
import { Checkout } from "./containers/pages/checkout";
import { Billing } from "./containers/pages/billing";
import { Register } from "./containers/pages/register";
import { Logout } from "./containers/pages/logout";
import { ConstantRun } from "./containers/pages/constantRun";
import { Member } from "./containers/pages/member";
import { Variables } from "./containers/pages/variables";

const theme = createMuiTheme({
    typography: {
        fontFamily: ["Noto Sans CJK JP", "Roboto"].join(","),
        fontWeightRegular: 500,
    },
    palette: {
        primary: {
            main: blue["700"],
        },
        secondary: {
            main: red["700"],
        },
    },
});

const store = createStore();

ReactDom.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider
            firebase={app}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}
            config={{ userProfile: "users", useFirestoreForProfile: true }}
        >
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <Router>
                    <HeaderBar />
                    <Popup />
                    <Switch>
                        <Route
                            path="/forgot-password"
                            component={ForgotPassword}
                        />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/register" component={Register} />
                        <Auth>
                            <Project>
                                <Route path="/checkout" component={Checkout} />
                                <Route path="/record" component={Recorder} />
                                <Route
                                    path="/test-suites"
                                    component={TestSuites}
                                />
                                <Route path="/builds" component={Builds} />
                                <Route
                                    path="/constant-run"
                                    component={ConstantRun}
                                />
                                <Route
                                    path="/integrations"
                                    component={Integrations}
                                />
                                <Route
                                    path="/variables"
                                    component={Variables}
                                />
                                <Route path="/member" component={Member} />
                                <Route path="/api-key" component={APIKeyPage} />
                                <Route path="/billing" component={Billing} />
                                <Route exact path="/" component={Home} />
                                <Route
                                    path="/oauth/slack/finish"
                                    component={AuthCallback}
                                />
                            </Project>
                        </Auth>
                    </Switch>
                </Router>
            </MuiThemeProvider>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById("root")
);
