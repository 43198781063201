import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useProjectState } from "../../../ducks/project/selectors";
import { Variable } from "../../../models/variable";

interface SendTextFormProps {
    handleOpenVariableModal: () => void;
    onSubmit?: (args: { text?: string; variableID?: string }) => void;
}

export const SendTextForm = (props: SendTextFormProps) => {
    const { handleOpenVariableModal, onSubmit } = props;

    const projectState = useProjectState().project;

    const [text, setText] = React.useState<string>("");
    const [variable, setVariable] = React.useState<Variable | null>(null);

    useEffect(() => {
        if (!variable) {
            return;
        }

        setText("");
    }, [variable]);

    return (
        <Grid item container alignItems="center" direction="column" spacing={1}>
            <Grid item container justify="center" spacing={1}>
                <Grid item>
                    <TextField
                        placeholder="Aa"
                        value={variable ? `$${variable.data.name}` : text}
                        margin="normal"
                        size="medium"
                        helperText="送信するテキストを入力してください。"
                        onChange={(event) => {
                            setText(event.currentTarget.value);
                        }}
                        style={{ height: 44 }}
                        InputProps={{
                            endAdornment: variable ? (
                                <IconButton
                                    onClick={() => {
                                        setText("");
                                        setVariable(null);
                                    }}
                                    size="small"
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            ) : null,
                        }}
                    />
                </Grid>
                <Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ height: 44 }}
                        onClick={() => {
                            if (!onSubmit) {
                                return;
                            }

                            if (variable) {
                                onSubmit({ variableID: variable.data.id });
                            } else if (text) {
                                onSubmit({ text });
                            }
                        }}
                    >
                        送信
                    </Button>
                </Grid>
            </Grid>
            {variable && (
                <Grid item>
                    <Typography variant="caption">
                        {variable.data.value}
                    </Typography>
                </Grid>
            )}
            <Grid
                item
                container
                wrap="nowrap"
                justify="center"
                style={{
                    overflowX: "scroll",
                    scrollbarWidth: "none",
                }}
                spacing={1}
            >
                {projectState.variables.map((variable) => (
                    <Grid item>
                        <Chip
                            clickable
                            color="primary"
                            variant="outlined"
                            label={variable.data.name}
                            onClick={() => {
                                setVariable(variable);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        handleOpenVariableModal();
                    }}
                >
                    変数を追加
                </Button>
            </Grid>
        </Grid>
    );
};
