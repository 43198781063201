import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { registerWithEmail } from "./effects";

export interface RegisterState {
    lastName: string;
    lastNameError?: Error;
    firstName: string;
    firstNameError?: Error;
    email: string;
    emailError?: Error;
    password: string;
    passwordError?: Error;
    isValid: boolean;
    isFailed: boolean;
}

export const initialState: RegisterState = {
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    isValid: false,
    isFailed: false,
};

const validateRegisterContents = (state: RegisterState) => {
    if (!(state.lastName && state.firstName && state.email && state.password)) {
        state.isValid = false;
        return;
    }

    if (
        !state.firstNameError &&
        !state.lastNameError &&
        !state.emailError &&
        !state.passwordError
    ) {
        state.isValid = true;
    } else {
        state.isValid = false;
    }
};

const isMatchedPattern = (text: string, patternString: string) => {
    const pattern = new RegExp(patternString);
    return pattern.test(text);
};

const recorder = createSlice({
    name: "login",
    initialState,
    reducers: {
        updateLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
            // TODO: Localized
            state.lastNameError = state.lastName
                ? undefined
                : new Error("名字を入力してください。");
            validateRegisterContents(state);
        },
        updateFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
            // TODO: Localized
            state.firstNameError = state.firstName
                ? undefined
                : new Error("名前を入力してください。");
            validateRegisterContents(state);
        },
        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            const isValidEmail = isMatchedPattern(
                state.email,
                "^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,}$"
            );
            // TODO: Localized
            state.emailError = isValidEmail
                ? undefined
                : new Error("不正なメールアドレスです。");
            validateRegisterContents(state);
        },
        updatePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
            const isValidPassword = isMatchedPattern(
                state.password,
                "^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$"
            );
            state.passwordError = isValidPassword
                ? undefined
                : new Error(
                      "半角英数記号のいずれか8文字以上で入力してください。"
                  );
            validateRegisterContents(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(registerWithEmail.fulfilled, (state, action) => {
            state.email = "";
            state.password = "";
            state.isFailed = false;
        });
        builder.addCase(registerWithEmail.rejected, (state, action) => {
            state.isFailed = true;
        });
    },
});

export const {
    updateLastName,
    updateFirstName,
    updateEmail,
    updatePassword,
} = recorder.actions;

export default recorder;
