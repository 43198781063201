import { createAsyncThunk, current, Action } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";

interface ILoginWithEmail {
    email: string;
    password: string;
}

export const loginWithEmail = createAsyncThunk<void, ILoginWithEmail>(
    "login/loginWithEmail",
    async (params, thunk) => {
        const firebase = getFirebase();
        const response = await firebase
            .auth()
            .signInWithEmailAndPassword(params.email, params.password);

        if (!response.user?.emailVerified) {
            response.user?.sendEmailVerification();
        }
    }
);
