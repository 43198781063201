import { createAsyncThunk, current, Action } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";

interface IRegisterWithEmail {
    email: string;
    password: string;
}

export const registerWithEmail = createAsyncThunk<void, IRegisterWithEmail>(
    "register/registerWithEmail",
    (params, _) => {
        const firebase = getFirebase();
        return firebase
            .auth()
            .createUserWithEmailAndPassword(params.email, params.password)
            .then();
    }
);
