import {
    Button,
    Dialog,
    Grid,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    DialogActions,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Close from "@material-ui/icons/Close";
import React from "react";

interface RenameTestModalProps {
    isOpen: boolean;
    prevTestName: string;
    onClose: () => void;
    onSubmit: (testCaseName: string) => void;
}

export const RenameTestModal = (props: RenameTestModalProps) => {
    const { isOpen, prevTestName, onClose, onSubmit } = props;
    const [afterTestName, setAfterTestName] = React.useState<string>("");

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth="lg"
            PaperProps={{
                style: { backgroundColor: grey[100], padding: 32 },
            }}
            BackdropProps={{ timeout: 500 }}
        >
            <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justify="space-around"
                direction="column"
                spacing={4}
            >
                <Grid container justify="flex-end">
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justify="space-around"
                    >
                        <Typography>テスト名の変更</Typography>
                        <IconButton onClick={() => onClose()}>
                            <Close htmlColor={grey[500]} />
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="afterName"
                        fullWidth
                        placeholder={prevTestName}
                        onChange={(event) =>
                            setAfterTestName(event.target.value)
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary">
                        キャンセル
                    </Button>
                    <Button
                        onClick={() => onSubmit(afterTestName)}
                        color="primary"
                    >
                        変更する
                    </Button>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};
