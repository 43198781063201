import { createAsyncThunk, current, Action } from "@reduxjs/toolkit";
import { getFirebase } from "react-redux-firebase";

interface IResetPasswordWithEmail {
    email: string;
}

export const resetPasswordWithEmail = createAsyncThunk<
    void,
    IResetPasswordWithEmail
>("forgotPassword/resetPasswordWithEmail", (params) => {
    const firebase = getFirebase();
    return firebase.auth().sendPasswordResetEmail(params.email).then();
});
