import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { useProjectState } from "../../ducks/project/selectors";
import Apple from "@material-ui/icons/Apple";
import { blue } from "@material-ui/core/colors";
import {
    clearHasCreated,
    updateApp,
    updateName,
} from "../../ducks/project/slice";
import {
    createProject,
    fetchActiveSubscriptions,
    fetchAPIKey,
    fetchMyProjects,
    fetchVariables,
} from "../../ducks/project/effects";
import { LoadingDialog } from "../../components/molecules/loadingDialog";
import { fetchMyAppInfos } from "../../ducks/builds/effects";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
        display: "flex",
    },
    uploadApp: {
        marginTop: theme.spacing(2),
        backgroundColor: blue[50],
        height: 128,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    fileInput: {
        opacity: "0",
        appearance: "none",
        position: "absolute",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const Project: React.FC<{}> = (props) => {
    const classes = useStyles();
    const state = useProjectState().project;
    const authState = useProjectState().firebase.auth;
    const dispatch = useDispatch();
    const history = useHistory();
    const [build, setBuild] = React.useState<File | null>(null);

    useEffect(() => {
        if (!authState.uid) {
            return;
        }
        dispatch(fetchMyProjects(authState.uid));
    }, []);

    useEffect(() => {
        if (!state.hasCreated) {
            return;
        }
        history.push("/");
        dispatch(clearHasCreated());
    }, [state.hasCreated]);

    useEffect(() => {
        if (!state.selectedProject) {
            return;
        }

        const projectID = state.selectedProject.data.id;

        dispatch(fetchMyAppInfos(projectID));
        dispatch(fetchAPIKey(projectID));
        dispatch(fetchActiveSubscriptions(projectID));
        dispatch(fetchVariables(projectID));
    }, [state.selectedProject]);

    const handleFileDragged = (files: File[]) => {
        console.log("File:", files[0]);
        console.log("FileName:", files[0].name);
        console.log("FileType:", files[0].type);
        if (files.length > 1 || files.length == 0) {
            return;
        }
        const file = files[0];
        if (
            ![
                "application/zip",
                "application/x-zip-compressed",
                "multipart/x-zip",
            ].includes(file.type)
        ) {
            return;
        }
        setBuild(file);
        dispatch(updateApp(file));
    };

    if (!authState.isEmpty) {
        if (
            state.myProjects === undefined ||
            (state.myProjects.length > 0 && state.subscription === undefined)
        ) {
            return <div />;
        } else if (state.myProjects.length > 0) {
            return <Route children={props.children} />;
        }
    }

    return (
        <Container component="main" maxWidth="sm">
            <LoadingDialog isLoading={state.isLoading} />
            <Grid
                component={Paper}
                container
                direction="column"
                spacing={2}
                style={{ padding: 16 }}
            >
                <Grid item>
                    <Typography component="h1" variant="h6">
                        プロジェクトの作成
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        こちらを参考に、ビルド成果物をアップロードしてください。
                        <br />
                        <a
                            href="https://www.notion.so/levetty/SmartQA-e6e9394102bc4402b12c8d474835f7cf#880cff0a62fe459992f85da05a38bf8c"
                            target="_blank"
                        >
                            プロジェクトの作成について
                        </a>
                        <br />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        *シミュレータ向けビルドでないとご利用いただけません。
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl className={classes.form}>
                        <TextField
                            required
                            label="プロジェクト名"
                            placeholder="SmartQA"
                            variant="outlined"
                            value={state.name}
                            onChange={(event) =>
                                dispatch(updateName(event.target.value))
                            }
                        />
                        <Dropzone
                            onDrop={handleFileDragged}
                            accept={[
                                "application/zip",
                                "application/x-zip-compressed",
                                "multipart/x-zip",
                            ]}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Paper
                                    {...getRootProps()}
                                    className={classes.uploadApp}
                                    variant="outlined"
                                    style={{ cursor: "pointer" }}
                                >
                                    <input
                                        required
                                        {...getInputProps()}
                                    ></input>
                                    <Apple fontSize="large" />
                                    <Typography variant="body2">
                                        {state.appName
                                            ? state.appName
                                            : "ドラッグorクリックしてビルド(.app.zip)をアップロードする"}
                                    </Typography>
                                </Paper>
                            )}
                        </Dropzone>
                        <Button
                            color="primary"
                            className={classes.submit}
                            disabled={!build}
                            onClick={() => {
                                if (!authState.uid || !build) {
                                    return;
                                }
                                dispatch(
                                    createProject({
                                        name: state.name,
                                        uid: authState.uid,
                                        build: build,
                                    })
                                );
                            }}
                        >
                            作成する
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
};
