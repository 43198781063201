import { Grid, Box, Typography, List } from "@material-ui/core";
import React from "react";
import { ActionView } from "../../../components/molecules/actionView";
import { ActionEvent } from "../../../models/actionEvent";
import { ActionResult } from "../../../models/actionResult";
import { AssertionResult } from "../../../models/assertionResult";
import { Device } from "../../../models/device";
import { RunResult } from "../../../models/runResult";
import { TestCase } from "../../../models/testCase";
import {
    getStatusColor,
    getStatusMessage,
} from "../../../utilities/testResultStatusColor";
import { convertUnixTimeToMinSec } from "../../../utilities/unixtime";
interface ActionResultListProps {
    runResult: RunResult;
    actionResults: ActionResult[];
    assertionResults?: AssertionResult[];
    testCase: TestCase;
    onClickActionResult?: (actionResultID: string, runResultID: string) => void;
}

export const ActionResultList = (props: ActionResultListProps) => {
    const {
        runResult,
        actionResults,
        assertionResults,
        testCase,
        onClickActionResult,
    } = props;

    const device = new Device(testCase.data.device);

    return (
        <Grid
            container
            alignItems="center"
            style={{ flexWrap: "nowrap" }}
            key={testCase?.data.id}
        >
            <Grid item xs={1}>
                <Typography
                    variant="body1"
                    style={{ color: getStatusColor(runResult.data.status) }}
                >
                    {getStatusMessage(runResult.data.status)}
                </Typography>
                {runResult.data.reason && (
                    <Typography variant="caption" color="textSecondary">
                        {runResult.getReasonDescription()}
                        <br />
                    </Typography>
                )}
                <br />
                <Typography variant="body2">{testCase?.data.name}</Typography>
                <Typography variant="caption">
                    {testCase?.data.device.name}
                </Typography>
                <br />
                <Typography variant="caption">
                    {`${runResult.data.createdAt?.toLocaleDateString()} ${runResult.data.createdAt?.toLocaleTimeString()}`}
                </Typography>
                <br />
                <Typography variant="caption">
                    {runResult.data.createdAt &&
                        runResult.data.endAt &&
                        `実行時間: ${convertUnixTimeToMinSec(
                            Math.floor(
                                (runResult.data.endAt.getTime() -
                                    runResult.data.createdAt.getTime()) /
                                    1000
                            )
                        )}`}
                </Typography>
            </Grid>
            <Grid item xs={11}>
                <List
                    style={{
                        overflowY: "hidden",
                        overflowX: "scroll",
                        display: "flex",
                        alignItems: "flex-end",
                    }}
                >
                    {actionResults &&
                        actionResults.map((actionResult) => {
                            return (
                                <ActionView
                                    actionEvent={
                                        new ActionEvent({
                                            ...actionResult.data,
                                            projectID: "",
                                        })
                                    }
                                    actionResult={actionResult}
                                    assertionResults={assertionResults}
                                    screenshotWidth={128}
                                    device={device}
                                    canDelete={false}
                                    onClick={() => {
                                        if (!onClickActionResult) {
                                            return;
                                        }

                                        onClickActionResult(
                                            actionResult.data.id,
                                            runResult.data.id
                                        );
                                    }}
                                />
                            );
                        })}
                </List>
            </Grid>
        </Grid>
    );
};
