import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceName } from "../../../../entities/src/device";
import { ActionEvent } from "../../models/actionEvent";
import { Assertion } from "../../models/assertion";
import { IOSNode } from "../../models/iOSNode";
import { TestCase } from "../../models/testCase";
import {
    fetchActionEvents,
    fetchMyTestCases,
    createTestCase,
    deleteTestCase,
    fetchAssertions,
    updateSimilarityAssertionValue,
    renameTestCase,
    fetchTreeXML,
    createAssertion,
    deleteAssertion,
    updateAssertion,
    duplicateTestCase,
    editTestCase,
    updateActionEventText,
    updateTapElement,
    updateActionEventVariable,
} from "./effects";

export type HomeState = {
    openedTestCaseModalID: string | null;
    isModalOpen: boolean;
    testCaseName: string;
    testCases: TestCase[];
    deviceName: DeviceName;
    actions: {
        [testCaseID: string]: ActionEvent[];
    };
    assertions: {
        [testCaseID: string]: Assertion[];
    };
    createdTestCaseID: string | null;
    isAssertionModalOpen: boolean;
    selectedTestCaseID: string | null;
    selectedActionEventID: string | null;
    isLoading: boolean;
    isRenameTestModalOpen: boolean;
    iOSNodes: IOSNode[];
    isConfirmDeleteModelOpen: boolean;
};

export const initialState: HomeState = {
    isModalOpen: false,
    testCases: [],
    actions: {},
    assertions: {},
    testCaseName: "",
    deviceName: "iPhone 8",
    openedTestCaseModalID: null,
    createdTestCaseID: null,
    isAssertionModalOpen: false,
    selectedActionEventID: null,
    selectedTestCaseID: null,
    isLoading: false,
    isRenameTestModalOpen: false,
    iOSNodes: [],
    isConfirmDeleteModelOpen: false,
};

const home = createSlice({
    name: "home",
    initialState,
    reducers: {
        updateTestCaseName: (state, action: PayloadAction<string>) => {
            state.testCaseName = action.payload;
        },
        updateDeviceName: (state, action: PayloadAction<DeviceName>) => {
            state.deviceName = action.payload;
        },
        clearCreatedTestCase: (state) => {
            state.createdTestCaseID = null;
            state.openedTestCaseModalID = null;
            state.isAssertionModalOpen = false;
            state.testCaseName = "";
        },
        openModal: (state) => {
            state.isModalOpen = true;
        },
        dismissModal: (state) => {
            state.testCaseName = "";
            state.isModalOpen = false;
        },
        openTestCaseModal: (state, action: PayloadAction<string>) => {
            state.openedTestCaseModalID = action.payload;
        },
        dismissTestCaseModal: (state) => {
            state.openedTestCaseModalID = null;
        },
        openAssertionModal: (
            state,
            action: PayloadAction<{ actionEventID: string; testCaseID: string }>
        ) => {
            const { actionEventID, testCaseID } = action.payload;
            state.selectedActionEventID = actionEventID;
            state.selectedTestCaseID = testCaseID;
            state.isAssertionModalOpen = true;
        },
        dismissAssertionModal: (state) => {
            state.selectedActionEventID = null;
            state.isAssertionModalOpen = false;
        },
        openRenameTestModal: (state) => {
            state.isRenameTestModalOpen = true;
        },
        dismissRenameTestModal: (state) => {
            state.isRenameTestModalOpen = false;
        },
        openConfirmDeleteModal: (state) => {
            state.isConfirmDeleteModelOpen = true;
        },
        dismissConfirmDeleteModal: (state) => {
            state.isConfirmDeleteModelOpen = false;
        },
        selectActionEventID: (
            state,
            action: PayloadAction<{ actionEventID: string }>
        ) => {
            state.selectedActionEventID = action.payload.actionEventID;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyTestCases.fulfilled, (state, action) => {
            const testCases = action.payload;
            state.testCases = testCases;
        });
        builder.addCase(fetchActionEvents.fulfilled, (state, action) => {
            state.actions[action.payload.testCaseID] = action.payload.actions;
        });
        builder.addCase(fetchAssertions.fulfilled, (state, action) => {
            state.assertions[action.payload.testCaseID] =
                action.payload.assertions;
        });
        builder.addCase(createTestCase.fulfilled, (state, action) => {
            state.isModalOpen = false;
            state.testCaseName = "";
            state.createdTestCaseID = action.payload.data.id;
        });
        builder.addCase(duplicateTestCase.pending, (state) => {
            state.openedTestCaseModalID = null;
        });
        builder.addCase(duplicateTestCase.fulfilled, (state, action) => {
            const duplicatedTestCase = action.payload;
            state.testCases = [duplicatedTestCase].concat(state.testCases);
        });
        builder.addCase(editTestCase.pending, (state) => {
            state.openedTestCaseModalID = null;
        });
        builder.addCase(editTestCase.fulfilled, (state, action) => {
            state.createdTestCaseID = action.payload.data.id;
        });
        builder.addCase(deleteTestCase.fulfilled, (state, action) => {
            const deletedTestCaseID = action.payload;
            state.testCases = state.testCases.filter(
                (testCase) => testCase.data.id !== deletedTestCaseID
            );
            state.openedTestCaseModalID = null;
        });
        builder.addCase(renameTestCase.fulfilled, (state, action) => {
            const renamedTestCaseID = action.payload.testCaseID;
            const afterTestName = action.payload.afterTestName;
            state.testCases.filter(
                (testCase) => testCase.data.id == renamedTestCaseID
            )[0].data.name = afterTestName;
            state.openedTestCaseModalID = null;
            state.isRenameTestModalOpen = false;
        });
        builder.addCase(updateActionEventText.fulfilled, (state, action) => {
            const { testCaseID, actionEventID, text } = action.meta.arg;
            state.actions[testCaseID].filter(
                (actionEvent) => actionEvent.data.id === actionEventID
            )[0].data.text = text;
            state.actions[testCaseID].filter(
                (actionEvent) => actionEvent.data.id === actionEventID
            )[0].data.variableID = undefined;
        });
        builder.addCase(
            updateActionEventVariable.fulfilled,
            (state, action) => {
                const {
                    testCaseID,
                    actionEventID,
                    variableID,
                } = action.meta.arg;
                state.actions[testCaseID].filter(
                    (actionEvent) => actionEvent.data.id === actionEventID
                )[0].data.variableID = variableID;
            }
        );
        builder.addCase(updateTapElement.fulfilled, (state, action) => {
            const { testCaseID, actionEventID, iOSNode } = action.meta.arg;
            state.actions[testCaseID].filter(
                (actionEvent) => actionEvent.data.id === actionEventID
            )[0].data.iOSNode = iOSNode.data;
        });
        builder.addCase(updateSimilarityAssertionValue.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            updateSimilarityAssertionValue.fulfilled,
            (state, action) => {
                const { testCaseID, assertionID, value } = action.payload;

                state.isLoading = false;

                state.assertions[testCaseID].filter(
                    (assertion) => assertion.data.id === assertionID
                )[0].data.value = value;
            }
        );
        builder.addCase(fetchTreeXML.fulfilled, (state, action) => {
            state.iOSNodes = action.payload;
        });
        builder.addCase(createAssertion.fulfilled, (state, action) => {
            const { assertion, testCaseID } = action.payload;
            state.assertions[testCaseID].push(assertion);
        });
        builder.addCase(updateAssertion.fulfilled, (state, action) => {
            const { assertion, testCaseID } = action.payload;
            state.assertions[testCaseID] = state.assertions[testCaseID].map(
                (_assertion) => {
                    if (_assertion.data.id !== assertion.data.id) {
                        return _assertion;
                    }
                    return assertion;
                }
            );
        });
        builder.addCase(deleteAssertion.fulfilled, (state, action) => {
            const { deletedAssertionID, testCaseID } = action.payload;
            state.assertions[testCaseID] = state.assertions[testCaseID].filter(
                (assertion) => assertion.data.id !== deletedAssertionID
            );
        });
    },
});

export const {
    updateTestCaseName,
    dismissModal,
    openModal,
    updateDeviceName,
    clearCreatedTestCase,
    openTestCaseModal,
    dismissTestCaseModal,
    openAssertionModal,
    dismissAssertionModal,
    openRenameTestModal,
    dismissRenameTestModal,
    openConfirmDeleteModal,
    dismissConfirmDeleteModal,
    selectActionEventID,
} = home.actions;

export default home;
