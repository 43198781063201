import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { authorizeSlackWithToken } from "../../ducks/authCallback/effects";
import { useAuthCallbackState } from "../../ducks/authCallback/selectors";

export const AuthCallback = () => {
    const dispatch = useDispatch();
    const state = useAuthCallbackState();
    const project = state.project;
    useEffect(() => {
        if (!project.selectedProject) {
            return;
        }

        const url = new URLSearchParams(window.location.search);
        const code = url.get("code");

        if (!code) {
            return;
        }
        dispatch(
            authorizeSlackWithToken({
                projectID: project.selectedProject.data.id,
                accessToken: code,
            })
        );
    }, []);

    if (state.authCallback.hasRequestedSlack) {
        return <Redirect to="/integrations"></Redirect>;
    } else {
        return <Box />;
    }
};
