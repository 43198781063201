import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import {
    Checkbox,
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    fetchRunSchedule,
    updateRunSchedule,
} from "../../ducks/constantRun/effects";
import { useConstantRunState } from "../../ducks/constantRun/selectors";
import { useProjectState } from "../../ducks/project/selectors";
import { RunSchedule } from "../../models/runSchedule";

export const ConstantRun = () => {
    const dispatch = useDispatch();
    const projectState = useProjectState().project;
    const state = useConstantRunState();
    const [selectedHour, setSelectedHour] = React.useState<number>(0);
    const [dayOfWeek, setDayOfWeek] = React.useState<number[]>([]);

    useEffect(() => {
        if (!projectState.selectedProject) {
            return;
        }
        const projectID = projectState.selectedProject.data.id;
        dispatch(fetchRunSchedule({ projectID: projectID }));
    }, []);

    useEffect(() => {
        if (!state.runSchedule) {
            return;
        }
        setSelectedHour(state.runSchedule.data.hour);
        setDayOfWeek(state.runSchedule.data.dayOfWeek);
    }, [state.runSchedule]);

    const DayCheckbox = (props: {
        title: string;
        isChecked: boolean;
        onClick: () => void;
    }) => {
        const { title, isChecked, onClick } = props;
        return (
            <Grid item container direction="column" alignItems="center">
                <Typography variant="body2">{title}</Typography>
                <Checkbox
                    color="primary"
                    checked={isChecked}
                    onClick={() => {
                        onClick();
                    }}
                />
            </Grid>
        );
    };

    const handleClickSave = () => {
        if (!projectState.selectedProject || !state.runSchedule) {
            return;
        }
        const projectID = projectState.selectedProject.data.id;
        dispatch(
            updateRunSchedule({
                projectID,
                runSchedule: state.runSchedule,
                hour: selectedHour,
                dayOfWeek,
            })
        );
    };

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
                spacing={3}
                alignItems="center"
            >
                <Grid item container justify="center">
                    <Grid item container direction="column" xs={10} spacing={3}>
                        <Grid item>
                            <Typography variant="h6">定期実行</Typography>
                        </Grid>
                        {projectState.subscription === null && (
                            <Grid item>
                                <Typography variant="caption" color="secondary">
                                    現在フリープランではご利用いただけません。
                                </Typography>
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="center"
                            wrap="nowrap"
                        >
                            {["日", "月", "火", "水", "木", "金", "土"].map(
                                (title, index) => (
                                    <DayCheckbox
                                        isChecked={dayOfWeek.includes(index)}
                                        title={title}
                                        key={title}
                                        onClick={() => {
                                            if (dayOfWeek.includes(index)) {
                                                const newDayOfWeek = dayOfWeek.filter(
                                                    (day) => day !== index
                                                );
                                                setDayOfWeek(newDayOfWeek);
                                            } else {
                                                setDayOfWeek(
                                                    dayOfWeek.concat(index)
                                                );
                                            }
                                        }}
                                    />
                                )
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    xs={12}
                >
                    <Grid item container xs={4}>
                        <FormControl fullWidth>
                            <Select
                                value={selectedHour}
                                onChange={(event) => {
                                    setSelectedHour(
                                        event.target.value as number
                                    );
                                }}
                            >
                                {RunSchedule.getHourTitles().map(
                                    (title, index) => (
                                        <MenuItem value={index} key={title}>
                                            {title}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClickSave}
                    >
                        保存する
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
