import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { useProjectState } from "../../ducks/project/selectors";
import { grey } from "@material-ui/core/colors";

const BUILD_UPLOAD_FILE_URL =
    "https://firebasestorage.googleapis.com/v0/b/e2e-test-dev.appspot.com/o/assets%2Findex.js.zip?alt=media&token=59cae7a7-3126-460f-9719-f9c4930aecae";
const BITRISE_URL = "https://www.bitrise.io/integrations/steps/smart-qa";
const BITRISE_DESCRIPTION_URL =
    "https://www.notion.so/levetty/Bitrise-be0a9a8fe17f499bb35e5c32ff11f266";

export const APIKeyPage = () => {
    const state = useProjectState().project;

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                alignItems="center"
                component={Paper}
                style={{ paddingTop: 32, paddingBottom: 32 }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    justify="space-around"
                    xs={10}
                    spacing={4}
                >
                    <Grid item>
                        <Typography variant="h6">Bitrise連携</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" gutterBottom>
                            {`APIキー: ${state.apiKey?.data.key}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <a href={BITRISE_URL}>
                            <Typography variant="body2">
                                {BITRISE_URL}
                            </Typography>
                        </a>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            Bitriseからビルドがアップロードして、テストを実行することができます。
                        </Typography>
                        <a href={BITRISE_DESCRIPTION_URL}>
                            <Typography variant="body2">
                                詳しい説明はこちら
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
