import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionEvent } from "../../models/actionEvent";
import { TestCase } from "../../models/testCase";
import {
    deleteVariable,
    fetchActionEventsWithVariable,
    fetchMyTestCases,
} from "./effects";

export type VariablesState = {
    usedActionEvents: { [testCaseID: string]: ActionEvent[] } | null;
    testCases: TestCase[];
};

export const initialState: VariablesState = {
    usedActionEvents: null,
    testCases: [],
};

const variables = createSlice({
    name: "testSuites",
    initialState,
    reducers: {
        clearUsedActionEvents: (state) => {
            state.usedActionEvents = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchActionEventsWithVariable.fulfilled,
            (state, action) => {
                state.usedActionEvents = action.payload;
            }
        );
        builder.addCase(fetchMyTestCases.fulfilled, (state, action) => {
            state.testCases = action.payload;
        });
    },
});

export const { clearUsedActionEvents } = variables.actions;

export default variables;
