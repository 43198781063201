import { useEffect, useState } from "react";

export const useInterval = (closure: () => void, intervalMS: number) => {
    const [time, setTime] = useState<Date>(new Date());

    useEffect(() => {
        const intervalID = setInterval(() => {
            setTime(new Date());
        }, intervalMS);
        return () => {
            clearInterval(intervalID);
        };
    }, []);

    useEffect(() => {
        closure();
    }, [time]);
};
