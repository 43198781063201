import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionResult } from "../../models/actionResult";
import { AssertionResult } from "../../models/assertionResult";
import { RunResult } from "../../models/runResult";
import { TestCase } from "../../models/testCase";
import { TestSuite } from "../../models/testSuite";
import {
    updateMyTestSuites,
    fetchMyTestCases,
    startTestSuite,
    updateRunResults,
    updateActionResults,
    updateAssertionResults,
} from "./effects";

export type TestSuitesState = {
    testSuites: TestSuite[];
    /**
     * 削除されていないテストケース
     */
    activeTestCases: TestCase[];
    /**
     * 削除済みも含めたテストケース
     */
    allTestCases: TestCase[];
    runResults: {
        [testSuiteID: string]: RunResult[];
    };
    actionResults: {
        [runResultID: string]: ActionResult[];
    };
    assertionResults: {
        [runResultID: string]: AssertionResult[];
    };
    selectedTestSuiteID: string | null;
    isDiffImageModalOpen: boolean;
    selectedActionResultID: string | null;
    selectedRunResultID: string | null;
    selectedTestCaseIDs: string[];
    isSelectTestCaseIDsModalOpen: boolean;
    isLoading: boolean;
};

export const initialState: TestSuitesState = {
    testSuites: [],
    activeTestCases: [],
    allTestCases: [],
    runResults: {},
    actionResults: {},
    assertionResults: {},
    selectedTestCaseIDs: [],
    selectedTestSuiteID: null,
    isDiffImageModalOpen: false,
    selectedActionResultID: null,
    selectedRunResultID: null,
    isSelectTestCaseIDsModalOpen: false,
    isLoading: false,
};

const testSuites = createSlice({
    name: "testSuites",
    initialState,
    reducers: {
        selectTestSuite: (state, action: PayloadAction<string>) => {
            state.selectedTestSuiteID = action.payload;
        },
        openDiffImageModal: (
            state,
            action: PayloadAction<{ actionID: string; runResultID?: string }>
        ) => {
            state.selectedActionResultID = action.payload.actionID;
            state.isDiffImageModalOpen = true;
            state.selectedRunResultID = action.payload.runResultID!;
        },
        dismissDiffImageModal: (state) => {
            state.selectedActionResultID = null;
            state.isDiffImageModalOpen = false;
            state.selectedRunResultID = null;
        },
        openSelectTestCaseIDsModal: (state) => {
            state.isSelectTestCaseIDsModalOpen = true;
        },
        dismissSelectTestCaseIDsModal: (state) => {
            state.isSelectTestCaseIDsModalOpen = false;
            state.selectedTestCaseIDs = [];
        },
        addSelectedTestCaseID: (state, action: PayloadAction<string>) => {
            state.selectedTestCaseIDs.push(action.payload);
        },
        removeSelectedTestCaseID: (state, action: PayloadAction<number>) => {
            state.selectedTestCaseIDs.splice(action.payload, 1);
        },
        selectAllTestCaseID: (state) => {
            state.selectedTestCaseIDs = state.activeTestCases.map(
                (testCase) => testCase.data.id
            );
        },
        removeAllTestCaseID: (state) => {
            state.selectedTestCaseIDs = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateMyTestSuites.fulfilled, (state, action) => {
            const testSuites = action.payload;

            const duplicatedTestSuites = testSuites.concat(state.testSuites);

            let ids: string[] = [];
            const filteredTestSuites = duplicatedTestSuites
                .filter((testSuite) => {
                    if (ids.includes(testSuite.data.id)) {
                        return false;
                    }

                    ids.push(testSuite.data.id);
                    return true;
                })
                .sort((a, b) => b.data.runNumber - a.data.runNumber);

            state.testSuites = filteredTestSuites;
            if (!state.selectedTestSuiteID && filteredTestSuites.length > 0) {
                state.selectedTestSuiteID = action.payload[0].data.id;
            }
        });
        builder.addCase(updateRunResults.fulfilled, (state, action) => {
            const {
                testSuiteID,
                runResults,
                deletedRunResults,
            } = action.payload;

            if (!state.runResults[testSuiteID]) {
                state.runResults[testSuiteID] = [];
            }

            const duplicatedRunResults = state.runResults[testSuiteID].concat(
                runResults
            );
            duplicatedRunResults.reverse();

            let ids: string[] = [];
            const deletedIDs = deletedRunResults.map(
                (runResult) => runResult.data.id
            );

            const filteredRunResults = duplicatedRunResults
                .filter((runResult) => {
                    if (ids.includes(runResult.data.id)) {
                        return false;
                    }
                    if (deletedIDs.includes(runResult.data.id)) {
                        return false;
                    }

                    ids.push(runResult.data.id);
                    return true;
                })
                .reverse()
                .sort(
                    (a, b) =>
                        (a.data.createdAt ?? new Date()).getTime() -
                        (b.data.createdAt ?? new Date()).getTime()
                );
            state.runResults[testSuiteID] = filteredRunResults;
        });
        builder.addCase(updateActionResults.fulfilled, (state, action) => {
            const { runResultID, actionResults } = action.payload;
            if (!state.actionResults[runResultID]) {
                state.actionResults[runResultID] = [];
            }
            const duplicatedActionResults = state.actionResults[
                runResultID
            ].concat(actionResults);
            duplicatedActionResults.reverse();

            let ids: string[] = [];
            const filteredActionResults = duplicatedActionResults
                .filter((actionResult) => {
                    if (ids.includes(actionResult.data.id)) {
                        return false;
                    }

                    ids.push(actionResult.data.id);
                    return true;
                })
                .reverse();
            state.actionResults[runResultID] = filteredActionResults;
        });
        builder.addCase(fetchMyTestCases.fulfilled, (state, action) => {
            const allTestCases = action.payload;
            state.allTestCases = allTestCases;
            state.activeTestCases = allTestCases.filter(
                (testCase) => testCase.data.deletedAt === null
            );
        });
        builder.addCase(startTestSuite.pending, (state) => {
            state.isSelectTestCaseIDsModalOpen = false;
            state.isLoading = true;
        });
        builder.addCase(startTestSuite.fulfilled, (state, action) => {
            const testSuite = action.payload;
            state.isLoading = false;
            state.selectedTestCaseIDs = [];
            state.selectedTestSuiteID = testSuite.data.id;
        });
        builder.addCase(startTestSuite.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateAssertionResults.fulfilled, (state, action) => {
            const { runResultID, assertionResults } = action.payload;

            if (!state.assertionResults[runResultID]) {
                state.assertionResults[runResultID] = [];
            }
            const duplicatedAssertionResults = state.assertionResults[
                runResultID
            ].concat(assertionResults);
            duplicatedAssertionResults.reverse();

            let ids: string[] = [];
            const filteredAssertionResults = duplicatedAssertionResults
                .filter((assertionResult) => {
                    if (ids.includes(assertionResult.data.id)) {
                        return false;
                    }

                    ids.push(assertionResult.data.id);
                    return true;
                })
                .reverse();

            state.assertionResults[runResultID] = filteredAssertionResults;
        });
    },
});

export const {
    selectTestSuite,
    openDiffImageModal,
    dismissDiffImageModal,
    openSelectTestCaseIDsModal,
    dismissSelectTestCaseIDsModal,
    addSelectedTestCaseID,
    removeSelectedTestCaseID,
    selectAllTestCaseID,
    removeAllTestCaseID,
} = testSuites.actions;

export default testSuites;
