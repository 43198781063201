import { IDevice, DeviceName } from "../../../entities/src/device";
import { Modelable } from "../../../entities/src/modelable";

export class Device implements Modelable<IDevice> {
    constructor(public data: IDevice) {}

    static getDeviceNames = (): DeviceName[] => {
        return [
            "iPhone 8",
            "iPhone SE (2nd generation)",
            "iPhone 12 Pro Max",
            "iPad Pro (12.9-inch) (4th generation)",
        ];
    };

    getRect = (): { width: number; height: number } => {
        switch (this.data.name) {
            case "iPhone 8":
                return { width: 375, height: 667 };
            case "iPhone SE (2nd generation)":
                return { width: 375, height: 667 };
            case "iPhone 12 Pro Max":
                return { width: 428, height: 926 };
            case "iPad Pro (12.9-inch) (4th generation)":
                return { width: 1024, height: 1366 };
        }
    };

    getResizedRect = (width: number): { width: number; height: number } => {
        if (width <= 0) {
            throw new Error("Width must be more than 0.");
        }

        return {
            width,
            height: (this.getRect().height * width) / this.getRect().width,
        };
    };

    getAspectRatio = () => {
        const rect = this.getRect();
        return rect.height / rect.width;
    };
}
