import { createAsyncThunk } from "@reduxjs/toolkit";
import { storage } from "firebase";
import { getFirebase } from "react-redux-firebase";
import { IAppInfo } from "../../../../entities/src/appInfo";
import { AppInfo } from "../../models/appInfo";
import { fetchSignedURL } from "../../utilities/fetchSignedURL";
import { generateHash } from "../../utilities/hash";
import { parseDocuments } from "../../utilities/parseDocument";

export const fetchMyAppInfos = createAsyncThunk<AppInfo[], string>(
    "builds/fetchMyAppInfos",
    async (projectID, thunk) => {
        const firebase = getFirebase();
        const firestore = firebase.firestore();

        const appInfoDocs = await firestore
            .collection("project")
            .doc(projectID)
            .collection("appInfo")
            .where("deletedAt", "==", null)
            .orderBy("createdAt", "desc")
            .get();

        const appInfos = await Promise.all(
            parseDocuments<IAppInfo>(appInfoDocs).map(async (doc) => {
                const appInfo = new AppInfo(doc);
                appInfo.data.appURL = await fetchSignedURL(
                    storage(),
                    appInfo.data.appURL
                );
                return appInfo;
            })
        );

        return appInfos;
    }
);

export const uploadApp = createAsyncThunk<
    string,
    { projectID: string; build: File }
>("builds/uploadApp", async (args) => {
    const { projectID, build } = args;
    const firebase = getFirebase();
    const filePath = `${projectID}/builds/${generateHash()}.app.zip`;
    const snapshot = await firebase.storage().ref(filePath).put(build);
    const appURL = `https://firebasestorage.googleapis.com/v0/b/${snapshot.metadata.bucket}/o/${filePath}`;
    return appURL;
});

export const deleteAppInfo = createAsyncThunk<
    string,
    { projectID: string; appInfoID: string }
>("builds/deleteAppInfo", async (args) => {
    const { projectID, appInfoID } = args;

    const firebase = getFirebase();
    await firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("appInfo")
        .doc(appInfoID)
        .update({ deletedAt: new Date() } as Pick<IAppInfo, "deletedAt">);
    return appInfoID;
});

export const createAppInfo = createAsyncThunk<
    AppInfo,
    { appURL: string; projectID: string }
>("builds/createAppInfo", async (args) => {
    const { appURL, projectID } = args;
    const firebase = getFirebase();

    const appInfoDoc = firebase
        .firestore()
        .collection("project")
        .doc(projectID)
        .collection("appInfo")
        .doc();

    const appInfo = new AppInfo({
        id: appInfoDoc.id,
        appURL: appURL,
        minPlatformVersion: "",
        bundleIdentifier: "",
        displayName: null,
        bundleName: "",
        releaseVersion: "",
        bundleVersion: "",
        appIconName: null,
        appIconURL: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
    });

    await appInfoDoc.set(appInfo.data);

    return appInfo;
});
