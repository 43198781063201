import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authorizeSlackWithToken } from "../authCallback/effects";

export type IntegrationsState = {};

export const initialState: IntegrationsState = {};

const integrations = createSlice({
    name: "integrations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
});

export const {} = integrations.actions;

export default integrations;
