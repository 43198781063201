import { DOMParser } from "xmldom";
import { IOSNode } from "../models/iOSNode";
import { getAttribute } from "./getAttributes";

export const getElements = (xml: string): IOSNode[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml);
    const childNodes = getElementsRecursively(xmlDoc, "");
    return childNodes;
};

const getElementsRecursively = (
    node: ChildNode | Document,
    xPath: string
): IOSNode[] => {
    const iOSNode = getAttribute(node);
    if (!node.childNodes || !node.childNodes[0]) {
        if (!iOSNode) {
            return [];
        }

        iOSNode.data.xPath = xPath;

        return [iOSNode];
    }

    const counts: { [type: string]: number } = {};

    const childNodes: IOSNode[] = Array.from(node.childNodes).reduce(
        (prevNodes, currNode) => {
            const attributes = getAttribute(currNode);
            if (!attributes) {
                return prevNodes;
            }

            if (!counts[attributes.data.type]) {
                counts[attributes.data.type] = 0;
            }

            counts[attributes.data.type] += 1;
            const newXPath = `${xPath}/${attributes.data.type}[${
                counts[attributes.data.type]
            }]`;

            return prevNodes.concat(getElementsRecursively(currNode, newXPath));
        },
        [] as IOSNode[]
    );

    // Buttonは最下層の要素でなくとも追加する
    if (iOSNode?.data.type === "XCUIElementTypeButton") {
        iOSNode.data.xPath = xPath;
        return [iOSNode].concat(childNodes);
    }

    return childNodes;
};
