import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    updateTestCaseName,
    openModal,
    updateDeviceName,
    clearCreatedTestCase,
    dismissTestCaseModal,
    dismissAssertionModal,
    dismissModal,
    dismissRenameTestModal,
    dismissConfirmDeleteModal,
    selectActionEventID,
} from "../../ducks/home/slice";
import { useHomeState } from "../../ducks/home/selectors";
import {
    Button,
    TextField,
    Dialog,
    Container,
    Grid,
    Typography,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    FormControl,
    Paper,
    ListItemText,
} from "@material-ui/core";
import {
    createTestCase,
    deleteTestCase,
    fetchActionEvents,
    fetchAssertions,
    fetchMyTestCases,
    renameTestCase,
    editTestCase,
    updateActionEventText,
    updateTapElement,
} from "../../ducks/home/effects";
import { useProjectState } from "../../ducks/project/selectors";
import { grey } from "@material-ui/core/colors";
import { AssertionModal } from "../organisms/home/assertionModal";
import { RenameTestModal } from "../organisms/home/renameTestModal";
import { ConfirmDeleteModal } from "../organisms/confirmDeleteModal";
import { Device } from "../../models/device";
import { DeviceName } from "../../../../entities/src/device";
import { ActionEvent } from "../../models/actionEvent";
import Apple from "@material-ui/icons/Apple";
import { useBuildsState } from "../../ducks/builds/selectors";
import { TestCaseList } from "../organisms/home/testCaseList";
import { openPopup } from "../../ducks/popup/slice";
import { TestCase } from "../../models/testCase";

export const Home = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useHomeState().home;
    const authState = useHomeState().firebase.auth;
    const buildState = useBuildsState().builds;
    const projectState = useProjectState().project;
    const [dependedTestCaseID, setDependedTestCaseID] = React.useState<string>(
        ""
    );
    const selectedActionEventIDRef = React.useRef(state.selectedActionEventID);
    const [searchWord, setSearchWord] = React.useState<string>("");
    const [isSortedDescending, SetSortedDescedning] = React.useState<boolean>(
        true
    );
    const canCreateTestCase =
        projectState.subscription !== null || state.testCases.length < 4;

    useEffect(() => {
        const myProject = projectState.selectedProject;
        if (!authState.uid || !myProject) {
            return;
        }

        dispatch(
            fetchMyTestCases({
                projectID: myProject.data.id,
            })
        );
    }, []);

    useEffect(() => {
        if (!state.createdTestCaseID) {
            return;
        }
        history.push(`/record/${state.createdTestCaseID}`);
        dispatch(clearCreatedTestCase());
    }, [state.createdTestCaseID]);

    useEffect(() => {
        selectedActionEventIDRef.current = state.selectedActionEventID;
    }, [state.selectedActionEventID]);

    const handleEditTestCase = (lastActionEvent?: ActionEvent) => {
        const testCaseID =
            state.openedTestCaseModalID ?? state.selectedTestCaseID ?? null;
        if (!projectState.selectedProject?.data.id || !testCaseID) {
            return;
        }
        const projectID = projectState.selectedProject.data.id;
        dispatch(
            editTestCase({
                projectID,
                testCaseID,
                lastActionEvent,
            })
        );
        dispatch(clearCreatedTestCase());
    };

    const handleCreateTestCase = () => {
        const testCaseName = state.testCaseName;
        const deviceName = state.deviceName;
        if (!(projectState.selectedProject && deviceName)) {
            return;
        }

        if (!canCreateTestCase) {
            dispatch(
                openPopup({
                    type: "error",
                    message: "テストケース数が上限に達しています。",
                })
            );
            return;
        }

        dispatch(
            createTestCase({
                name: testCaseName,
                deviceName: deviceName,
                projectID: projectState.selectedProject.data.id,
                dependedTestCaseID: dependedTestCaseID ?? undefined,
            })
        );
    };

    const showPreviousActionModal = () => {
        if (!state.selectedTestCaseID || !selectedActionEventIDRef.current) {
            return;
        }
        const actionEvents = state.actions[state.selectedTestCaseID];
        const currentIndex = actionEvents.findIndex(
            (actionEvent) =>
                actionEvent.data.id === selectedActionEventIDRef.current
        );

        if (currentIndex <= 0) {
            return;
        }

        dispatch(
            selectActionEventID({
                actionEventID: actionEvents[currentIndex - 1].data.id,
            })
        );
    };

    const showNextActionModal = () => {
        if (!state.selectedTestCaseID || !selectedActionEventIDRef.current) {
            return;
        }
        const actionEvents = state.actions[state.selectedTestCaseID];
        const currentIndex = actionEvents.findIndex(
            (actionEvent) =>
                actionEvent.data.id === selectedActionEventIDRef.current
        );

        if (currentIndex >= actionEvents.length) {
            return;
        }

        dispatch(
            selectActionEventID({
                actionEventID: actionEvents[currentIndex + 1].data.id,
            })
        );
    };

    const filterByWord = (testCase: TestCase) => {
        return testCase.data.name.includes(searchWord);
    };

    const sortDescending = (testCaseA: TestCase, testCaseB: TestCase) => {
        if (isSortedDescending) {
            if (testCaseA.data.createdAt! < testCaseB.data.createdAt!) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (testCaseA.data.createdAt! > testCaseB.data.createdAt!) {
                return 1;
            } else {
                return -1;
            }
        }
    };

    const selectedActionEvent =
        state.selectedTestCaseID === null
            ? undefined
            : state.actions[state.selectedTestCaseID].filter(
                  (action) => action.data.id === state.selectedActionEventID
              )[0];
    const selectedAssertions =
        state.selectedTestCaseID === null
            ? undefined
            : !state.assertions[state.selectedTestCaseID]
            ? undefined
            : state.assertions[state.selectedTestCaseID].filter(
                  (assertion) =>
                      assertion.data.actionEventID ===
                      state.selectedActionEventID
              );

    const appInfo =
        buildState.appInfos.length > 0 ? buildState.appInfos[0] : null;

    return (
        <Container component="main" maxWidth="xl">
            {state.isAssertionModalOpen &&
                state.actions &&
                state.assertions &&
                selectedActionEvent &&
                projectState.selectedProject &&
                state.selectedTestCaseID && (
                    <AssertionModal
                        isOpen={state.isAssertionModalOpen}
                        actionEvent={selectedActionEvent}
                        assertions={selectedAssertions}
                        isLoading={state.isLoading}
                        iOSNodes={state.iOSNodes}
                        projectID={projectState.selectedProject?.data.id}
                        testCaseID={state.selectedTestCaseID}
                        device={
                            new Device(
                                state.testCases.filter(
                                    (testCase) =>
                                        testCase.data.id ===
                                        state.selectedTestCaseID
                                )[0].data.device
                            )
                        }
                        onModifyTestCase={(actionEvent) => {
                            handleEditTestCase(actionEvent);
                        }}
                        onClose={() => dispatch(dismissAssertionModal())}
                        onMoveToLeft={() => {
                            showPreviousActionModal();
                        }}
                        onMoveToRight={() => {
                            showNextActionModal();
                        }}
                    />
                )}
            <RenameTestModal
                isOpen={state.isRenameTestModalOpen}
                prevTestName={
                    state.openedTestCaseModalID
                        ? state.testCases.filter(
                              (testCase) =>
                                  testCase.data.id ==
                                  state.openedTestCaseModalID
                          )[0].data.name
                        : ""
                }
                onClose={() => {
                    dispatch(dismissRenameTestModal());
                    dispatch(dismissTestCaseModal());
                }}
                onSubmit={(testCaseName) => {
                    if (
                        !state.openedTestCaseModalID ||
                        !projectState.selectedProject
                    ) {
                        return;
                    }
                    dispatch(
                        renameTestCase({
                            testCaseID: state.openedTestCaseModalID,
                            projectID: projectState.selectedProject.data.id,
                            afterTestName: testCaseName,
                        })
                    );
                }}
            />
            <ConfirmDeleteModal
                isOpen={state.isConfirmDeleteModelOpen}
                onClose={() => {
                    dispatch(dismissConfirmDeleteModal());
                    dispatch(dismissTestCaseModal());
                }}
                onClick={() => {
                    dispatch(dismissConfirmDeleteModal());
                    dispatch(dismissTestCaseModal());
                    if (
                        !state.openedTestCaseModalID ||
                        !projectState.selectedProject
                    ) {
                        return;
                    }

                    dispatch(
                        deleteTestCase({
                            testCaseID: state.openedTestCaseModalID,
                            projectID: projectState.selectedProject.data.id,
                        })
                    );
                }}
                title="テストケースを削除してよろしいですか？"
                actionText="削除する"
            ></ConfirmDeleteModal>
            <Dialog
                open={state.isModalOpen}
                maxWidth="sm"
                fullWidth
                PaperProps={{ style: { margin: 0, padding: 32 } }}
            >
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item container direction="column" xs={11} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>テストケースを作成する</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                placeholder="xx機能(任意)"
                                label="テストケース名"
                                InputLabelProps={{ shrink: true }}
                                value={state.testCaseName}
                                onChange={(event) =>
                                    dispatch(
                                        updateTestCaseName(event.target.value)
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>デバイス</InputLabel>
                                <Select
                                    value={state.deviceName}
                                    onChange={(event) => {
                                        dispatch(
                                            updateDeviceName(
                                                event.target.value as DeviceName
                                            )
                                        );
                                        if (!dependedTestCaseID) {
                                            return;
                                        }
                                        const deviceName = state.testCases.filter(
                                            (testCase) =>
                                                testCase.data.id ===
                                                dependedTestCaseID
                                        )[0].data.device.name;
                                        if (
                                            deviceName !==
                                            (event.target.value as DeviceName)
                                        ) {
                                            setDependedTestCaseID("");
                                        }
                                    }}
                                >
                                    {Device.getDeviceNames().map(
                                        (deviceName) => (
                                            <MenuItem
                                                id={deviceName}
                                                value={deviceName}
                                            >
                                                {deviceName}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <FormHelperText
                                    style={{ fontWeight: "normal" }}
                                >
                                    iOSのバージョンは14.4になります。
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>依存するテストケース</InputLabel>
                                <Select
                                    defaultValue={-1}
                                    onChange={(event) => {
                                        const index = event.target
                                            .value as number;

                                        if (index < 0) {
                                            setDependedTestCaseID("");
                                            return;
                                        }

                                        const testCase = state.testCases.filter(
                                            (testCase) =>
                                                testCase.data.device.name ===
                                                state.deviceName
                                        )[index];

                                        setDependedTestCaseID(testCase.data.id);
                                    }}
                                >
                                    <MenuItem value={-1}>選択しない</MenuItem>
                                    {state.testCases
                                        .filter(
                                            (testCase) =>
                                                testCase.data.device.name ===
                                                state.deviceName
                                        )
                                        .map((testCase, index) => {
                                            return (
                                                <MenuItem
                                                    value={index}
                                                    key={testCase.data.id}
                                                >
                                                    {`${testCase.data.name} [${testCase.data.device.name}]`}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText
                                    style={{ fontWeight: "normal" }}
                                >
                                    ログインやオンボーディングなどの共通部分を省略します。
                                    <br />
                                    同じ端末のテストケースのみ使用できます。
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        {appInfo ? (
                            <Grid
                                item
                                container
                                xs={12}
                                justify="space-between"
                            >
                                <Grid item xs={1}>
                                    <Paper
                                        style={{
                                            textAlign: "center",
                                            width: 48,
                                            height: 48,
                                            overflow: "hidden",
                                        }}
                                    >
                                        {appInfo.data.appIconURL ? (
                                            <img
                                                src={appInfo.data.appIconURL}
                                                width="48"
                                                height="48"
                                            />
                                        ) : (
                                            <Apple
                                                fontSize="large"
                                                htmlColor={grey[400]}
                                                style={{ height: 48 }}
                                            ></Apple>
                                        )}
                                    </Paper>
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText
                                        primary={appInfo.data.bundleName}
                                        secondary={
                                            appInfo.data.bundleIdentifier
                                        }
                                        primaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText
                                        primary={`Bundle: ${appInfo.data.bundleVersion}`}
                                        secondary={`Release: ${appInfo.data.releaseVersion}`}
                                        primaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText
                                        primary={`>= iOS: ${appInfo.data.minPlatformVersion}`}
                                        secondary={`${appInfo.data.createdAt?.toLocaleDateString()}`}
                                        primaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Typography>ビルドが存在しません。</Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">
                                最新のビルドが使用されます。
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} justify="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    dispatch(dismissModal());
                                }}
                            >
                                キャンセル
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleCreateTestCase();
                                }}
                            >
                                作成する
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid
                    container
                    item
                    alignItems="center"
                    justify="flex-start"
                    xs={10}
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                dispatch(openModal());
                            }}
                        >
                            テストケースを作成する
                        </Button>
                    </Grid>
                    {state.testCases.length > 0 && (
                        <Grid item xs={5}>
                            <Typography variant="caption">
                                テストケース数: {state.testCases.length}件
                            </Typography>
                        </Grid>
                    )}
                    {projectState.subscription === null && (
                        <Grid item>
                            <Typography variant="caption">
                                フリープランでは、テストケースを4つまで作成できます。
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <TextField
                            label="テストケースを検索"
                            onChange={(event) =>
                                setSearchWord(event.target.value)
                            }
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                SetSortedDescedning(!isSortedDescending);
                            }}
                        >
                            作成日でソート
                            {isSortedDescending ? "↓" : "↑"}
                        </Button>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    direction="column"
                    xs={10}
                    zeroMinWidth
                    style={{ flexWrap: "nowrap" }}
                    id={"testCases"}
                    spacing={1}
                >
                    {state.testCases
                        .filter(filterByWord)
                        .sort(sortDescending)
                        .map((testCase) => (
                            <TestCaseList
                                testCase={testCase}
                                testCases={state.testCases}
                                actionEvents={state.actions[testCase.data.id]}
                                assertions={state.assertions[testCase.data.id]}
                                projectID={
                                    projectState.selectedProject?.data.id
                                }
                                isOpen={
                                    state.openedTestCaseModalID ===
                                    testCase.data.id
                                }
                                canDuplicateTestCase={canCreateTestCase}
                            />
                        ))}
                </Grid>
            </Grid>
        </Container>
    );
};
